import { sum, sumBy } from "lodash-es";
import type { DbIdentity } from "../models/types";

export function getNumberWithOrdinalSuffix(i: number) {
    var j = i % 10,
        k = i % 100;
    if (j === 1 && k !== 11) {
        return i + "st";
    }
    if (j === 2 && k !== 12) {
        return i + "nd";
    }
    if (j === 3 && k !== 13) {
        return i + "rd";
    }
    return i + "th";
}

export function toDbIdentity(s:string) {
    if (!s) return null;
    const n = Number(s);
    return Number.isNaN(n) ? null : n as DbIdentity;
}

export function mustBeGreaterOrEqualZero(a: number){
    return a > 0 ? a : 0;
}

export function isNumber(s?: string ){
    if(!(s?.trim()) || Number.isNaN(Number(s))) return false;
    return true;
}


export function randomNumberUnderK(k: number) { return Math.floor( Math.random() * k );}


export function toSafeNumber(s:string|undefined) {
    if (s === undefined) return undefined;
    const n = Number(s);
    return (Number.isNaN(n) || !Number.isFinite(n)) ? undefined : n
}

export function average(xs: {'score': number, 'weight': number}[]) : number {
    const roundNum: number = 10000;
    const sum = sumBy(xs, 'score');
    const divideBy = sumBy(xs, 'weight');
    return (divideBy === 0) ? NaN : Math.round((sum * roundNum) / divideBy) / roundNum;
}

export function percent(value?: number, minimumFractionDigits: number = 2) : string {
    return (value == null || Number.isNaN(value)) ? "" : value.toLocaleString("en-US", {style:"percent", minimumFractionDigits})
}

export function decimal(value?: number, minimumFractionDigits: number = 2, maximumFractionDigits: number = 2) : string {
    return (value == null || Number.isNaN(value)) ? "" : value.toLocaleString("en-US", {style:"decimal", minimumFractionDigits, maximumFractionDigits})
}

export enum RoundingStategy{
    Floor,
    Nearest,
    Ceil
}
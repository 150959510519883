import { observable, action, computed, toJS, makeObservable } from "mobx";
import { aFetch } from "../services/api/fetch";

import {DbIdentity, DefaultId, NumberDate} from "./types"
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { Activity, ActivityType } from "./Activity";
import { SubmissionTypeEnum } from "./SubmissionTypeEnum";
import { Student } from "./Student";
import { StudentActDoc, StudentActItem } from "./StudentActDoc";
import { ClassStudent } from "./ClassStudent";
import { ThreadUnread } from "./ThreadUnread";
import moment from "moment";
import { Class, ClassGradingType } from "./Class";
import { ActivityCategory } from "./Category";
import { stringSorter, stringSorterDesc } from "../utils/list";
import { ActivityStatus } from "../components/Activity/ActivityStatus";
import { SbgActivityScore } from "./SbgActivityScore";
import { applyAbsentForGrading } from "../config";
import { IPaging, Pagination } from "./Pagination";
import { SubmissionExportResponse } from "../FacultyPortal/stores/GradeDetailStore";
import _ from "lodash-es";
import { PointScoreGuide } from "./PointScoreGuide";

export enum SubmissionFlagType {
    Clear = 0,
    IsMissing = 1,
    IsExclude = 2,
    IsLateSubmitted = 3,
    IsAbsent  = 4,
    IsTempExcused  = 5,
}

export interface IActivityScore {
    activityId: DbIdentity;
    studentId : DbIdentity;
    score     : number;
    groupId  ?: number;
}

export interface MessageStudentSubmitProps {
    activityId: DbIdentity;
    filter: string;
    subject: string;
    body: string;
    score?: number;
    parentIncluded: boolean;
}

export interface IStudentIdentityImport {
    studentId       : DbIdentity,
    email           : string,
}

interface IUpdateScoreResult {
    score                    ?: number,
    numberCorrect            ?: number,
    facultyGradeScore        ?: number,
    cumulativeGrade          ?: number,
    cumulativePublishGrade   ?: number,
    gradeDate                ?: number,
}

interface IUpdateScoreClassGradebookResult {
    classGradebookId         ?: number,
    studentId                ?: number,
    score                    ?: number,
    numberCorrect            ?: number,
    facultyGradeScore        ?: number,
    cumulativeGrade          ?: number,
    cumulativePublishGrade   ?: number,
    gradeDate                ?: number,
}

export class StudentCategoryGrade {
    activityCategoryId: DbIdentity = DefaultId;
    color             : string     = "#ffffff";
    name              : string     = "";
    weight            : number     = 0;
    cumulativeGrade   : number     = 0;
    classId           : DbIdentity = DefaultId;
    score             : number     = 0;
    maxScore          : number     = 0;
    mark              : string     = "";

    constructor(data ?: any){
        makeObservable(this, {
            activityCategoryId: observable,
            color             : observable,
            name              : observable,
            weight            : observable,
            cumulativeGrade   : observable,
            classId           : observable,
            score             : observable,
            maxScore          : observable,
            mark              : observable
        });

        if (data) Object.assign(this, data);
    }
}

export class RevisionScore {
    score       ?: number;
    gradeDate   ?: number;
    mark        ?: string;
    rubricScores : string[] = [];
    revision     : number   = 0;
    activityId   : number = DefaultId;

    constructor(data ?: any){
        makeObservable(this, {
            score          : observable,
            gradeDate      : observable,
            mark           : observable,
            rubricScores   : observable.shallow,
            revision       : observable,
            activityId     : observable
        });

        if (data) Object.assign(this, data);
    }

    static sorter = {
        revision  : (a: RevisionScore, b: RevisionScore) => ((a.revision || -1) - (b.revision || -1)),
    };
}
export class ActivityScore {
    studentId              : DbIdentity      = DefaultId;
    activityId             : DbIdentity      = DefaultId;
    score                 ?: number          = undefined;
    facultyGradeScore     ?: number          = undefined;
    mark                  ?: string          = undefined;
    isExclude              : boolean         = false;

    get isTempExcused() { return this.isExclude && this.isMissing; }
    set_isTempExcused(x: boolean) { this.isExclude = x; this.isMissing = x; }

    savedDate             ?: number          = undefined;
    gradeDate             ?: number          = undefined;
    isSubmitted            : boolean         = false;
    isNeedsGrading         : boolean         = false;
    isLateSubmitted       ?: boolean         = undefined;
    threadId               : string          = "";
    discussionThreadId     : string          = "";
    groupId               ?: string          = undefined;
    rubricScores           : string[]        = [];
    startTime             ?: number          = undefined;
    endTime               ?: number          = undefined;
    dateFirstViewed       ?: NumberDate      = undefined;
    dateLastViewed        ?: NumberDate      = undefined;
    dateMarkCompleted     ?: NumberDate      = undefined;
    cumulativeGrade       ?: number          = undefined;
    gradeImpact           ?: number          = undefined;
    isStudentMarkCompleted : boolean         = false;
    revision               : number          = 0;
    revisionScores         : RevisionScore[] = [];
    isMissing              : boolean         = false;
    isAbsent               : boolean         = false;
    openedDate            ?: NumberDate      = undefined;
    isGroupSubmitted      ?: boolean         = undefined;
    groupSavedDate        ?: number          = undefined;
    hasScore              ?: boolean         = undefined;
    submittedBy           ?: number          = undefined;
    submittedDate         ?: number          = undefined;
    attemptRemaining      ?: number          = undefined;
    activityStatus        ?: ActivityStatus  = undefined;
    /** Computed from BE to know the student completes course */
    isSelfPacedLearning    : boolean         = false;
    numberCorrect ?: number     = undefined; set_numberCorrect (v ?: number    ) {this.numberCorrect = v};

    isSaving        ?: boolean  = false    ; set_isSaving (v ?: boolean ) {this.isSaving = v};
    isSaved         ?: boolean  = false    ; set_isSaved (v ?: boolean ) {this.isSaved = v};

    constructor(data?:any) {
        makeObservable(this, {
            score                   : observable, set_score: action.bound,
            facultyGradeScore       : observable,
            mark                    : observable, set_mark     : action,
            isExclude               : observable, set_isExclude: action,
            isTempExcused           : computed, set_isTempExcused: action,
            savedDate               : observable,
            gradeDate               : observable,
            isSubmitted             : observable, set_isSubmitted: action,
            isNeedsGrading          : observable, set_isNeedsGrading : action,
            isLateSubmitted         : observable, set_isLateSubmitted: action,
            groupId                 : observable,
            threadId                : observable,
            rubricScores            : observable.shallow, set_rubricScore : action.bound,
            startTime               : observable,         set_startTime   : action,
            endTime                 : observable,
            dateFirstViewed         : observable,
            dateLastViewed          : observable,
            dateMarkCompleted       : observable,
            cumulativeGrade         : observable,
            gradeImpact             : observable,
            isStudentMarkCompleted  : observable, set_studentMarkCompleted: action,
            revision                : observable,
            revisionScores          : observable,
            isMissing               : observable, set_isMissing: action,
            isAbsent                : observable, set_isAbsent : action,
            openedDate              : observable,
            isGroupSubmitted        : observable,
            groupSavedDate          : observable,
            hasScore                : observable,
            submittedBy             : observable,
            submittedDate           : observable,
            attemptRemaining        : observable,
            activityStatus          : observable,
            isSelfPacedLearning     : observable,
            numberCorrect           : observable, set_numberCorrect : action.bound,
            isSaving                : observable, set_isSaving      : action.bound,
            isSaved                 : observable, set_isSaved       : action.bound,
            params                  : computed,
            hasStarted              : computed,
            isInProgress            : computed,
            lastestRevisionHasGraded: computed,
            showedScore             : computed,
            showedRubricScore       : computed,
            showedMark              : computed,
            displayMarkScore        : computed,
            scoreIsNotNull          : computed,
            set_attemptRemaining    : action.bound,
            shouldShowMissingChar   : computed,
            shouldShowExcuseChar    : computed,
            shouldShowTempExcuseChar: computed,
            shouldShowAbsentChar    : computed
        });

        if (data != null) {
            const { revisionScores, ...pData } = data;
            Object.assign(this, pData);
            this.revisionScores = revisionScores ? Array.from(revisionScores).map(r => new RevisionScore(r)) : [];
        }
    }

    get params() { return ({
        studentId : String(this.studentId),
        activityId: String(this.activityId),
        groupId   : (!this.groupId  ? undefined : String(this.groupId))!,
        threadId  : (!this.threadId ? undefined : String(this.threadId))!,
    }) }

    get displayMarkScore(){
        if(!!this.mark){
            return `${this.mark} (${this.score})`;
        }
        return String(this.score);
    }

    get scoreIsNotNull() { return !!this.hasScore || this.score != null; }

    get hasStarted() { return !!this.startTime && this.startTime > 0; }

    get isInProgress() { return !!this.openedDate || !!this.savedDate; }

    get shouldShowAbsentChar() { return applyAbsentForGrading ? (this.isMissing && this.isAbsent) : false }
    get shouldShowMissingChar() { return this.isMissing && (this.score == null || this.score == 0) }
    get shouldShowExcuseChar() { return this.isExclude }
    get shouldShowTempExcuseChar() { return this.isTempExcused }

    /**
     * check hasGraded for retake exam
     * TES-2467 Student - Assessment: Re-take button should be display only after Teacher set score
     */
    get lastestRevisionHasGraded() {
        const revisionScore = this.revisionScores.find(rs => rs.revision === this.revision);
        if (!revisionScore) return false;
        return revisionScore.gradeDate != null || revisionScore.score != null;
    }

    toJS() {
        return ({
            studentId             : this.studentId,
            activityId            : this.activityId,
            isAbsent              : this.isAbsent,
            isExclude             : this.isExclude,
            isSubmitted           : this.isSubmitted,
            isLateSubmitted       : this.isLateSubmitted,
            isNeedsGrading        : this.isNeedsGrading,
            groupId               : this.groupId,            
            score                 : this.score == undefined ? null : this.score, //make sure the toJS() always return the score property.
            numberCorrect         : this.numberCorrect == undefined ? null : _.round(this.numberCorrect, 3),
            mark                  : this.mark,
            isMissing             : this.isMissing,
            rubricScores          : this.rubricScores?.slice() ?? [],
            threadId              : this.threadId,
            discussionThreadId    : this.discussionThreadId,
            revision              : this.revision,
            revisionScores        : this.revisionScores?.slice() ?? [],
            openedDate            : this.openedDate,
            savedDate             : this.savedDate,
            isStudentMarkCompleted: this.isStudentMarkCompleted,
            dateMarkCompleted     : this.dateMarkCompleted,
            submittedBy           : this.submittedBy,
        });
    }

    clone() {
        return new ActivityScore(this.toJS());
    }

    get showedScore() { return new Map(this.revisionScores.map(rs => [rs.revision , rs.score])) }
    set_score(v?: number, revision ?: number, shouldNotSetMissing ?: boolean, 
                    maxScore ?: number, correctPossible ?: number) {

        if (typeof v != "number") v = undefined;

        if (v == null) {
            this.set_mark(undefined, revision);
        }

        this.score = v ? Number(v.toFixed(2)) : v;

        if (v != null) {
            this.set_isExclude(false);
            // TES-6118: Missing flag not cleared and score not saving when entering a 0 score
            if (!(shouldNotSetMissing == true)) this.set_isMissing(false);
        }

        if (this.isNeedsGrading && v != null) this.set_isNeedsGrading(false);

        //numberCorrect
        if (maxScore != null && correctPossible != null) {
            if (!this.score) { //=0 or null;
                this.set_numberCorrect(this.score);
            } 
            else {
                this.set_numberCorrect(ActivityScore.score2CorrectPossible(this.score, maxScore, correctPossible));
            }              
        }

        //revision
        if (revision == null && this.revisionScores.length < 1) return;
        if (revision == null) revision = this.revision;
        const rs = this.revisionScores.find(rs => rs.revision == revision);
        if (rs != null) { rs.score = v; }
        else { this.revisionScores.push(new RevisionScore({ score: v, revision, rubricScores: this.rubricScores })); }        
    }

    set_mark(v? : string, revision ?: number) {
        this.mark = v ?? "";
        if (revision == null && this.revisionScores.length < 1) return;

        if (revision == null) revision = this.revision;
        const rs = this.revisionScores.find(rs => rs.revision == revision);
        if (rs != null) { rs.mark = v; }
        else { this.revisionScores.push(new RevisionScore({ score: this.score, revision, rubricScores: this.rubricScores, mark: this.mark})); }
    }
    set_rubricScore(v : string[], score?:number, revision ?: number) {
        this.rubricScores = v;
        if (score != null) this.set_score(score, revision);

        if (revision == null && this.revisionScores.length < 1) return;

        if (revision == null) revision = this.revision;
        const rs = this.revisionScores.find(rs => rs.revision == revision);
        if (rs != null) { rs.rubricScores = v; }
        else { this.revisionScores.push(new RevisionScore({ score: this.score, revision, rubricScores: this.rubricScores, mark: this.mark})); }
    }
    set_isExclude(v  : boolean) { this.isExclude       = v };
    set_isMissing(v  : boolean) { this.isMissing       = v };
    set_isAbsent (v  : boolean) { this.isAbsent        = v };
    set_isLateSubmitted(v ?: boolean) { this.isLateSubmitted = v };
    set_isSubmitted(v:boolean){this.isSubmitted = v};
    set_isNeedsGrading(v: boolean) { this.isNeedsGrading = v };

    async set_flags({type, value, activity, activityScores}:{type: SubmissionFlagType, value?: boolean, activity : Activity, activityScores ?: ActivityScore[]}) {
        switch(type) {
            case SubmissionFlagType.Clear:
                this.set_isAbsent(false);
                this.set_isMissing(false);
                this.set_isExclude(false);
                this.set_isLateSubmitted(false);
                break;
            case SubmissionFlagType.IsAbsent :
                if (!applyAbsentForGrading) return;
                this.set_isAbsent(value ?? !this.isAbsent);
                this.set_isMissing(value ?? !this.isMissing);
                if (this.isMissing) {
                    this.set_score(undefined, undefined, true);     
                    this.set_numberCorrect(undefined);     
                    this.set_isExclude(false);
                    this.set_isLateSubmitted(false);
                    if (activity.submissionType === SubmissionTypeEnum.InPerson)
                    {
                        this.set_studentMarkCompleted(undefined);
                    }
                }
                break;
            case SubmissionFlagType.IsMissing:
                if (this.isAbsent && applyAbsentForGrading) {
                    this.set_isAbsent(value ?? !this.isAbsent);
                    return;
                }
                this.set_isMissing(value ?? !this.isMissing);
                if (this.isMissing) {
                    this.set_score(undefined, undefined, true);
                    this.set_numberCorrect(undefined);
                    this.set_isExclude(false);
                    this.set_isLateSubmitted(false);
                    if (activity.submissionType === SubmissionTypeEnum.InPerson)
                    {
                        this.set_studentMarkCompleted(undefined);
                    }
                }
                break;

            case SubmissionFlagType.IsTempExcused:
                this.set_isExclude(value ?? !this.isExclude);
                this.set_isMissing(value ?? !this.isMissing);
                if (value) {
                    this.set_isAbsent(false);
                    this.set_isLateSubmitted(false);

                    // TES-7986: Teacher - Excused students should have Needs Grading flag removed
                    if(this.isNeedsGrading && !!activityScores)
                    {
                        this.set_isNeedsGrading(false);
                        // update need grading flag of act Score in sClassDetails
                        // some label reply on actScores status in sClassDetails
                        const athActScore = activityScores.find(x => x.activityId == this.activityId && x.studentId == this.studentId);
                        athActScore?.set_isNeedsGrading(false);
                    }
                }
                break;
            case SubmissionFlagType.IsExclude:
                this.set_isExclude(value ?? !this.isExclude);
                if (this.isExclude) {
                    this.set_isAbsent(false);
                    this.set_isMissing(false);
                    this.set_isLateSubmitted(false);

                    // TES-7986: Teacher - Excused students should have Needs Grading flag removed
                    if(this.isNeedsGrading && !!activityScores)
                    {
                        this.set_isNeedsGrading(false);
                        // update need grading flag of act Score in sClassDetails
                        // some label reply on actScores status in sClassDetails
                        const athActScore = activityScores.find(x => x.activityId == this.activityId && x.studentId == this.studentId);
                        athActScore?.set_isNeedsGrading(false);
                    }
                }
                break;

            case SubmissionFlagType.IsLateSubmitted:
                this.set_isLateSubmitted(value ?? !this.isLateSubmitted);
                if (this. isLateSubmitted) {
                    this.set_isAbsent(false);
                    this.set_isMissing(false);
                    this.set_isExclude(false);
                }
                break;
        }
    }

    get showedRubricScore() { return new Map(this.revisionScores.map(rs => [rs.revision , rs.rubricScores])) }

    get showedMark() { return new Map(this.revisionScores.map(rs => [rs.revision , rs.mark])) }
    set_startTime(v : NumberDate | undefined) { this.startTime = v; };
    set_studentMarkCompleted(v?: boolean ) { this.isStudentMarkCompleted = v };
    set_attemptRemaining(v ?: number) { this.attemptRemaining = v; }

    async checkIsSubmitted({facultyId} : {facultyId: DbIdentity}) {
        if (this.isSubmitted || !!this.isGroupSubmitted || !!this.isStudentMarkCompleted) return true;

        const [err, x] = await aFetch<boolean>("GET", `/faculty/${facultyId}/activity/${this.activityId}/student/${this.studentId}/isSubmitted`);
        if (err) return false;

        return x;
    }

    static isChanged(oldScore ?: ActivityScore, newScore ?: ActivityScore) {
        return oldScore != null && newScore != null && JSON.stringify(oldScore) != JSON.stringify(newScore);
    }

    static correctPossible2Score(numberCorrect ?: number, correctPossibleMaxScore ?: number, actMaxScore ?: number) {
        if (!numberCorrect) return numberCorrect; //==0 || null
        if (!correctPossibleMaxScore) return;
        const maxScore = (actMaxScore == null || actMaxScore < 1) ? 10 : actMaxScore;
        const correctPossible = correctPossibleMaxScore;

        return ((numberCorrect ?? 0) / correctPossible) * maxScore;
    }

    static score2CorrectPossible(score ?: number, actMaxScore ?: number, correctPossibleMaxScore ?: number) {
        if (!score) return score; //==0 || null
        if (!correctPossibleMaxScore) return;
        const maxScore = (actMaxScore == null || actMaxScore < 1) ? 10 : actMaxScore;
        const correctPossible = correctPossibleMaxScore;
        return Number(_.round(((score ?? 0) / maxScore) * correctPossible, 3));
    }

    static setPointScoreGuide({actScore, pointScoreGuide, maxScore, score}: {actScore: ActivityScore, pointScoreGuide: PointScoreGuide, maxScore : number, score ?: number}) {
        const mS = maxScore < 1 ? 1 : maxScore;
        if (score == null) {
            actScore.set_score(undefined);           
        }
        else {
            var scale = pointScoreGuide.getPointScaleFromScore((score ?? 0) / mS);
            actScore.set_score((scale?.percent ?? 0) * mS);
            actScore.set_mark(scale?.mark);
        }
    }

    /**
     * Save activity score changes to backend.
     * @param  {DbIdentity} facultyId -- faculty id.
     * @return -- IUpdateScoreResult from updated score.
     */
    async update(facultyId:DbIdentity, applyScoreToSbgScores: boolean = false) {
        const [err, data] = await aFetch<{updateScore: IUpdateScoreResult, updateScoreMultipleGradebook: IUpdateScoreClassGradebookResult[]}>("POST",
                                        `/faculty/${facultyId}/activity/${this.activityId}/student/${this.studentId}/activityScore`,
                                        { ...this.toJS(), applyScoreToSbgScores: applyScoreToSbgScores });

        return [err,
                (err ? undefined : data.updateScore as IUpdateScoreResult),
                (err ? [] : data.updateScoreMultipleGradebook.map(i => i as IUpdateScoreClassGradebookResult))] as const;
    }

    async updateItemActualScore({facultyId, studentId, activityId, revision}:{facultyId: DbIdentity, studentId: DbIdentity, activityId: DbIdentity, revision ?: number}, actItem: StudentActItem, actScore ?: number) {
        const actualScore : ActualScore = ({ actItemId: actItem.id, actualActItemScore: actItem.actualScore, revision: revision, actScore });
        const [err, x] = await aFetch<{studentActItem: StudentActItem, mark ?: string}>("PUT", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/updateActualScore`, toJS(actualScore));
        return [err, (err ? undefined : new StudentActItem(x.studentActItem))!, x.mark!] as const;
    }

    static async recalculateScores({facultyId, activityId}:{facultyId: DbIdentity, activityId: DbIdentity}) {
        const [err, xs] = await aFetch<ActivityScore[]>("PUT", `/faculty/${facultyId}/activity/${activityId}/recalculateScores`);
        return [err, (err ? [] : xs.map(a => new ActivityScore(a)))] as const;
    }

    static async fetchAsFaculty({facultyId, activityId, studentId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal }) {
        const [err, x] = await aFetch<{}>("GET", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/activityScore`, undefined, { signal });
        return [err, (err ? undefined : new ActivityScore(x)!)] as const;
    }
    static async fetchThreadAsFaculty({facultyId, activityId, studentId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal }) {
        return await aFetch<string>("GET", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/threadId`
            , undefined, { signal });
    }

    static async fetchGroupAsFaculty({facultyId, activityId, groupId, signal}:{facultyId:DbIdentity, activityId:DbIdentity, groupId:string, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<ActivityScore[]>("GET", `/faculty/${facultyId}/activity/${activityId}/group/${groupId}`, undefined, { signal });
        return [err, (err ? [] : xs.map(a => new ActivityScore(a)))] as const;
    }

    static async fetchActivityScoresOfClassAsFaculty({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{activityScores: {}[], threadUnreads:{}[]}>("GET", `/faculty/${facultyId}/class/${classId}/activityScore`, undefined, { signal });
        return [
            err,
            (err ? [] : xs.activityScores.map(a => new ActivityScore(a))),
            (err ? [] : xs.threadUnreads .map(a => new ThreadUnread (a))),
        ] as const;
    }

    static async fetchActivityScoresOfActivityAsFaculty({facultyId, activityId, showInactiveStudents, includeInterclass, signal}:
        {facultyId:DbIdentity, activityId:DbIdentity, showInactiveStudents?: boolean, includeInterclass?: boolean, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/activity/${activityId}/ActivityScore`,
            {showInactiveStudents: !!showInactiveStudents, includeInterclass: !!includeInterclass}, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    // identical to fetchActivityScoresOfActivityAsFaculty, but with pagination
    static async fetchActivityScoresOfActivityWithPagingAsFaculty({facultyId, activityId, showInactiveStudents, includeInterclass, pagination, signal}:
        {facultyId:DbIdentity, activityId:DbIdentity, showInactiveStudents?: boolean, includeInterclass?: boolean, pagination: Pagination, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/activity/${activityId}/activityScoreWithPaging`,
            {showInactiveStudents: !!showInactiveStudents, includeInterclass: !!includeInterclass, ...pagination.toParams()}, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(dto))!] as const;
    }

    static async fetchActivityScoresOfActivityAsFacultyForExport2Csv({facultyId, activityId, showInactiveStudents, includeInterclass, signal}:
        {facultyId:DbIdentity, activityId:DbIdentity, showInactiveStudents?: boolean, includeInterclass?: boolean, signal?: AbortSignal}) {
        const [err, data] = await aFetch<SubmissionExportResponse[]>("GET", `/faculty/${facultyId}/activity/${activityId}/submissionData2ExportCsv`,
            {showInactiveStudents: !!showInactiveStudents, includeInterclass: !!includeInterclass}, { signal });
        return [err, (err ? undefined : data)!] as const;
    }

    /**
     * Get all student identities related to the activityId for importing scores.
     * @param
     * @returns
     */
    static async fetchAllStudentIdentityForImportScores({facultyId, activityId, includeInterclass, signal}:
        {facultyId:DbIdentity, activityId:DbIdentity, includeInterclass?: boolean, signal?: AbortSignal}) {
        const [err, data] = await aFetch<IStudentIdentityImport[]>("GET", `/faculty/${facultyId}/activity/${activityId}/student-identities`, undefined, { signal });
        return [err, (err ? [] : data)] as const;
    }

    //#region Scores Report
    static async fetchActivityScoresForTable({facultyId, activityId, onlyInactiveStudents, signal}:{facultyId:DbIdentity, activityId:DbIdentity, onlyInactiveStudents?: boolean, signal?: AbortSignal}) {
        return await aFetch<{actScores: {}[], numberOfStudent: number, scoreStats: {}, allStudents: number[], studentsForCalculation: number[]}>("GET", `/faculty/${facultyId}/activity/${activityId}/scoresReport`, {onlyInactiveStudents: onlyInactiveStudents}, { signal });
    }

    static async fetchAllActivityScoresOfActivity({facultyId, activityId, studentIds, onlyInactiveStudents, signal}:{facultyId:DbIdentity, activityId:DbIdentity, studentIds?: number[], onlyInactiveStudents?: boolean, signal?: AbortSignal}) {
        return await aFetch<{}[]>("GET", `/faculty/${facultyId}/activity/${activityId}/all-scores`, !studentIds ? {onlyInactiveStudents} : {studentIds: studentIds, onlyInactiveStudents: onlyInactiveStudents}, { signal });
    }
    //#endregion

    static async setUnSubmittedAsFaculty({facultyId, activityId, studentId, request}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity, request: UnSubmitRequest}) {
        const [err, result] = await aFetch<{activityScore: {}, studentActDoc: {}}>("PUT", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/unsubmitted`, toJS(request));
        const actScore = (err ? undefined : new ActivityScore(result.activityScore));
        const sDoc = (err ? undefined : new StudentActDoc(result.studentActDoc));
        return [err, actScore!, sDoc!] as const;
    }

    static async resetTimerAsFaculty({facultyId, activityId, studentId}:{facultyId:DbIdentity, activityId:DbIdentity, studentId:DbIdentity}) {
        const [err, x] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/resetTimer`);
        return [err, (err ? undefined : new ActivityScore(x))!] as const;
    }

    static async fetchOverdue({facultyId, classId, signal}:{facultyId:DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/activityScore/overdue`, undefined, { signal });
        return [err, (err ? [] : xs.map(a => new ActivityScore(a)))] as const;
    }

    static async fetchNumberOverdueByClassIds({facultyId, classIds, signal}:{facultyId:DbIdentity, classIds:DbIdentity[], signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{classId:DbIdentity, count: number}[]>("POST", `/faculty/${facultyId}/activityScore/countOverdueByClasses`, classIds , { signal });
        return [err, (err ? [] : xs)] as const;
    }

    static async excuseAllStudents({facultyId, activityId}:{facultyId:DbIdentity, activityId:DbIdentity}) {
        const [err, data] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/activity/${activityId}/excuseAllStudents`);
        return [err, err ? [] : data.map(x => new ActivityScore(x))] as const;
    }
    static async sendMessageToStudents({ facultyId, activityId, filter, subject, body, score, parentIncluded }: MessageStudentSubmitProps & {facultyId: DbIdentity}) {
        const [err, data] = await aFetch<{messageCount: number, actScoresHasComment: ActivityScore[]}>("PUT", `/faculty/${facultyId}/activity/${activityId}/messageToStudents`, {
            filter, subject, body, score, parentIncluded
        });
        return [err , err ? {messageCount: 0, actScoresHasComment: []} : data] as const;
    }

    static async fetchCumulativeGradeBreakdownAsStudent({classId, studentId, classGradebookId, signal}:{classId:DbIdentity, studentId:DbIdentity, classGradebookId?: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<StudentCategoryGrade[]>("GET", `/student/${studentId}/class/${classId}/GetCumulativeGradeBreakdown`, { classGradebookId }, { signal });
        return [err, err ? [] : data.map(x => new StudentCategoryGrade(x))] as const;
    }

    static async fetchCumulativeGradeBreakdownAsParent({parentId, classId, studentId, classGradebookId, signal}:{parentId:DbIdentity, classId:DbIdentity, studentId:DbIdentity, classGradebookId?: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<any[]>("GET", `parent/${parentId}/student/${studentId}/class/${classId}/GetCumulativeGradeBreakdown`, { classGradebookId }, { signal });
        return [err, err ? [] : data.map(x => new StudentCategoryGrade(x))] as const;
    }

    static async fetchCumulativeGradeBreakdownAsFaculty({ facultyId, classId, studentId, classGradebookId, signal }: { facultyId: DbIdentity, classId?: DbIdentity, studentId: DbIdentity, classGradebookId?: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = classId ? await aFetch<any[]>("GET", `faculty/${facultyId}/student/${studentId}/class/${classId}/GetCumulativeGradeBreakdown`, { classGradebookId }, { signal })
                : await aFetch<any[]>("GET", `faculty/${facultyId}/student/${studentId}/GetCumulativeGradeBreakdown`, { classGradebookId }, { signal });
        return [err, err ? [] : data.map(x => new StudentCategoryGrade(x))] as const;
    }

    static async fetchActivityRevisionScores(studentId: DbIdentity, activityIds: DbIdentity[]){
        const [err, data] = await aFetch<RevisionScore[]>("GET", `/student/${studentId}/activityRevisionScores`, {activityIds});
        return [err,err? []: data.map(x=>new RevisionScore(x))] as const;
    }

    static async fetchActivityRevisionScoresAsParent(studentId: DbIdentity, activityIds: DbIdentity[]){
        const [err, data] = await aFetch<RevisionScore[]>("GET", `/parent/student/${studentId}/activityRevisionScores`, {activityIds});
        return [err,err? []: data.map(x=>new RevisionScore(x))] as const;
    }

    async updateAttemptRemaining({facultyId}: {facultyId: DbIdentity}) {
        const [err, xs] = await aFetch<{}>("PUT", `/faculty/${facultyId}/activity/${this.activityId}/student/${this.studentId}/activityScore/attemptRemaining`, {attemptRemaining: this.attemptRemaining});
        return [err, (err ? undefined : new ActivityScore(xs))] as const;
    }

    /** Hightlight for what? */
    static isHighlight(activity: Activity, score?: ActivityScore, isStudentView ?: boolean, isSbgClass ?: boolean, sbgScores ?: SbgActivityScore[]) {
        if (score?.isExclude) return false;//dont care missing or not
        if (activity.isCredit) return false;
        if (this.isMissingDisplay(activity, score, isSbgClass, sbgScores)) return true;

        const tmpOverdue = activity.type != ActivityType.Conference &&
                    activity.type != ActivityType.GradeOnlyActivity &&
                    activity.dateDue != null && (activity.dateDue < Date.now() || (!!isStudentView && moment(activity.dateDue).add(-1, "day").valueOf() <= Date.now()))

        const noScore = isSbgClass
            ? sbgScores?.every(s => s.score == null)
            : score?.score == null || score.score === 0;

        return (
            (tmpOverdue && (activity.isOnlineSubmit || (activity.isOffline && activity.isGradingCompleted)))
            && !score?.isSubmitted && !score?.isGroupSubmitted
            && !score?.isStudentMarkCompleted
            && noScore
        );
    }

    static isMissingDisplay(activity: Activity, actScore ?: ActivityScore, isSbgClass ?: boolean, sbgScores ?: SbgActivityScore[]) {
        //TES-6578 Don't touch IsMissing flag: just display on UI.
        if (actScore?.isMissing) return true; //teacher set isMissing = true.

        function canDisplayMissing(a: Activity) {
            return a.type !== ActivityType.Conference &&
                /// Hide missing if activity is not online submission one
                /// The only exception is grade only assigment, which submission if offline by default.
                (!a.isOffline || a.type === ActivityType.GradeOnlyActivity) &&
                !a.isCredit;
        }

        function isDue(a: Activity) {
            if (a.isGradingCompleted) return true;
            /// Date due logic won't apply for GradeOnly assignments
            if (a.type === ActivityType.GradeOnlyActivity) return false;
            return a.dateDue && a.dateDue < Date.now();
        }

        function isUnsubmitted(s?: ActivityScore) {
            if (s == null) return true;
            if (s.isLateSubmitted) return false;
            const submitted = s.isSubmitted || (s.isGroupSubmitted && activity.type === ActivityType.Discussion)
            return !submitted;
        }

        function hasEmptyScore(s?: ActivityScore, isSbgClass ?: boolean, sbgScores ?: SbgActivityScore[]) {
            if (s == null) return true;
            if (s.isExclude) return false;
            return isSbgClass ? sbgScores?.every(s => s.score == null) : s.score == null;
        }

        if (!canDisplayMissing(activity)) return false;
        return isDue(activity) && isUnsubmitted(actScore) && hasEmptyScore(actScore, isSbgClass, sbgScores);
    }


    static sorter = {
        score  : <T extends ActivityScore>(a: T, b: T) => ((a.score || -1) - (b.score || -1)),
        groupId: <T extends ActivityScore>(a: T, b: T) => ((a?.groupId ?? "").localeCompare(b?.groupId ?? "")),
    };

    static getActivityScoreOfStudent(
        classStudent: ClassStudent,
        aClass: Class,
        activityList: Activity[],
        activityScoreList: ActivityScore[],
        activityCategoryList: ActivityCategory[] | undefined
    ) {
        const scores = activityScoreList.filter(s => s.studentId === classStudent.studentId && activityList.some(x => x.activityId == s.activityId));
        const actScoreTuples = activityList.map(a => [a, scores.find(s => s.activityId == a.activityId)] as const)
            .filter(([a])    => a.categoryId != null && !a.isExcused && a.weight > 0)
            .filter(([,s])   => !s?.isExclude)
            .filter(([a,s])  => a.isPublishGrade || (aClass.applyScoresImmediately
                && s?.score != null && s?.isMissing !== true));

        var calcedScores = actScoreTuples.map(([a, s]) => ({
                activityId: a.activityId,
                categoryId: a.categoryId,
                maxScore: a.maxScore,
                isCredit: a.isCredit,
                score: s?.score,
                percent: a.maxScore > 0 ? (s?.score ?? 0) / a.maxScore : 0
            })
        );

        // apply drop lowest scores
        let droppedActivityIds: number[] = [];
        var dropCats = activityCategoryList?.filter(x => x.classId == aClass.classId)?.filter(c => c.numberOfScoresToDrop > 0);
        if (dropCats && dropCats.length > 0)
        {
            dropCats.forEach(c => {
                var activitiesByCat = calcedScores.filter(a => a.categoryId == c.activityCategoryId && !a.isCredit).sort((a, b) => (a.percent - b.percent));
                if (activitiesByCat.length > c.numberOfScoresToDrop) {
                    droppedActivityIds = droppedActivityIds.concat(activitiesByCat.slice(0, c.numberOfScoresToDrop).map(s => s.activityId));
                }
            })
        }
        calcedScores = calcedScores.filter(s => !droppedActivityIds.includes(s.activityId));

        // apply grading floor:
        // Check priority student has grading floor first then class' grading floor.
        // If any of them exist and current activity score is less than grading floor then return grading floor.
        // If current activity is Extra Credit or there are not any grading floor then return original activity score.
        const studentGradingFloor = classStudent.gradingFloor;
        const classGradingFloor = aClass.gradingFloor;
        calcedScores.forEach(s => {
            if (studentGradingFloor) {
                s.score = studentGradingFloor && !s.isCredit && s.percent < studentGradingFloor ? studentGradingFloor * s.maxScore : s.score;
            } else if (classGradingFloor) {
                s.score = classGradingFloor && !s.isCredit && s.percent < classGradingFloor ? classGradingFloor * s.maxScore : s.score;
            }
        });

        return calcedScores;
    }

    /**
     * Calculate total earned points and max total points of a student within a class that doesn't have Weighted score AND NOT using formative with percent
     */
    static getOverallScoreOfStudent(classStudent: ClassStudent, aClass: Class, activityList: Activity[], activityScoreList: ActivityScore[], activityCategoryList: ActivityCategory[] | undefined) {
        if (aClass.gradingType !== ClassGradingType.Point ) return undefined;
        if (aClass.applyFormativeSummative) return undefined;

        var activityScores = this.getActivityScoreOfStudent(classStudent, aClass, activityList, activityScoreList, activityCategoryList);
        const acs = activityScores.filter(x => activityList.some(y => y.activityId == x.activityId));
        var maxTotalPoints = acs.reduce((t, s) => t + (!s.isCredit ? s.maxScore : 0), 0);
        var totalEarnedPoints = acs.reduce((t, s) => t + (s.score ?? 0), 0);

        return {
            maxTotalPoints,
            totalEarnedPoints
        };
    }

    static getGradeBreakdownInfo(classStudent: ClassStudent, aClass: Class, activityList: Activity[], activityScoreList: ActivityScore[], activityCategoryList: ActivityCategory[] | undefined, gradeBreakdown: StudentCategoryGrade[]) {
        var activityScores = this.getActivityScoreOfStudent(classStudent, aClass, activityList, activityScoreList, activityCategoryList)
        gradeBreakdown.forEach(c => {
            c.score = activityScores.reduce((t, s) => t + ((s.categoryId === c.activityCategoryId) ? (s.score ?? 0) : 0), 0);
            c.maxScore = activityScores.reduce((t, s) => t + ((s.categoryId === c.activityCategoryId) ? (!s.isCredit ? s.maxScore : 0) : 0), 0);
        });

        return gradeBreakdown
    }
}

export class ActivityScoreInfo extends ActivityScore {
    fullName      : string   = "";
    firstName     : string   = "";
    middleName    : string   = "";
    lastName      : string   = "";
    avatar        : string   = "";
    externalId    : string   = "";
    studentNumber : string   = "";
    isLate        : boolean  = false    ;
    activity        ?: Activity = undefined;
    student         ?: Student  = undefined;
    groupName       ?: string   = undefined;
    isActiveStudent  : boolean  = true     ;
    isAuditor       ?: boolean  = false    ; set_isAuditor (v ?: boolean ) {this.isAuditor = v};
    startDate    ?: NumberDate = undefined; set_startDate    (v ?: NumberDate) {this.startDate    = v};
    endDate      ?: NumberDate = undefined; set_endDate      (v ?: NumberDate) {this.endDate      = v};
    daysAssigned ?: number     = undefined; set_daysAssigned (v ?: number    ) {this.daysAssigned = v};

    constructor(data?: {}) {
        super(data);

        makeObservable(this, {
            fullName        : observable,
            firstName       : observable,
            middleName      : observable,
            lastName        : observable,
            avatar          : observable,
            externalId      : observable,
            studentNumber   : observable,
            isLate          : observable,
            activity        : observable,
            student         : observable,
            groupName       : observable,
            isActiveStudent : observable,

            startDate        : observable,
            endDate          : observable,
            daysAssigned     : observable,
            set_startDate    : action.bound,
            set_endDate      : action.bound,
            set_daysAssigned : action.bound,

            groupParams : computed  ,
            isAuditor : observable, set_isAuditor: action.bound,
        });

        if (data != null) Object.assign(this, data);
    }

    override clone() {
        return new ActivityScoreInfo({...this.toJS(), isAuditor: this.isAuditor});
    }

    get groupParams() {
        const threadId = (!this.threadId ? undefined : String(this.threadId));
        return ({
        studentId : String(this.studentId),
        activityId: String(this.activityId),
        groupId   : (!this.groupId  ? threadId : String(this.groupId))!,
        threadId  : threadId!,
    }) }

    static sorter = {
        ...ActivityScore.sorter,
        fullName     : <T extends ActivityScoreInfo>(a: T, b: T) => (a.student? a.student.lfName?.localeCompare(b.student!.lfName) : a.fullName?.localeCompare(b.fullName)),
        firstName    : stringSorter<ActivityScoreInfo>(s => (s.student ? s.student.firstName  : s.firstName).trim().replace((/\-/g), "") ?? ""),
        middleName   : stringSorter<ActivityScoreInfo>(s => (s.student ? s.student.middleName : (s.middleName ?? "")).trim().replace((/\-/g), "") ?? ""),
        lastName     : stringSorter<ActivityScoreInfo>(s => (s.student ? s.student.lastName   : s.lastName).trim().replace((/\-/g), "") ?? ""),
        firstNameDesc : stringSorterDesc<ActivityScoreInfo>(s => (s.student ? s.student.firstName  : s.firstName).trim().replace((/\-/g), "") ?? ""),
        middleNameDesc: stringSorterDesc<ActivityScoreInfo>(s => (s.student ? s.student.middleName : (s.middleName ?? "")).trim().replace((/\-/g), "") ?? ""),
        lastNameDesc  : stringSorterDesc<ActivityScoreInfo>(s => (s.student ? s.student.lastName   : s.lastName).trim().replace((/\-/g), "") ?? ""),
        groupName     : stringSorter<ActivityScoreInfo>(s => (s.groupName ?? "")),
        externalId   : <T extends ActivityScoreInfo>(a: T, b: T) => (a.student? a.student.externalId?.localeCompare(a.student.externalId): a.externalId.localeCompare(b.externalId)),
        studentNumber: <T extends ActivityScoreInfo>(a: T, b: T) => (a.student? a.student.studentNumber?.localeCompare(a.student.studentNumber): a.studentNumber.localeCompare(b.studentNumber)),
        studentId    : <T extends ActivityScoreInfo>(a: T, b: T) => (a.studentId - b.studentId),
        submission   : <T extends ActivityScoreInfo>(a: T, b: T) => {

            const dIsSubmitt = ((a.isSubmitted || a.isStudentMarkCompleted) ? 1 : 0) - ((b.isSubmitted || b.isStudentMarkCompleted) ? 1 : 0);
            if (dIsSubmitt != 0) return dIsSubmitt;

            const dSubmittedDate =  ((a.savedDate || a.gradeDate) || 0) - ((b.savedDate || b.gradeDate) || 0);
            const dOpenedDate =  (a.openedDate || 0) - (b.openedDate || 0);
            if (!a.openedDate && !b.openedDate) return (a.dateLastViewed ?? 0) - (b.dateLastViewed ?? 0);

            return (a.isSubmitted || a.isStudentMarkCompleted) ? dSubmittedDate : dOpenedDate;

        },
        activity  :<T extends ActivityScoreInfo>(a: T, b: T) => (Activity.sorter.title(a.activity!, b.activity!)),
        dateDue   :<T extends ActivityScoreInfo>(a: T, b: T) => (Activity.sorter.dateDue(a.activity!, b.activity!)),
        cumulativePublishGrade: <T extends ActivityScoreInfo>(a: T, b: T) => (Student.sorter.cumulativePublishGrade(a.student!, b.student!)),
        dateFirstViewed   :<T extends ActivityScoreInfo>(a: T, b: T) => ((a.dateFirstViewed || 0) - (b.dateFirstViewed || 0)),
        dateLastViewed    :<T extends ActivityScoreInfo>(a: T, b: T) => ((a.dateLastViewed || 0) - (b.dateLastViewed || 0)),
        opened            :<T extends ActivityScoreInfo>(a: T, b: T) => ((a.openedDate || 0) - (b.openedDate || 0)),

        startDate         :<T extends ActivityScoreInfo>(a: T, b: T) => ((a.startDate || 0) - (b.startDate || 0)),
        endDate           :<T extends ActivityScoreInfo>(a: T, b: T) => ((a.endDate || 0) - (b.endDate || 0)),
        daysAssigned      :<T extends ActivityScoreInfo>(a: T, b: T) => ((a.daysAssigned || 0) - (b.daysAssigned || 0)),
    }
}

interface ActualScore {
    actItemId            : string;
    revision            ?: number;
    actualActItemScore  ?: number; // score of actItem
    actScore            ?: number; // score of doc
}

export class UnSubmitRequest {
    clearScore     ?: boolean;
    clearStudentSubmission ?: boolean;
    activityStatus ?: ActivityStatus;
    constructor(data ?: any){
        if (data) Object.assign(this, data);
    }
}

export enum StandardView {
    Activity = 0,
    Standard = 1,
    Awards = 2,
    Trajectories = 3,
    AdditionalScore = 4,
}
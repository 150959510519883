import { observable, action, computed, toJS, makeObservable, runInAction} from "mobx";

import {DbIdentity, DefaultId, HexColorString, NumberDate} from "./types";
import { Faculty } from "./Faculty";
import type { ClassFaculty } from "./ClassFaculty";

import { aFetch } from "../services/api/fetch";
import { parseGeneralViewModel, GeneralDto } from "./GeneralViewModel";
import { imageUrlToThumbnail, qs2URLSearchParams } from "../utils/url";
import { BlendOptions } from "./BlendOptions";
import { ClassInvitation } from "./ClassInvitation";
import { isEnableClassCalendar } from "../config";
import { ISortable } from "../utils/changeSortIndex";
import { IBanner } from "./Banner";
import { ActivityDistribution } from "./ActivityDistribution";

export interface IClassFaculty {
    classId           : DbIdentity;
    facultyId         : DbIdentity;
    sortIndex         : number    ;
    permissionBitmap  : number    ;
    isTeacherOnRecord : boolean   ;
    customLabel       : string    ;
}

export interface IClassGradebook {
    classGradebookId  : DbIdentity;
    classId           : DbIdentity;
    gradingTermId     : DbIdentity;
    gradingTermName   : string;
    startDate         : NumberDate;
    endDate           : NumberDate;
}

/** Pick default gradebook https://linear.app/ekadence/issue/EK-2552
*  When a class has multiple grading terms, the default value in the dropdown should be the CURRENT grading term based on the start/end date.
*  If no grading term date ranges contain current day, default to the LATEST grading term that has not started yet.
*  Example: if Trimester 2 ends on Mar 12, and Trimester 3 begins Mar 15, then on Mar 13 we should default to Trimester 2.
*  If grading term ranges overlap and current day is part of 2 grading terms, take the latest grading term. (This should never happen anyways)
*/

export interface IFinalModeChange {
    policy : ClassSyncPolicy,
    jobId? : string,
}

export enum JobTypeEnum {
    SisForcePush = 1,
    SisSyncInit = 2,
    SisDiffCheck,
    SisFinalModeUpdate,
    SisUnSync = 3,
}

interface IBaseSyncReport {
    isOnWorking: boolean,
    attempNo: number;
    lastAttemp?: any;
    nextAttemp?: number;
}

export const isActive = (x: IBaseSyncReport) => x.nextAttemp != null;

export interface IActivitySyncReport extends IBaseSyncReport {
    activityId: number;
    title: string;
}

export interface IScoreSyncReport extends IBaseSyncReport {
    activityId: number;
    studentId: number;
    activityTile: string;
    firstName: string;
    lastName: string;
}

export interface ISyncReport {
    activities: IActivitySyncReport[];
    scores: IScoreSyncReport[];
}

export class StudentGroup {
    constructor(data?:any) {
        if (data != null) Object.assign(this, data);

        makeObservable(this, {
            set_groupId  : action.bound,
            set_groupName: action.bound,
            set_students : action.bound,
        });
    }

    groupId?    : DbIdentity = undefined; set_groupId  (v : DbIdentity) { this.groupId   = v; };
    students    : DbIdentity[] = [];  set_groupName(v : string      ) { this.groupName = v  };
    groupName?  : string = undefined; set_students (v : DbIdentity[]) { this.students  = v  };


    toJS() { return toJS(this) }

    static makeGroupName<T extends {groupName:string}>(groups: T[], prefix: string) {
        const m = new Set(groups.map(x => x.groupName));
        for (let index = 1; true; ++index) {
            const groupName = `${prefix} ${index}`;
            if (!m.has(groupName)) return groupName;
        }
    }
    static refineGroupName<T extends {groupName:string}>(groups: T[], prefix: string) {
        runInAction(() => {
            const m = new Set(groups.map(x => x.groupName));
            let i = 0;
            for (const group of groups) {
                if (group.groupName) continue;
                while (m.has(`${prefix} ${i}`)) i++;
                group.groupName = `${prefix} ${i}`; i++;
            }
        });
    }

    static async fetchDefaultGroups({facultyId, classId, signal}:{facultyId: DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/faculty/${facultyId}/class/${classId}/groups`, undefined, { signal });
        return [err, (err ? [] : xs.map(x => new StudentGroup(x)))!] as const;
    }

    static async setDefaultGroups({facultyId, classId, groups}:{facultyId: DbIdentity, classId:DbIdentity, groups: StudentGroup[]}) {
        const [err, xs] = await aFetch<{}[]>("POST", `/faculty/${facultyId}/class/${classId}/groups`, { groups });
        return [err, (err ? [] : xs.map(x =>new StudentGroup(x)))!] as const;
    }

    static sorter = {
        groupName: <T extends StudentGroup>(a:T, b:T) => ((a.groupName || '').localeCompare(b.groupName || ''))
    }
}

export interface CourseTime
{
    start : number
    end   : number
}

export enum ClassTabActiveState {
    Off                 = 0,
    Faculty             = 1,
    Student             = 2,
    Parent              = 4,
    FacultyAndStudent   = ClassTabActiveState.Faculty | ClassTabActiveState.Student,
    FacultyAndParent    = ClassTabActiveState.Faculty | ClassTabActiveState.Parent,
    StudentAndParent    = ClassTabActiveState.Student | ClassTabActiveState.Parent,
    On                  = ClassTabActiveState.Faculty | ClassTabActiveState.Student | ClassTabActiveState.Parent
}

export enum ClassLayoutType {
    v1 = 1,
    v2 = 2,
}

export const facultyOnlyTabConfigs = ["classAttendance", "reflectionsSurvey"];
export const parentHideTabConfigs = ["classStudents", "classCalendar", "classTools", "googleCourseLink"];

export const defaultTabsStudentPortal = [
    "classHomepage"     ,
    "classActivities"   ,
    "classLearningPath" ,
    "discussionList"    ,
    "classAssignments"  ,
    "classAssessments"  ,
    "classAnnouncements",
    "classConferences"  ,
    "classGradebook"    ,
    "classTextbooks"    ,
    "classStudents"     ,
    "classCalendar"     ,
    "googleCourseLink"  ,
];

// NSA - New Student Alerts
export enum EFirstTabPortal {
    None              = 0, // uncheck NSA - no route name selected
    FacultyWithoutNSA = 1, // uncheck NSA - route name selected
    FacultyWithNSA    = 2, //   check NSA - route name selected
    StudentAndParent  = 3
}

export class ClassTabConfig {
    state               : ClassTabActiveState = ClassTabActiveState.Off;
    isFacultyTabActive  : boolean = false;
    isStudentTabActive  : boolean = false;
    isParentTabActive   : boolean = false;
    separatedStates     : boolean = false;
    isHiddenOnCustomTab : boolean = false;
    id                  : string              = "";
    firstTabPortal      : EFirstTabPortal[] = [];
    set_firstTabPortal(v: EFirstTabPortal[]) { this.firstTabPortal = v }
    set_state(v:ClassTabActiveState) { this.state = v }
    set_isHiddenOnCustomTab(v:boolean) { this.isHiddenOnCustomTab = v }

    constructor(data?:{}) {
        makeObservable(this, {
            state                   : observable, set_state : action.bound,
            isFacultyOnly           : computed,
            isHiddenFromParent      : computed,
            calculateState          : computed,
            isFacultyTabActive      : observable,
            isStudentTabActive      : observable,
            isParentTabActive       : observable,
            separatedStates         : observable,
            isHiddenOnCustomTab     : observable,
            onChangeFacultyBtn      : action.bound,
            onChangeStudentBtn      : action.bound,
            onChangeParentBtn       : action.bound,
            firstTabPortal          : observable,
            set_firstTabPortal      : action.bound,
            set_isHiddenOnCustomTab : action.bound,
        });

        if (data != null) Object.assign(this, data);
    }

    get isFacultyOnly() { return facultyOnlyTabConfigs.includes(this.id); }

    get isHiddenFromParent() { return parentHideTabConfigs.includes(this.id) }

    onChangeFacultyBtn(v: boolean) {
        this.isFacultyTabActive = v;
        this.set_state(this.calculateState);
        if(v == false){
            this.isStudentTabActive = v;
            this.isParentTabActive = v;
        }
    }

    onChangeStudentBtn(v: boolean) {
        this.isStudentTabActive = v;
        this.set_state(this.calculateState);
    }

    onChangeParentBtn(v: boolean) {
       this.isParentTabActive = v;
       this.set_state(this.calculateState);
    }

    get calculateState() {
        return parseInt(`${(+this.isParentTabActive).toString()}${(+this.isStudentTabActive).toString()}${(+this.isFacultyTabActive).toString()}`, 2);
    }

    toJS() {
        this.separatedStates = true; // mark class tabConfig as migrated to sepated values instead of State
        // EK-1288: only frontend use isHiddenOnCustomTab so we can reset value here to to avoid isDirty check in ClassEditorStore
        return toJS({...this, isHiddenOnCustomTab: false});
    }
}

enum ClassSyncPolicy {
    Disabled = 0,
    AllItem  = 1,
    FinalGradeOnly = 2,
}

export enum ClassGradingType {
    Point          = 1,
    WeightCategory = 2,
    StandardBased  = 3,
}

const OnSisSyncingPrefix = "PENDING_";

export class Class implements ISortable, IBanner {
    schoolId              : DbIdentity       = DefaultId;
    classId               : DbIdentity       = DefaultId;
    gradingTerm           : DbIdentity       = DefaultId;
    gradingTermName       : string           = '';
    gradingScaleId        : DbIdentity       = DefaultId;
    pointScoreGuideId    ?: DbIdentity       = undefined;
    courseCode            : string           = '';
    classCourseId        ?: DbIdentity           ; set_classCourseId(v?: DbIdentity) { this.classCourseId = v }
    homepageId           ?: DbIdentity       = undefined;
    banner                : string           = "";
    bannerBlendOptions   ?: BlendOptions     ;              set_bannerBlendOptions(v ?: BlendOptions) { this.bannerBlendOptions = v }
    period               ?: string           = "";
    className             : string           = "";
    color                 : HexColorString   = "#fff2cc";
    description           : string           = "";
    defaultGroups         : StudentGroup  [] = [];
    googleCalendarId     ?: string           = undefined;
    driveId              ?: string           = undefined;
    createdBy            ?: DbIdentity       = undefined;
    linkedSectionId      ?: DbIdentity;
    crosslistedSectionId ?: DbIdentity;
    sortIndex            ?: number           = undefined;
    disableRosterSync     = false;
    classCardImage       ?: string           = undefined;
    classCardBlendOptions?: BlendOptions  = undefined; set_classCardBlendOptions(v ?: BlendOptions) { this.classCardBlendOptions = v }

    gradingType           : ClassGradingType = ClassGradingType.Point;

    startDate            ?: NumberDate       = undefined; set_startDate(v: NumberDate|undefined) { this.startDate = v }
    endDate              ?: NumberDate       = undefined; set_endDate  (v: NumberDate|undefined) { this.endDate   = v }

    oneRosterId          ?: string           = "";
    googleClassroomId    ?: string;

    sisGradeBookNumber   ?: string           = undefined; set_sisGradeBookNumber(v?: string) { this.sisGradeBookNumber = v }
    sisBidiMapping        : boolean = false;
    sisClassGrade        ?: string;
    aeriesSyncPolicy     : ClassSyncPolicy = 0;
    tabConfigs           : ClassTabConfig[] = [];
    get displayClassGradebooks() { return this.isSelfPacedLearning ? [] : this.classGradebooks; }
    classGradebooks      : IClassGradebook[] = [];
    get hasMultipleGradebooks() { return this.displayClassGradebooks.length > 1 }

    /**
     * @deprecated Replace with {@link ClassFaculty.showClassOnDashboard}
     * Used in FACULTY portal only. Determinate visibility of Class card on Dashboard
     */
    isVisibleOnDashboard        : boolean          = true;
    deleteCalendarAtEndOfTerm   : boolean          = true;
    disableGoogleCalendar       : boolean          = true;     set_disableGoogleCalendar(v ?: boolean) { this.disableGoogleCalendar = v ?? true; }

    previousGradingTermClassId ?: DbIdentity       = undefined; set_previousGradingTermClassId(v: DbIdentity) { v !== DefaultId ? this.previousGradingTermClassId = v : this.previousGradingTermClassId = undefined }
    previousGradingTermClassName ?: string         = undefined;
    previousGradingTermName    ?: string           = undefined;
    previousClassOneRosterId   ?: string           = undefined;
    isNotGraded                 : boolean          = false;
    applyScoresImmediately      : boolean          = false;
    linkedSectionClassIds       : DbIdentity[]     = [];
    crosslistSectionClassIds    : DbIdentity[]     = [];
    showAboutMe                 : boolean          = true;
    applyFormativeSummative     : boolean          = false;
    formativePercent            : number           = 0;
    summativePercent            : number           = 100;
    isSelfPacedLearning         : boolean          = false;
    set_isSelfPacedLearning(v: boolean) {
        this.isSelfPacedLearning = v;
        this.selfPacedLearning ??= new SelfPacedLearning();
    }

    // too confuse
    selfPacedLearning           ?: SelfPacedLearning;
    selfPace                    ?: SelfPacedLearningV2;
    hasClassEPortfolio         : boolean           = false; set_hasClassEPortfolio(v: boolean){this.hasClassEPortfolio = v}
    gradingFloor               ?: number           = undefined; set_gradingFloor(v?: number) { this.gradingFloor = v }
    // TES-7605 -> default notifyStudentWhenPublishActivity is false
    notifyStudentWhenPublishActivity : boolean     = false; set_notifyStudentWhenPublishActivity(v: boolean) { this.notifyStudentWhenPublishActivity = v }
    notifyStudentWhenActivityIsGraded : boolean    = true; set_notifyStudentWhenActivityIsGraded(v: boolean) { this.notifyStudentWhenActivityIsGraded = v }
    notifyStudentWhenGradeHasComment : boolean     = true; set_notifyStudentWhenGradeHasComment(v: boolean) { this.notifyStudentWhenGradeHasComment = v }
    isEnableGPTZero : boolean = false; set_isEnableGPTZero(v: boolean) { this.isEnableGPTZero = v }

    dateUpdated                 : NumberDate       = 0;

    nextCourse                 ?: CourseTime       = undefined;
    /**
     * Used in STUDENT portal only. Determinate visibility of Class card on Dashboard
     */
    isVisibleOnStudentDashboard : boolean = false;
    isVisibleOnFacultyDashboard : boolean = false;
    hidePercentageFromStudent   : boolean= false;
    hideGrade                   : boolean= false;

    joinPassword          ?: string = undefined;
    publicLinkId          ?: string = undefined;
    canSelfEnroll          : boolean= false;
    studentCount           : number = 0;     set_studentCount(v: number) {this.studentCount = v;}
    activityCount          : number = 0;     set_activityCount(v: number) {this.activityCount = v;}
    isFacultyClass         : boolean= false; set_isFacultyClass(v: boolean) {this.isFacultyClass = v;}
    externalId            ?: string        ; set_externalId(v: string) {this.externalId = v.trim() == "" ? undefined : v;}
    credits               ?: number = undefined; set_credits(v?: number) {this.credits = v; }
    isVisibleToPublic      : boolean = false; set_isVisibleToPublic(v: boolean) { this.isVisibleToPublic = v }

    studentsCanPreviewUnpublishedItems = false; set_studentsCanPreviewUnpublishedItems(v ?: boolean) { this.studentsCanPreviewUnpublishedItems = v ?? false; }

    /** @deprecated */ faculties     : Faculty       [] = [];
    /** @deprecated */ classFaculties: IClassFaculty [] = [];

    get isAeriesSynced() { return !!this.oneRosterId; }
    set_googleClassroom (id?: string) { this.googleClassroomId = id;}

    classLayout: ClassLayoutType = ClassLayoutType.v1;
    set_classLayout(v: ClassLayoutType) { this.classLayout = v;}

    constructor(data?:any) {
        makeObservable(this, {
            schoolId                  : observable        , set_schoolId                  : action.bound,
            gradingTerm               : observable        , set_gradingTerm               : action.bound,
            gradingTermName           : observable        , set_gradingTermName           : action.bound,
            gradingScaleId            : observable        , set_gradingScaleId            : action.bound,
            pointScoreGuideId         : observable        , set_pointScoreGuideId         : action.bound,
            homepageId                : observable        ,
            banner                    : observable        , set_banner                    : action.bound,
            bannerBlendOptions        : observable        , set_bannerBlendOptions        : action.bound,
            period                    : observable        , set_period                    : action.bound,
            className                 : observable        , set_className                 : action.bound,
            color                     : observable        , set_color                     : action.bound,
            description               : observable        , set_description               : action.bound,
            defaultGroups             : observable.shallow,
            googleCalendarId          : observable         ,
            driveId                   : observable        ,
            nextCourse                : observable        ,
            sortIndex                 : observable        , set_sortIndex                 : action.bound,
            linkedSectionId           : observable        , set_linkedSectionId           : action.bound,
            crosslistedSectionId      : observable        , set_crosslistedSectionId      : action.bound,
            disableRosterSync         : observable        , set_disableRosterSync         : action.bound,
            gradingType               : observable        , set_gradingType               : action.bound,
            startDate                 : observable        , set_startDate                 : action.bound,
            endDate                   : observable        , set_endDate                   : action.bound,
            oneRosterId               : observable        ,
            sisGradeBookNumber        : observable        , set_sisGradeBookNumber        : action,
            sisBidiMapping            : observable        ,
            sisClassGrade             : observable        ,
            aeriesSyncPolicy          : observable        ,
            googleClassroomId         : observable        ,
            set_googleClassroom       : action.bound      ,
            applyScoresImmediately    : observable        , set_applyScoresImmediately    : action.bound,
            tabConfigs                : observable.shallow,
            isVisibleOnDashboard      : observable        , set_isVisibleOnDashboard      : action.bound,
            deleteCalendarAtEndOfTerm : observable        , set_deleteCalendarAtEndOfTerm : action.bound,
            disableGoogleCalendar     : observable        , set_disableGoogleCalendar     : action.bound,
            previousGradingTermClassId: observable        , set_previousGradingTermClassId: action.bound,
            previousGradingTermClassName: observable      ,
            previousGradingTermName   : observable        ,
            previousClassOneRosterId  : observable        ,
            isNotGraded               : observable        ,
            linkedSectionClassIds     : observable        , set_linkedSectionClassIds     : action.bound,
            isLinkedSelectionFinished : observable        , set_isLinkedSelectionFinished : action.bound,
            crosslistSectionClassIds  : observable        , set_crosslistSectionClassIds  : action.bound,
            isCrosslistSectionFinished: observable        , set_isCrosslistSectionFinished: action.bound,
            showAboutMe               : observable        , set_showAboutMe               : action.bound,
            applyFormativeSummative   : observable        , set_applyFormativeSummative   : action.bound,
            formativePercent          : observable        , set_formativePercent          : action.bound,
            summativePercent          : observable        , set_summativePercent          : action.bound,
            isSelfPacedLearning       : observable        , set_isSelfPacedLearning        : action.bound,
            selfPacedLearning         : observable        ,
            hasClassEPortfolio        : observable        , set_hasClassEPortfolio        : action.bound,
            gradingFloor              : observable        , set_gradingFloor              : action.bound,
            studentCount              : observable        , set_studentCount              : action.bound,
            activityCount             : observable        , set_activityCount             : action.bound,
            isAeriesSynced            : computed,
            set_groups                : action.bound,
            params                    : computed,
            displayName               : computed,
            displayNameWithoutPeriod  : computed,
            rosterId                  : computed,
            displayNameWithoutOneRosterId : computed,
            banner_thumbnail          : computed,
            facultyOnRecordNames      : computed,
            facultyOnRecords          : computed,
            mainFaculty               : computed,
            isLinkedSectionMainClass  : computed,
            isGradebookSynced         : computed,
            gradebookSyncingJobId     : computed,
            isFinalGrade              : computed,
            isActiveCustomClass       : computed,
            isPastCustomClass         : computed,
            isFutureCustomClass       : computed,
            faculties                 : observable.shallow,
            classFaculties            : observable.shallow,
            isVisibleOnStudentDashboard: observable,
            set_isVisibleOnStudentDashboard : action.bound,
            hidePercentageFromStudent  : observable,
            hideGrade                  : observable,
            set_HidePercentageFromStudent   : action.bound,
            set_hideGrade                   : action.bound,
            classCardImage            : observable        , set_classCardImage           : action.bound,
            classCardBlendOptions     : observable        , set_classCardBlendOptions    : action.bound,
            displayPreviousClassName  : computed,
            joinPassword              : observable        , set_joinPassword    : action.bound,
            publicLinkId              : observable        , set_publicLinkId    : action.bound,
            canSelfEnroll             : observable        , set_canSelfEnroll   : action.bound,
            classLayout               : observable        , set_classLayout     : action.bound,
            notifyStudentWhenPublishActivity: observable  , set_notifyStudentWhenPublishActivity : action.bound,
            notifyStudentWhenActivityIsGraded: observable  , set_notifyStudentWhenActivityIsGraded : action.bound,
            notifyStudentWhenGradeHasComment: observable  , set_notifyStudentWhenGradeHasComment : action.bound,
            isEnableGPTZero: observable, set_isEnableGPTZero: action.bound,
            studentsCanPreviewUnpublishedItems: observable  , set_studentsCanPreviewUnpublishedItems : action.bound,
            isFacultyClass: observable  , set_isFacultyClass : action.bound,
            externalId: observable  , set_externalId : action.bound,
            credits: observable  , set_credits : action.bound,
            firstTabFacultyPortal: computed,
            firstTabStudentAndParentPortal: computed,
            autoStartNSA         : computed,
            isSelectedFirstTabAvailable: computed,
            displayName2: computed,
            isSBG       : computed,
            classGradebooks: observable.shallow,
            displayClassGradebooks: computed,
            hasMultipleGradebooks  : computed  ,
            isVisibleToPublic: observable,
            set_isVisibleToPublic: action.bound,
            courseCode: observable,
            classCourseId: observable, set_classCourseId: action.bound,
        });

        if (data != null) {
            // confusing between selfPacedLearning, selfPace
            const { defaultGroups, tabConfigs, classCardBlendOptions, classGradebooks, selfPacedLearning, selfPace, ...pData} = data;
            Object.assign(this, pData);

            if (Array.isArray(defaultGroups)) this.defaultGroups = defaultGroups.map(x => new StudentGroup  (x));
            if (Array.isArray(tabConfigs   )) {
                this.tabConfigs = tabConfigs.map(x => new ClassTabConfig(x));
                if (!isEnableClassCalendar) this.tabConfigs = this.tabConfigs.filter(x => x.id != "classCalendar");
            }
            this.classCardBlendOptions = new BlendOptions(classCardBlendOptions);
            if (Array.isArray(classGradebooks)) this.classGradebooks = classGradebooks.map(x => x as IClassGradebook);

            if (selfPacedLearning != null) {
                this.selfPacedLearning = new SelfPacedLearning(selfPacedLearning);
            } else if (this.isSelfPacedLearning) {
                // just hack for now because we confuse with 2 objects
                if (selfPace) {
                    const _selfPace = new SelfPacedLearningV2(selfPace);
                    if (_selfPace.completeType === SelfPaceCompleteType.Specific) this.selfPacedLearning = new SelfPacedLearning({ ..._selfPace, minScore: _selfPace.activities[0]?.minScore, actId: _selfPace.activities[0]?.actId })
                }
                else this.selfPacedLearning ??= new SelfPacedLearning();
            }
        } else {
            this.classCardBlendOptions = new BlendOptions();
        }

        if (!this.color) this.color = "#ffffff";
        if (this.isVisibleOnDashboard == null) this.isVisibleOnDashboard = true;
        if (this.courseCode == null) this.courseCode = '';
    }

    set_color               (v : string        ) { this.color                =     v  }
    set_schoolId            (v : DbIdentity    ) { this.schoolId             =     v  }
    set_gradingTerm         (v : DbIdentity    ) { this.gradingTerm          =     v  }
    set_gradingTermName     (v : string        ) { this.gradingTermName      =     v  }
    set_gradingScaleId      (v : DbIdentity    ) { this.gradingScaleId       =     v  }
    set_pointScoreGuideId   (v?: DbIdentity    ) { this.pointScoreGuideId    =     v  }
    set_period              (v : string        ) { this.period               =     v  }
    set_className           (v : string        ) { this.className            =     v  }
    set_description         (v : string        ) { this.description          =     v  }
    set_groups              (v : StudentGroup[]) { this.defaultGroups        = [...v] }
    set_banner              (v?: string        , blendOptions ?: BlendOptions) { this.banner = v ?? ""; this.bannerBlendOptions = blendOptions;}

    set_disableRosterSync   (v : boolean       ) { this.disableRosterSync    =     v  }
    set_sortIndex           (v : number        ) { this.sortIndex            =     v  }
    set_linkedSectionId     (v ?: number       ) { this.linkedSectionId      =     v  }
    set_crosslistedSectionId(v ?: number       ) {this.crosslistedSectionId  =     v  }
    set_isVisibleOnDashboard(v : boolean       ) { this.isVisibleOnDashboard =     v  }
    set_isVisibleOnStudentDashboard(v : boolean) { this.isVisibleOnStudentDashboard = v }
    set_isVisibleOnFacultyDashboard(v : boolean) { this.isVisibleOnFacultyDashboard = v }
    set_HidePercentageFromStudent(v : boolean) { this.hidePercentageFromStudent = v; }
    set_hideGrade(v : boolean) { this.hideGrade = v; }
    set_deleteCalendarAtEndOfTerm(v : boolean       ) { this.deleteCalendarAtEndOfTerm =     v  }
    set_applyScoresImmediately(v ?: boolean) { this.applyScoresImmediately = v ?? false; }
    set_linkedSectionClassIds (v : DbIdentity[]) { this.linkedSectionClassIds=     v  }
    set_showAboutMe         (v : boolean       ) { this.showAboutMe = v; }
    set_classCardImage      (v?: string        ) { this.classCardImage = v; }
    set_gradingType         (v: ClassGradingType) {this.gradingType = v};
    set_applyFormativeSummative(v: boolean)    { this.applyFormativeSummative = v; }
    set_formativePercent(v?: number) {
        this.formativePercent = v ?? 0;
        this.summativePercent = 100 - this.formativePercent;
    }
    set_summativePercent(v?: number) {
        this.summativePercent = v ?? 1;
        this.formativePercent= 100 - this.summativePercent;
    }
    set_joinPassword         (v : string          ) { this.joinPassword          = v }
    set_publicLinkId         (v : string          ) { this.publicLinkId          = v }
    set_canSelfEnroll        (v : boolean         ) { this.canSelfEnroll         = v }
    set_crosslistSectionClassIds(v: DbIdentity[]) { this.crosslistSectionClassIds = v; }

    isLinkedSelectionFinished : boolean = false; set_isLinkedSelectionFinished (v : boolean) { this.isLinkedSelectionFinished =     v  }
    isCrosslistSectionFinished: boolean = false; set_isCrosslistSectionFinished(v: boolean) { this.isCrosslistSectionFinished = v }

    get params() { return ({classId:String(this.classId), schoolId:String(this.schoolId)}) }
    get displayName() { return `${[this.period, this.className].filter(Boolean).join(' - ')}${(this.oneRosterId ?? "") == "" ? "" : ` (${this.oneRosterId})`}`; }
    get displayName2() {
        //Ex: Class1 - (period1) or Class1
        const p = !!this.period ? ` (${this.period})` : "";
        return `${this.className}${p}`;
    }
    get displayNameWithoutOneRosterId() { return `${[this.period, this.className].filter(Boolean).join(' - ')}`; }
    get displayNameWithoutPeriod() { return `${this.className}${(this.oneRosterId ?? "") == "" ? "" : ` (${this.oneRosterId})`}`; }
    get rosterId() { return this.oneRosterId ? `(${this.oneRosterId})` : ""; }
    get displayPreviousClassName() {
        if(!!this.previousClassOneRosterId && !!this.previousGradingTermName){
            return `${this.previousGradingTermName} (${this.previousClassOneRosterId})`;
        }
        if(!!this.previousGradingTermName){
            return this.previousGradingTermName;
        }
        return null;
    }
    get isSBG() { return this.gradingType === ClassGradingType.StandardBased; }
    get banner_thumbnail() { return this.banner ? imageUrlToThumbnail(this.banner) : undefined; }

    get facultyOnRecordNames(): string {
        const names =  this.faculties
            .filter(x => this.facultyOnRecords.find(y => y.facultyId == x.userId && y.classId == this.classId))
            .map(x => new Faculty(x).fullName).join(', ');
        return names != '' ? names : new Faculty(this.faculties[0]).fullName;
    }

    get mainFaculty(): Faculty | undefined {
        return this.faculties.find(x => this.facultyOnRecords.find(y => y.classId == this.classId && y.facultyId == x.userId));
    }

    get facultyOnRecords(): IClassFaculty[] {
        return this.classFaculties.filter(x => x.isTeacherOnRecord);
    }

    get isLinkedSectionMainClass(): boolean
    {
        return this.classId === this.linkedSectionId;
    }

    get isCrosslistMainClass():boolean
    {
        return this.classId === this.crosslistedSectionId;
    }

    get gradebookSyncingJobId(): string | undefined  {
        if (!this.sisGradeBookNumber) return undefined;

        const regEx = new RegExp(`^${OnSisSyncingPrefix}(.*)$`);

        const matches = this.sisGradeBookNumber.match(regEx);
        return !!matches ? matches[1] : undefined;
    }

    get isFinalGrade(): boolean{
        return this.isGradebookSynced && this.aeriesSyncPolicy == ClassSyncPolicy.FinalGradeOnly;
    }

    get isGradebookSynced(): boolean
    {
        return !!this.sisGradeBookNumber
            && !this.gradebookSyncingJobId;
    }

    gradingFloorScore(maxScore : number) {
        if (!this.gradingFloor) return undefined;
        return this.gradingFloor * maxScore;
    }

    get isActiveCustomClass(): boolean
    {
        return (this.startDate == null || this.startDate < Date.now()) &&
                (this.endDate == null || this.endDate > Date.now());
    }

    get isPastCustomClass(): boolean
    {
        return (this.endDate != null && this.endDate < Date.now())
    }

    get isFutureCustomClass(): boolean
    {
        return (this.startDate != null && this.startDate > Date.now())
    }

    get availableTabRouteNameForStudent() {
        return this.tabConfigs.find(t => t.isStudentTabActive);
    }

    get firstTabFacultyPortal(){
        return this.tabConfigs.find(c => c.firstTabPortal.some(x => x === EFirstTabPortal.FacultyWithNSA || x === EFirstTabPortal.FacultyWithoutNSA));
    }

    get firstTabStudentAndParentPortal(){
        return this.tabConfigs.find(c => c.firstTabPortal.includes(EFirstTabPortal.StudentAndParent));
    }

    get autoStartNSA(){
        const isChecked = this.tabConfigs.some(c => c.firstTabPortal.includes(EFirstTabPortal.FacultyWithNSA));
        if (isChecked) return true;

        const isUnChecked = this.tabConfigs.some(c => c.firstTabPortal.some(x => x === EFirstTabPortal.None || x === EFirstTabPortal.FacultyWithoutNSA));
        if (isUnChecked) return false;

        return true;
    }

    get isSelectedFirstTabAvailable(){
        if (this.firstTabFacultyPortal !== undefined && this.tabConfigs.filter(t => !t.isFacultyTabActive).includes(this.firstTabFacultyPortal)) {
            return false
        }

        if (this.firstTabStudentAndParentPortal !== undefined && this.tabConfigs.filter(t => !t.isStudentTabActive).includes(this.firstTabStudentAndParentPortal)) {
            return false
        }

        return true;
    }

    extends(data:any) {
        const { classGradebooks, ...remaning} = data;
        Object.assign(this, remaning);
        if (Array.isArray(classGradebooks) && classGradebooks.length > 0){
            this.classGradebooks = classGradebooks;
        }
    }

    toJS() {
        return ({
            classId                   : this.classId,
            schoolId                  : this.schoolId,
            gradingTerm               : this.gradingTerm,
            gradingScaleId            : this.gradingScaleId,
            pointScoreGuideId         : this.pointScoreGuideId,
            homepageId                : this.homepageId,
            banner                    : this.banner,
            bannerBlendOptions        : this.bannerBlendOptions,
            classCardImage            : this.classCardImage,
            classCardBlendOptions     : this.classCardBlendOptions,
            period                    : this.period,
            className                 : this.className,
            faculties                 : this.faculties,
            description               : this.description,
            defaultGroups             : this.defaultGroups?.filter(Boolean).map(g => g.toJS()) ?? [],
            tabConfigs                : this.tabConfigs.map(x => x.toJS()),
            googleCalendarId          : this.googleCalendarId,
            color                     : this.color,
            classFaculties            : this.classFaculties,
            gradingType               : this.gradingType,
            disableRosterSync         : this.disableRosterSync,
            createdBy                 : this.createdBy,
            oneRosterId               : this.oneRosterId,
            googleClassroomId         : this.googleClassroomId,
            sisGradeBookNumber        : this.sisGradeBookNumber,
            sisBidiMapping            : this.sisBidiMapping,
            sisClassGrade             : this.sisClassGrade,
            aeriesSyncPolicy          : this.aeriesSyncPolicy,
            linkedSectionId           : this.linkedSectionId,
            crosslistedSectionId      : this.crosslistedSectionId,
            startDate                 : this.startDate,
            endDate                   : this.endDate,
            isVisibleOnDashboard      : this.isVisibleOnDashboard,
            deleteCalendarAtEndOfTerm : this.deleteCalendarAtEndOfTerm,
            disableGoogleCalendar     : this.disableGoogleCalendar,
            previousGradingTermClassId: this.previousGradingTermClassId,
            previousGradingTermClassName: this.previousGradingTermClassName,
            previousGradingTermName   : this.previousGradingTermName,
            previousClassOneRosterId  : this.previousClassOneRosterId,
            applyScoresImmediately    : this.applyScoresImmediately,
            hidePercentageFromStudent : this.hidePercentageFromStudent,
            hideGrade                 : this.hideGrade,
            showAboutMe               : this.showAboutMe,
            isVisibleOnStudentDashboard : this.isVisibleOnStudentDashboard,
            applyFormativeSummative   : this.applyFormativeSummative,
            formativePercent          : this.formativePercent,
            summativePercent          : this.summativePercent,
            isSelfPacedLearning       : this.isSelfPacedLearning,
            selfPacedLearning         : toJS(this.selfPacedLearning),
            gradingFloor              : this.gradingFloor,
            joinPassword              : this.joinPassword,
            publicLinkId              : this.publicLinkId,
            canSelfEnroll             : this.canSelfEnroll,
            dateUpdated               : this.dateUpdated,
            hasClassEPortfolio        : this.hasClassEPortfolio,
            classLayout               : this.classLayout,
            classCourseId             : this.classCourseId,
            notifyStudentWhenPublishActivity :  this.notifyStudentWhenPublishActivity,
            notifyStudentWhenActivityIsGraded : this.notifyStudentWhenActivityIsGraded,
            notifyStudentWhenGradeHasComment : this.notifyStudentWhenGradeHasComment,
            isEnableGPTZero : this.isEnableGPTZero,
            studentsCanPreviewUnpublishedItems: this.studentsCanPreviewUnpublishedItems,
            externalId: this.externalId,
            credits: this.credits,
            isVisibleToPublic: this.isVisibleToPublic
        });
    }

    clone() {
        return new Class(this.toJS());
    }

    async createClassAsFacultyAdmin(facultyId:DbIdentity, primaryTeacherId?:DbIdentity) {
        const body = this.toJS();
        const { schoolId } = this;

        if (schoolId < 1) {
            const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/admin/faculty/${primaryTeacherId}/class`, body);
            return [err, (err ? undefined : new Class(x))!] as const;
        }

        const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/admin/school/${schoolId}/faculty/${primaryTeacherId}/class`, body);
        return [err, (err ? undefined : new Class(x))!] as const;
    }

    async save(facultyId:DbIdentity) {
        const body = this.toJS();
        const { schoolId, classId } = this;

        if (classId < 1) {
            if (schoolId < 1) {
                const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/class`, body);
                return [err, (err ? undefined : new Class(x))!] as const;
            }

            const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/school/${schoolId}/class`, body);
            return [err, (err ? undefined : new Class(x))!] as const;
        }

        const [err, x] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/class/${classId}`, body);
        return [err, (err ? undefined : new Class(x[0]))!] as const;
    }

    static async save(facultyId: DbIdentity, masterClass: Class, linkedClasses: Class[]) {
        const body = { ...masterClass!.toJS(), linkedClassesInfo: linkedClasses.map(cls => {
            return {
                classId: cls.classId,
                className: cls.className,
                description: cls.description,
                previousGradingTermClassId: cls.previousGradingTermClassId,
                gradingFloor: cls.gradingFloor,
                joinPassword: cls.joinPassword,
                canSelfEnroll: cls.canSelfEnroll,
                isSelfPacedLearning: cls.isSelfPacedLearning,
                selfPacedLearning: cls.selfPacedLearning,
                isEnableGPTZero: cls.isEnableGPTZero,
                classLayout: cls.classLayout,
                notifyStudentWhenPublishActivity: cls.notifyStudentWhenPublishActivity,
                notifyStudentWhenActivityIsGraded: cls.notifyStudentWhenActivityIsGraded,
                notifyStudentWhenGradeHasComment: cls.notifyStudentWhenGradeHasComment,
                externalId: cls.externalId,
            };
        })};
        const [err, clses] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/class/${masterClass!.classId}`, body);
        return [err, (err ? undefined : clses ? clses.map(x => new Class(x)): [])!] as const;
    }

    static async toggleDisableGoogleCalendar(facultyId: DbIdentity, classId: DbIdentity, disable: boolean) {
        const [err, clses] = await aFetch<{}[]>("PUT", `/faculty/${facultyId}/class/${classId}/toggleDisableGoogleCalendar`, disable);
        return [err, (err ? undefined : clses ? clses.map(x => new Class(x)) : [])!] as const;
    }

    static async fetchClassAsFaculty({facultyId, classId, signal}:{facultyId: DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/class/${classId}`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, (vm)!] as const;
    }
    static async fetchDeletedClassesAsFaculty({facultyId, signal}:{facultyId: DbIdentity, signal?: AbortSignal}) {
        const [err, dto] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/deletedClass`, undefined, { signal });
        const vm = err ? undefined : parseGeneralViewModel(dto);
        return [err, (vm)!] as const;
    }
    static hasDeletedClassAsFaculty({facultyId, signal}:{facultyId: DbIdentity, signal?: AbortSignal}) {
        return aFetch<boolean>("GET", `/faculty/${facultyId}/deletedClass/any`, undefined, { signal });
    }
    static async restoreClass({facultyId, classId, signal}:{facultyId: DbIdentity, classId:DbIdentity, signal?: AbortSignal}) {
        const [err, clss] = await aFetch<{}>("POST", `/faculty/${facultyId}/deletedClass/${classId}/restore`, undefined, { signal });
        return [err, (err ? undefined :  new Class(clss))!] as const;
    }

    static async fetchGradingtermsClassesAsFaculty({facultyId, gradingTermIds, signal }:{facultyId:DbIdentity, gradingTermIds:DbIdentity[], signal?: AbortSignal}) {
        gradingTermIds = gradingTermIds.slice().sort((a,b) => a-b);
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/classesInGradingTerm`, { tIds: gradingTermIds }, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchClassesAsFaculty({facultyId, gradingTermId, signal }:{facultyId:DbIdentity, gradingTermId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/gradingTerm/${gradingTermId}/class`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchClassesAsFacultyAdmin({facultyId, signal }:{facultyId:DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `/faculty/${facultyId}/gradingTerm/1/class`, undefined, { signal });
        return [err, (err ? undefined : parseGeneralViewModel(data).classes)!] as const;
    }

    static async searchClassesForUsageReport({schoolId, gradingTermId, classStartDate, classEndDate, facultyId, searchText, signal }:{schoolId: DbIdentity, gradingTermId: DbIdentity,classStartDate ?: NumberDate, classEndDate ?: NumberDate, facultyId:DbIdentity, searchText: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<Class[]>("POST", `/faculty/${facultyId}/feature-usage-report/searchClassForAutoComplete`
            , {...toJS({schoolId, gradingTermId, classStartDate, classEndDate, searchText})}
            , { signal });
        return [err, (err ? undefined : data.map(c=>new Class(c)))!] as const;
    }

    static async fetchClassesAsStudent({studentId, gradingTermId, signal}:{studentId: DbIdentity, gradingTermId?:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<{}[]>("GET", `/student/${studentId}/gradingTerm/${gradingTermId}/class`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new Class(x))] as const;
    }

    static async fetchClassSelfReflection({ facultyId, classId, activityId, signal }:{ facultyId: DbIdentity, classId: DbIdentity, activityId: DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<string>("GET", `/faculty/${facultyId}/class/${classId}/activity/${activityId}/classSelfReflection`, undefined, { signal });
        if (signal?.aborted) return [err, undefined] as const;

        return [err, !err ? xs: undefined] as const;
    }

    static sorter = {
        className  : <T extends Class>(a?: T, b?: T) => ((a ? a.className: "").localeCompare(b ? b.className : "")),
        displayName: <T extends Class>(a?: T, b?: T) => ((a ? a.displayName : "").localeCompare(b ? b.displayName : "")),
        displayNameWithoutPeriod: <T extends Class>(a?: T, b?: T) => ((a ? a.displayNameWithoutPeriod : "").localeCompare(b ? b.displayNameWithoutPeriod : "")),
        period     : <T extends Class>(a?: T, b?: T) => periodSort((a?.period ?? ""), (b?.period ?? "")),
        periodDesc : <T extends Class>(a?: T, b?: T) => -periodSort((a?.period ?? ""), (b?.period ?? "")),
        sortIndex  : <T extends Class>(a?: T, b?: T) => ((a?.sortIndex ?? Number.MAX_SAFE_INTEGER) - (b?.sortIndex ?? Number.MAX_SAFE_INTEGER)),
        schoolId   : <T extends Class>(a?: T, b?: T) => ((a?.schoolId ?? DefaultId) - (b?.schoolId ?? DefaultId)),
        gradingTerm: <T extends Class>(a : T, b : T) => (a.gradingTerm - b.gradingTerm),
        courseCode : <T extends Class>(a : T, b : T) => (a.courseCode.localeCompare(b.courseCode)),
        oneRosterId: <T extends Class>(a : T, b : T) => ((a.oneRosterId || '').localeCompare(b.oneRosterId || '')),


        sortIndexThenPeriod: <T extends Class>(a?: T, b?: T) => ((a?.sortIndex ?? Number.MAX_SAFE_INTEGER) - (b?.sortIndex ?? Number.MAX_SAFE_INTEGER))
                                                             || periodSort((a?.period ?? ""), (b?.period ?? "")),
        sortMasterClassThenPeriod: <T extends Class>(a?: T, b?: T) =>
                        (
                            ((a?.classId == a?.linkedSectionId) ? 0 : 1) - ((b?.classId == b?.linkedSectionId) ? 0 : 1)
                        )
                        || periodSort((a?.period ?? ""), (b?.period ?? "")),

        sortPeriodThenClassName: <T extends Class>(a?: T, b?: T) => ((a?.period ?? "").localeCompare(b?.period ?? "")
            || ((a ? a.className : "").localeCompare(b ? b.className : ""))),
        startDateDesc: <T extends Class>(a?: T, b?: T) => ((b?.startDate ?? 0) - (a?.startDate ?? 0)),
        gradingTermName: <T extends Class>(a?: T, b?: T) => ((a?.gradingTermName ?? "").localeCompare(b?.gradingTermName ?? "")),
    }

    static async fetchPreviousGradingTermClasses({ schoolId, facultyId, signal }: { schoolId: DbIdentity, facultyId : DbIdentity, signal?: AbortSignal }) {
        const [err, xs] = await aFetch<Class[]>("GET", `/faculty/${facultyId}/school/${schoolId}/prevGradingTermClass`, undefined, { signal });
        return [err, err ? [] : xs.map(x => new Class(x))] as const;
    }

    static async getGoogleClassroom({ classroomId, signal }:{signal?: AbortSignal, classroomId?: string}) {
        const [err, data] = await aFetch<IClassroom>("GET", `/google-classroom/${classroomId}`, undefined, { signal } );
        return [err, (err ? undefined : new GoogleCourse(data))] as const;
    }
    static async setHomepage({ classId, homepageId, facultyId }: { classId: DbIdentity, homepageId: DbIdentity, facultyId: DbIdentity }) {
        const [err, data] = await aFetch<Class[]>("PUT", `/faculty/${facultyId}/class/${classId}/setHomepage`, { homepageId });
        return [err, (err ? undefined : data.map(c => new Class(c)))] as const;
    }
    static async setStudentsCanPreviewUnpublishedItems({ classId, studentsCanPreviewUnpublishedItems, facultyId }: { classId: DbIdentity, studentsCanPreviewUnpublishedItems: boolean, facultyId: DbIdentity }) {
        const [err, data] = await aFetch<Class>("PUT", `/faculty/${facultyId}/class/${classId}/studentsCanPreviewUnpublishedItems`, { studentsCanPreviewUnpublishedItems });
        return [err, (err ? undefined : new Class(data))] as const;
    }

    static async getSisGradebooks({ facultyId, classId, signal }:{signal?: AbortSignal, classId: DbIdentity, facultyId: DbIdentity}) {
        const [err, data] = await aFetch<ISisGradebook[]>("GET", `/faculty/${facultyId}/class/${classId}/sisgradebook`, undefined, { signal } );
        return [err, (err ? undefined : data.map(c => new SisGradebook(c)))] as const;
    }
    static async fetchLastSync({ facultyId, classId, signal }:{signal?: AbortSignal, classId: DbIdentity, facultyId: DbIdentity}) {
        return await aFetch<number>("GET", `/faculty/${facultyId}/class/${classId}/sis/last-synced`, undefined, { signal } );
    }


    static async changeFinalGradeMode({ facultyId, classId, isFinal} : {isFinal: boolean, classId: DbIdentity, facultyId: DbIdentity}) {
        const [err, data] = await aFetch<IFinalModeChange>("PUT", `/faculty/${facultyId}/class/${classId}/sis/final-grade/${isFinal}`);
        return [err, (err ? undefined : data)] as const;
    }
    static async fetchSisSyncStatus({ facultyId, classId, signal }:{signal?: AbortSignal, classId: DbIdentity, facultyId: DbIdentity}) {
        const [err, xs] = await aFetch<{publicId: string, jobtype: JobTypeEnum}[]>("GET", `/faculty/${facultyId}/class/${classId}/sis/sync-status`, undefined, { signal } );
        return [err, xs?.filter(job => job.jobtype !== JobTypeEnum.SisSyncInit)] as const;
    }

    static async reviewSyncTasks({facultyId, classId, signal }:{signal?: AbortSignal, classId: DbIdentity, facultyId: DbIdentity}) {
        const [err, xs] = await aFetch<ISyncReport>("GET", `/faculty/${facultyId}/class/${classId}/sis/sync-review`, undefined, { signal } );
        return [err, (err ? undefined : xs)] as const;
    }

    static async retrySyncTask(props:{signal?: AbortSignal, classId: DbIdentity, facultyId: DbIdentity, activityId: number; studentId?: number; }) {
        const {facultyId, classId, activityId, studentId, signal } = props;
        const segment = studentId != null ? `activity/${activityId}/student/${studentId}`
            : `activity/${activityId}`
        const [err, xs] = await aFetch<IBaseSyncReport >("PUT", `/faculty/${facultyId}/sis/retry/class/${classId}/${segment}`
            , undefined, { signal } );
        return [err, (err ? undefined : xs)] as const;
    }
    static async setClassCard({ classId, color, classCardBlendOptions, classCardImage, facultyId }: { classId: DbIdentity, color: string, classCardBlendOptions?: BlendOptions, classCardImage?: string, facultyId: DbIdentity }){
        const [err, data] = await aFetch<Class[]>("PUT", `/faculty/${facultyId}/class/${classId}/setClassCard`, {
            color,
            cardImage: classCardImage,
            cardBlendOptions: classCardBlendOptions?.toJS()
        });
        return [err, (err ? undefined : data.map(c => new Class(c)))] as const;
    }

    static async fetchPublicClass(q:{publicLinkId:string, invitationId ?: string, signal?: AbortSignal}) {
        const url = q.invitationId ? `/public/class/${q.publicLinkId}/invitation/${q.invitationId}` : `/public/class/${q.publicLinkId}`;
        const [err, data] = await aFetch<{classInfo: PublicClassResponse, faculty: IPublicFacultyResponse, classInvitation: ClassInvitation}>("GET" , url)
        return [err,
                (err ? undefined : new PublicClassResponse(data.classInfo)),
                (err ? undefined : data.faculty),
                (err ? undefined : new ClassInvitation(data.classInvitation))
        ] as const;
    }

    static async processPublicClassUser(q:{publicLinkId:string, invitationId ?: string, passcode?: string, signal?: AbortSignal}) {
        let url = q.invitationId ? `/public/class/${q.publicLinkId}/invitation/${q.invitationId}/processPublicClassUser` : `/public/class/${q.publicLinkId}/processPublicClassUser`;
        if (!!q.passcode) url = `${url}?passcode=${q.passcode}`;
        const [err, data] = await aFetch<{publicClassResponseType: PublicClassResponseType, newToken: string}>("POST" , url);
        return [err, err ? undefined : data.publicClassResponseType, err ? undefined : data.newToken] as const;
    }

    static async CreateGradebook({classId, gradingTermIds, facultyId}: { classId: DbIdentity; gradingTermIds: DbIdentity[]; facultyId: DbIdentity }){
        return await aFetch<IClassGradebook[]>("POST", `/faculty/${facultyId}/class/${classId}/gradebooks/add`, gradingTermIds);
    }
}

export function periodSort(a:string = '', b:string = '') {
    const na = a ? Number(a) : NaN, nb = b ? Number(b) : NaN;
    if (!Number.isNaN(na) && !Number.isNaN(nb)) return na - nb;
    if (Number.isNaN(na) && Number.isNaN(nb)) return (a || '').localeCompare(b || '');
    return !Number.isNaN(na) ? -1 : 1;
}

export class GoogleCourse {
    classroomId: string = "";
    name: string = "";
    url: string = "";
    inviteCode?: string;

    constructor(data?: IClassroom) {
        if (data != null) Object.assign(this, data);

        makeObservable(this, {
            classroomId : observable,
            name : observable,
            url : observable,
            inviteCode : observable,
            inviteLink: computed,
        });
    }

    get inviteLink() {
        if (!this.url) return undefined;
        const queryString = !!this.inviteCode ? `cjc=${this.inviteCode}` : "";
        return this.url + "?" + queryString;
    }
}
export interface IClassroom {
    classroomId: string;
    name: string;
    url: string;
    inviteCode?: string;
}

interface ISisGradebook {
    classId         : DbIdentity;
    gradebookNumber : number;
    name           ?: string;
    sections        : ISection[];
}

interface ISection{
    gradebookNumber : number;
    schoolCode :number;
    sectionNumber:number;
}

export class SisGradebook {
    classId         : DbIdentity = DefaultId;
    gradebookNumber : number = 0;
    name           ?: string;
    aeriesUrl      ?: string;
    sections        : ISection[] = [];

    constructor(data?: ISisGradebook) {
        if (data != null) Object.assign(this, data);

        makeObservable(this, {
            classId        : observable,
            gradebookNumber: observable,
            name           : observable,
            aeriesUrl      : observable,
            isValid        : computed,
            sections       : observable.shallow
        });
    }

    get isValid() {
        return this.gradebookNumber > DefaultId
    }

    get hasName() {
        return this.gradebookNumber > DefaultId && this.name
    }
}
export enum PublicClassResponseType
{
    Unknown = 0,
    IsStudentClass = 1,
    NeedToCreateNewAcc = 2,
    NeedToLogout = 3,
    NeedToLogin = 4,
    GoToDashboard = 5,
    ShouldLogin = 6, //display registration url.
}

export class PublicClassResponse {
    period                ?: string         ;
    classId               ?: DbIdentity     ;
    className             ?: string         ;
    /** not public now */
    classCardImage        ?: string         ;
    classCardBlendOptions ?: BlendOptions   ;
    havePasscode           : boolean = false;
    responseType           : PublicClassResponseType = PublicClassResponseType.Unknown;
    publicLinkId          ?: string         ;

    constructor(data?:any) {
        if (data != null) {
            Object.assign(this, data);
        }
    }
}

export interface IPublicFacultyResponse {
    fullName ?: string ;
    email    ?: string ;
    aboutMe  ?: string ;
    /** not public now */
    avatar   ?: string ;
}


export class DistributionClassResponse {
    schoolId         : DbIdentity = DefaultId;
    schoolName       : string = ""           ;
    gradingTermId    : DbIdentity = DefaultId;
    gradingTermName  : string = ""           ;
    classId          : DbIdentity = DefaultId;
    className        : string = ""           ;
    courseCode      ?: string                ;
    sectionCode     ?: string                ;
    period          ?: string                ;
    isShared         : boolean = false       ;
    faculty         ?: Faculty = undefined   ;
    createdBy        : DbIdentity = DefaultId;
    distributerName ?: string                ;
    description     ?: string                ;

    constructor(data?:any) {
        if (data != null) {
            Object.assign(this, data);
            this.faculty = new Faculty(this.faculty);
        }
    }

    static async search(facultyId: DbIdentity, activityId: DbIdentity, search: {schoolId: DbIdentity, teacherIds: DbIdentity[], className ?: string, includeFutureTerm ?: boolean}, signal?: AbortSignal) {
        const [err, xs] = await aFetch<{}[]>("GET", `faculty/${facultyId}/activity/${activityId}/searchDistributionClasses`, search, { signal });
        return [err, (err ? [] : xs.map(x => new DistributionClassResponse(x)))] as const;
    }

    static async share(facultyId: DbIdentity, activityId: DbIdentity, targetClassIds: DbIdentity[], signal?: AbortSignal, distributerName?: string, description?: string) {
        const [err, xs] = await aFetch<{}[]>("POST", `faculty/${facultyId}/activity/${activityId}/share-content`, {targetClassIds, distributerName, description}, { signal });
        return [err, (err ? [] : xs.map(x => new ActivityDistribution(x)))] as const;
    }

    static async checkIsActivityDistribution(facultyId: DbIdentity, classId: DbIdentity, activityId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}>("GET", `faculty/${facultyId}/class/${classId}/activity/${activityId}/isActivityDistribution`, undefined, { signal });
        return [err, (err ? undefined : data ? new DistributionClassResponse(data) : undefined)] as const;
    }

    static sorter = {
        schoolName : (a: DistributionClassResponse, b: DistributionClassResponse) => a.schoolName.localeCompare(b.schoolName),
        className  : (a: DistributionClassResponse, b: DistributionClassResponse) => a.className.localeCompare(b.className),
        termName  : (a: DistributionClassResponse, b: DistributionClassResponse) => a.gradingTermName.localeCompare(b.gradingTermName),
    }
}

export enum SelfPaceCompleteType {
    All = 1,
    Specific = 2,
}

export class SelfPacedLearning {
    completeType = SelfPaceCompleteType.All; set_completeType(v: SelfPaceCompleteType) {this.completeType = v};
    minScore?: number; set_minScore(v?: number) {this.minScore = v};
    actId?: DbIdentity; set_actId(v?: DbIdentity) {this.actId = v};

    constructor(data?:any) {
        makeObservable(this, {
            completeType     : observable      ,
            set_completeType : action.bound    ,
            minScore         : observable      ,
            set_minScore     : action.bound    ,
            actId            : observable      ,
            set_actId        : action.bound    ,
        });

        Object.assign(this, data ?? {});
    }
}

interface SelfPacedLearningAct {
    minScore ?: number;
    actId    ?: DbIdentity;
}
class SelfPacedLearningV2 {
    completeType = SelfPaceCompleteType.All; set_completeType(v: SelfPaceCompleteType) {this.completeType = v};
    activities  : SelfPacedLearningAct[] = [];

    constructor(data ?:any) {
        makeObservable(this, {
            completeType     : observable      ,
            set_completeType : action.bound    ,
            activities       : observable.shallow,
        });

        Object.assign(this, data ?? {});
    }
}
import { observable, action, computed, makeObservable } from "mobx";
import {ActItem, IActItem, MatchQuizzItem, ActOption, PollQuizItem, EmbedItem, EActItemType, FillInBlankItem, FillInOption, FillInBlankItemType, QuestionTypes, MatchQuizzRowItem, MatchQuizzOption, NumericSliderOption} from './ActDoc';
import { DocId, DbIdentity, UrlString, HtmlString, DefaultId, NumberDate } from "./types";
import { aFetch, aFetchWithCache } from "../services/api/fetch";
import { createError } from "../services/api/AppError";
import { compact, sumBy, toNumber } from "lodash-es";
import { nanoid } from "nanoid";
import type { StudentActDocReport } from "../FacultyPortal/stores/StudentScoreReportStore";
import { BuildCacheName_StudentDoc, IsEnableCacheStudentDoc } from "../config";
import { cleanUpContent } from "../utils/html";
import { PdfDocument } from "../components/Pdf/type";
import { blob2File } from "../utils/file";
import { uploadGeneralFile } from "../services/api/upload";
import {PDFDocument} from 'pdf-lib';
import fontkit from '@pdf-lib/fontkit';
import { isEmpty } from "../utils/stringUtils";
import { StudentActItemContainer } from "./ActDoc/StudentActItemContainer";

interface ICorrectableAnswer {
    isCorrectAnswer?: boolean,
    toJS?: () => any
}
export class MatchQuizzAnswer extends MatchQuizzItem implements ICorrectableAnswer{
    isCorrectAnswer?: boolean;
    constructor(data: any) {
        super(data);
        makeObservable(this, {
            isCorrectAnswer: observable
        });

        if (data != null) {
            const { isCorrectAnswer, ...pData } = data;
            Object.assign(this, pData);
            this.isCorrectAnswer = isCorrectAnswer ?? null; //should be assigned to null if undefined, because null !== undefined and sometimes, cause the comparition issue
        }
    }
}
export class MatchQuizzRowAnswer extends MatchQuizzRowItem implements ICorrectableAnswer{
    isCorrectAnswer ?: boolean;
    constructor(data: any) {
        super(data);
        makeObservable(this, {
            isCorrectAnswer: observable
        });

        if (data != null) {
            const { isCorrectAnswer, ...pData } = data;
            Object.assign(this, pData);
            this.isCorrectAnswer = isCorrectAnswer ?? null;
        }
    }
}
export class HighLightTextAnswer implements ICorrectableAnswer {
    isCorrectAnswer?: boolean;
    value           : string = "";
    constructor(data: any) {
        makeObservable(this, {
            isCorrectAnswer: observable,
            value          : observable,
            toJS           : action.bound
        });

        if (data != null) {
            const { isCorrectAnswer, value } = data;
            this.value = value ?? "";
            this.isCorrectAnswer = isCorrectAnswer ?? null;
        }
    }
    toJS(){
        return { value: this.value };
    }
}
export class ActOptionAnswer extends ActOption implements ICorrectableAnswer {
    isCorrectAnswer?: boolean;
    constructor(data: any) {
        super(data);
        makeObservable(this, {
            isCorrectAnswer: observable
        });

        if (data != null){
            const { isCorrectAnswer, ...pData } = data;
            Object.assign(this, pData);
            this.isCorrectAnswer = isCorrectAnswer ?? null;
        }
    }
}
class NumberAnswer implements ICorrectableAnswer {
    isCorrectAnswer?: boolean;
    value?: number;
    constructor(data: any) {
        makeObservable(this, {
            isCorrectAnswer: observable,
            value: observable,
            toJS: action.bound
        });

        if (data != null) {
            const { isCorrectAnswer, value } = data;
            if (value != null) this.value = toNumber(value);
            this.isCorrectAnswer = isCorrectAnswer ?? null;
        }
    }
    toJS() {
        return { value: this.value };
    }
}
export class NumericSliderAnswer extends NumericSliderOption implements ICorrectableAnswer {
    isCorrectAnswer?: boolean;

    constructor(data: any) {
        super(data);

        makeObservable(this, {
            isCorrectAnswer: observable,
        });

        if (data != null) {
            const { isCorrectAnswer } = data;
            this.isCorrectAnswer = isCorrectAnswer ?? null;
        }
    }
}

export class FillInBlankAnswer extends FillInBlankItem implements ICorrectableAnswer {
    isCorrectAnswer?: boolean;

    constructor(data: any) {
        super(data);
        makeObservable(this, {
            isCorrectAnswer: observable
        });

        if (data != null) {
            const { isCorrectAnswer, ...pData } = data;
            Object.assign(this, pData);
            this.isCorrectAnswer = isCorrectAnswer ?? null;
        }
    }
}
export interface IStudentActItem extends IActItem {
    fromTeacher          : boolean             ;
    textAnswer           : string;
    matchQuizzAnswer     : MatchQuizzAnswer[];
    matchQuizzRowAnswer  : MatchQuizzRowAnswer[];
    optionsAnswer        : ActOptionAnswer[];
    pollItemsAnswer     ?: number[];
    numberAnswer        ?: NumberAnswer;
    googleDocAnswer     ?: EmbedItem;
    microsoftDocAnswer  ?: EmbedItem;
    fillInBlankAnswer   ?: FillInBlankAnswer[];
    highlightTextAnswer  : HighLightTextAnswer[];
    markUpImageAnswer   ?: UrlString;
    hasBlockWord        ?: boolean;
    numericSliderAnswer  : NumericSliderAnswer[];
    clicked             ?: boolean;
    buildActItemId()     : string;
    set_pollItemsAnswer  : (v: number[]) => void;
    set_pollTextAnswer   : (v: string) => void;
    toJS                 : () => Object;
}

interface IStudentActDoc {
    id        : DocId;
    activityId: DbIdentity;

    title     : string;
    banner    : UrlString;
    summary   : HtmlString;

    studentId : number;
    focusIndex: number | undefined;
    lockedIndex: number | undefined;
    items     : StudentActItem[];
    revision  : number;
    contentWidth?: number,
    contentHeight?: number,
    isTinyMCE?: boolean
}

export class StudentActItem extends ActItem implements IStudentActItem {
    fromTeacher          : boolean                     = true              ;
    textAnswer           : string                      = ""                ;
    markUpImageAnswer   ?: UrlString                   = undefined         ;
    matchQuizzAnswer     : MatchQuizzAnswer[]          = []                ;
    matchQuizzRowAnswer  : MatchQuizzRowAnswer[]       = []                ;
    optionsAnswer        : ActOptionAnswer[]           = []                ;
    numberAnswer        ?: NumberAnswer                = undefined         ;
    googleDocAnswer     ?: EmbedItem                   = undefined         ;
    microsoftDocAnswer  ?: EmbedItem                   = undefined         ;
    fillInBlankAnswer    : FillInBlankAnswer[]         = []                ;
    highlightTextAnswer  : HighLightTextAnswer[]       = []                ;
    numericSliderAnswer  : NumericSliderAnswer[]       = []                ;
    // UI
    clicked         : boolean          = false;
    hasBannedWords  : boolean          = false;
    hasBlockedWords : boolean          = false;

    constructor(data:any) {
        super(data);

        makeObservable(this, {
            fromTeacher        : observable,
            textAnswer         : observable, set_textAnswer     : action.bound,
            markUpImageAnswer  : observable, set_markUpImageAnswer: action.bound,
            markUpImagePreview : computed,
            matchQuizzAnswer   : observable,
            matchQuizzRowAnswer: observable,
            optionsAnswer      : observable, set_optionsAnswer  : action.bound,
            numberAnswer       : observable, set_numericAnswer  : action.bound,
            fillInBlankAnswer  : observable, set_fillInBlankAnswer: action.bound,
            googleDocAnswer    : observable,
            microsoftDocAnswer : observable,
            hasBannedWords     : observable,
            hasBlockedWords    : observable, set_hasBlockedWords: action.bound,
            clicked            : observable, set_btnClicked     : action.bound,
            isMissingAnswer    : computed,
            doRequireAction    : computed,
            highlightTextAnswer: observable,
            update_highlightTextAnswer: action.bound,
            update_matchQuizzAnswer   : action.bound,
            update_orderListAnswer    : action.bound,
            numericSliderAnswer: observable,
            set_numericSliderAnswer: action.bound,
            update_matchQuizzRowAnswer: action.bound,
            triggerFocus: action.bound,
            buildActItemId: action.bound,
            isAdditionalAnswer: computed,
            set_fillInBlankNumberAnswer: action.bound,
            fillInBlankAnswerSorted: action.bound
        });

        if (data != null) {
            const {
                matchQuizzAnswer, matchQuizzRowAnswer, options, optionsAnswer, fillInBlankAnswer, highlightTextAnswer,
                fromTeacher, textAnswer, pollItemsAnswer, pollTextAnswer, numberAnswer, googleDocAnswer, microsoftDocAnswer, clicked, markUpImageAnswer, numericSliderOptions, numericSliderAnswer
            } = data;

            this.matchQuizzAnswer = (Array.isArray(matchQuizzAnswer) && matchQuizzAnswer.length == this.matchQuizzItems.length)
                ? matchQuizzAnswer.map(mQ => new MatchQuizzAnswer(mQ))
                : this.matchQuizzItems.map(opt => new MatchQuizzAnswer(opt));

            this.matchQuizzRowAnswer = (
                (Array.isArray(matchQuizzRowAnswer) && matchQuizzRowAnswer.length > 0)
                    ? matchQuizzRowAnswer.map((opt) => new MatchQuizzRowAnswer(opt))
                        : this.matchQuizzRowItems.map((opt : MatchQuizzRowItem)  => {
                            return new MatchQuizzRowAnswer({
                                leftOptions: opt.leftOptions.length < 1
                                    ? [new MatchQuizzOption({id: nanoid()})]
                                    : opt.leftOptions.map(y => new MatchQuizzOption(y)),
                                rightOptions: [],
                                isEmptyQuestion: opt.isEmptyQuestion
                            });
                        }
                    )
            );

            if (Array.isArray(options)) this.options = options.map(i => new ActOption(i));

            if (this.type == EActItemType.OrderList) {
                if (Array.isArray(optionsAnswer) && optionsAnswer.length == this.options?.length) {
                    this.optionsAnswer = optionsAnswer.map(i => new ActOptionAnswer(i));
                } else {
                    this.optionsAnswer = this.options?.map(i => new ActOptionAnswer(i)) ?? [];
                }
            }
            else {
                if (Array.isArray(optionsAnswer) && optionsAnswer.length > 0) {
                    this.optionsAnswer = optionsAnswer.map(i => new ActOptionAnswer(i));
                }
            }

            if (Array.isArray(fillInBlankAnswer)) this.fillInBlankAnswer = fillInBlankAnswer.map(i => new FillInBlankItem(i));
            if (Array.isArray(highlightTextAnswer)) this.highlightTextAnswer = highlightTextAnswer.map(i => new HighLightTextAnswer(i));
            if (numberAnswer != null) this.numberAnswer = new NumberAnswer(numberAnswer);
            if (googleDocAnswer != null) {
                this.googleDocAnswer = new EmbedItem(googleDocAnswer);
                if(!this.googleDocAnswer.contentType) {
                    this.googleDocAnswer.contentType = this.embedContentType;
                }
            }
            
            if (microsoftDocAnswer != null) {
                this.microsoftDocAnswer = new EmbedItem(microsoftDocAnswer);
                if(!this.microsoftDocAnswer.contentType) {
                    this.microsoftDocAnswer.contentType = this.embedContentType;
                }
            }

            if (pollItemsAnswer != null) {
                if (Array.isArray(pollItemsAnswer)) {
                    this.pollItemsAnswer = pollItemsAnswer;
                }
                else {
                    this.pollItemsAnswer = [pollItemsAnswer];
                }
            } else {
                this.pollItemsAnswer = undefined;
            }

            if (pollTextAnswer !== "") {
                this.pollTextAnswer = pollTextAnswer;
            } else {
                this.pollTextAnswer = "";
            }

            if (Array.isArray(numericSliderOptions)) this.numericSliderOptions = numericSliderOptions.map(i => new NumericSliderOption(i));
            if (this.type == EActItemType.NumericSlider && Array.isArray(this.numericSliderOptions)) {

                const currentNumericSliderAnswer = Array.isArray(numericSliderAnswer) ? numericSliderAnswer.map(i => new NumericSliderAnswer(i)) : [];

                this.numericSliderAnswer = this.numericSliderOptions.map((x, index) => {
                    const answer = currentNumericSliderAnswer.find(y => y.id == x.id) ?? new NumericSliderAnswer(x);
                    if (answer.numberAnswer == null) {
                        let defaultAnswer = (this.numericMin || 0) + (index * (this.numericSliderInterval || 1));
                        if (defaultAnswer > (this.numericMax || 0)) defaultAnswer = (this.numericMax || 0);
                        answer.numberAnswer = defaultAnswer;
                    };
                    return answer;
                });
            }
            
            Object.assign(this, { fromTeacher, textAnswer, clicked, markUpImageAnswer});
        }
    }

    /** Make this container of act item is trigger focus event, this make sure actEffortLog work correct*/
    triggerFocus(){
        const el = document.getElementById(this.buildActItemId());
        if(el){
            el.focus();
        }
    }

    buildActItemId(){
        return provideId(this.id);
    }


    update_matchQuizzAnswer(matchAnswer: MatchQuizzItem[]) {
        for(var i = 0; i < matchAnswer.length; i++) {
            const x = matchAnswer[i]!;
            const y = this.matchQuizzAnswer.find(f => (f.hasLeftValue && f.isEqualLeft(x)) || (!f.hasLeftValue && f.id == x.id));
            if (!y) continue;
            y.right      = x.right;
            y.rightImage = x.rightImage;
            y.isCorrectAnswer = undefined;
        }
    }

    update_matchQuizzRowAnswer(matchAnswer: MatchQuizzRowItem[]) {
        this.matchQuizzRowAnswer = matchAnswer;
    }

    update_highlightTextAnswer(answers: HighLightTextAnswer[]) {
        this.highlightTextAnswer = answers;
    }
    get_optionsAnswer(item: ActOption) {
        return this.optionsAnswer.find(x => x.isEqual(item));
    }
    set_hasBlockedWords(v: boolean) { this.hasBlockedWords = v; };
    set_optionsAnswer(item: ActOption, v: boolean) {
        for(var i = 0; i < this.optionsAnswer.length; i++){
            if(this.optionsAnswer[i]!.isEqual(item)) {
                if(!v) this.optionsAnswer.splice(i, 1);
                return;
            }
        }
        if(!this.isMultipleAnswer) {
            this.optionsAnswer = [];
        }
        this.optionsAnswer.push(new ActOptionAnswer({label:item.label, image:item.image, id: item.id}));
    };

    update_orderListAnswer(answers: ActOption[]) {
        this.optionsAnswer = answers.slice();
    }

    set_fillInBlankAnswer(item: FillInBlankItem, answer: FillInOption) {
        var selectedOption = item.type == FillInBlankItemType.DropDown ? item.options.find(o => o.id == answer.id || (!o.hasId && o.getLabel == answer.getLabel)) : new FillInOption(answer);
        if (!selectedOption) return;
        const _fillInBlankAnswer = new FillInBlankAnswer(item)
        _fillInBlankAnswer.set_options([selectedOption]);

        if (this.fillInBlankAnswer.length > 0) {
            const index = this.fillInBlankAnswer.findIndex(a => a.id == item.id);
            if (index >= 0) {
                this.fillInBlankAnswer.splice(index, 1);
            }
        }
        this.fillInBlankAnswer.push(_fillInBlankAnswer);
    };

    set_fillInBlankNumberAnswer(item: FillInBlankItem, numberAnswer ?: number) {
        const _fillInBlankAnswer = new FillInBlankAnswer(item);
        _fillInBlankAnswer.set_numberAnswer(numberAnswer);

        if (this.fillInBlankAnswer.length > 0) {
            const index = this.fillInBlankAnswer.findIndex(a => a.id == item.id);
            if (index >= 0) {
                this.fillInBlankAnswer.splice(index, 1);
            }
        }
        this.fillInBlankAnswer.push(_fillInBlankAnswer);
    };

    set_numericAnswer       (v: number|undefined) { this.numberAnswer        = new NumberAnswer({value: v}); }
    set_textAnswer          (v: string          ) { this.textAnswer          = v  ; }
    set_markUpImageAnswer   (v?: string         ) { this.markUpImageAnswer   = v  ; }
    set_btnClicked          (v: boolean         ) { this.clicked             = v  ; }
    set_numericSliderAnswer (v: NumericSliderAnswer[]) { this.numericSliderAnswer =                     v  ; }


    get isMissingAnswer(): boolean {
        switch (this.type) {
            case EActItemType.ChoiceQuiz     : return !this.optionsAnswer?.length       ;
            case EActItemType.MatchQuiz      : return !this.matchQuizzAnswer?.length    ;
            case EActItemType.MatchQuizN     : return !this.matchQuizzRowAnswer?.some(x => x.rightOptions.length > 0) ;
            case EActItemType.NumericQuestion: return this.numberAnswer?.value == null  ;
            case EActItemType.NumericSlider  : return !this.numericSliderAnswer?.length ;
            case EActItemType.PollQuiz       : return this.pollItemsAnswer == null && isEmpty(this.pollTextAnswer);
            case EActItemType.TextQuiz       : return !this.textAnswer?.trim().length   ;
            case EActItemType.HighlightText  : return !this.highlightTextAnswer.length  ;
            case EActItemType.FillInBlank    : return !this.fillInBlankAnswer.length    ;
            case EActItemType.GoogleAssignment: return !this.googleDocAnswer            ;
            case EActItemType.MicrosoftAssignment: return !this.microsoftDocAnswer      ;
            case EActItemType.OrderList      : return !this.optionsAnswer?.length       ;
            case EActItemType.MarkUpImage    : return !this.markUpImageAnswer           ;
            case EActItemType.Video          : return !this.mediaFile && !this.link     ;
            case EActItemType.Image          : return !this.imageFile && !this.image    ;
            default                          : return false                             ;
        }
    }

    get doRequireAction() { return QuestionTypes.has(this.type)}

    get markUpImagePreview() { return this.markUpImageAnswer || this.image;}

    fillInBlankAnswerSorted() {
        if (this.fillInBlankAnswer.length <= 0) return [] as FillInBlankItem[];

        const fillInBlankAnswer: FillInBlankAnswer[] = [];

        this.fillInBlankItems?.map(item => {
            const answerItem = this.fillInBlankAnswer.find(a => a.id == item.id);
            if (answerItem) fillInBlankAnswer.push(answerItem);
        });

        return fillInBlankAnswer;
    };

    private get _cleanedUpTextAnswer(){
        return cleanUpContent(this.textAnswer);
    }

    protected override get _cleanedUpContent(){
        return cleanUpContent(this.content);
    }

    get isAdditionalAnswer() { return !this.fromTeacher && this.type === EActItemType.Text};

    override toJS() {
        return ({
            id                 : this.id,
            type               : this.type,
            title              : this.title,
            image              : this.image,
            image2             : this.image2,
            content            : this._cleanedUpContent,
            startDate          : this.startDate,
            endDate            : this.endDate,
            link               : this.link,
            link2              : this.link2,
            contentType        : this.contentType,
            contentType2       : this.contentType2,
            mediaType          : this.mediaType,
            mediaType2         : this.mediaType2,
            embedLink          : this.embedLink,
            embedContentType   : this.embedContentType,
            embedId            : this.embedId,
            embedApp           : this.embedApp,
            blockVideoSeeking  : this.blockVideoSeeking,
            isEmbedLink        : this.isEmbedLink,
            pdfLink            : this.pdfLink,
            matchQuizzItems    : this.matchQuizzItems.map(i => i.toJS()),
            numericMin         : this.numericMin,
            numericMax         : this.numericMax,
            isMultipleAnswer   : this.isMultipleAnswer,
            isRequireAllCorrect: this.isRequireAllCorrect,
            allowAnyOrderResponses: this.allowAnyOrderResponses,
            options            : this.options  ?.map(opt   => new ActOption(opt).toJS()),
            pollItems          : this.pollItems?.map(opt => new PollQuizItem(opt).toJS()),
            pollType           : this.pollType,
            fromTeacher        : this.fromTeacher                            ,
            textAnswer         : this._cleanedUpTextAnswer                   ,
            matchQuizzAnswer   : this.matchQuizzAnswer.map(opt => opt.toJS()),
            matchQuizzRowAnswer: this.matchQuizzRowAnswer.map(opt => new MatchQuizzRowItem(opt).toJS()),
            optionsAnswer      : this.optionsAnswer.map(opt => opt.toJS()),
            fillInBlankAnswer  : this.fillInBlankAnswerSorted().map(ans => ans.toJS()),
            highlightTextAnswer: this.highlightTextAnswer.map(ans => ans.toJS()),
            pollItemsAnswer    : this.pollItemsAnswer                        ,
            pollTextAnswer     : this.pollTextAnswer                        ,
            numberAnswer       : this.numberAnswer?.toJS()                   ,
            googleDocAnswer    : this.googleDocAnswer?.toJS()                ,
            microsoftDocAnswer : this.microsoftDocAnswer?.toJS()                ,
            items              : this.items.map(x => new ActItem(x).toJS())  ,
            clicked            : this.clicked                                ,
            annotations        : this.annotations                            ,
            fillInBlankItems   : this.fillInBlankItems?.map(it => it.toJS()) ,
            correctHighlightItems: this.correctHighlightItems                ,
            highlightTextItems : this.highlightTextItems                     ,
            highlightTextContent: this.highlightTextContent                  ,
            matchQuizzRowItems : this.matchQuizzRowItems.map(it => it.toJS()),
            embedFrameHeight   : this.embedFrameHeight,
            subType            : this.subType,
            hasBlockWord       : this.hasBlockWord,
            markUpImageAnswer  : this.markUpImageAnswer,
            numericSliderAnswer: this.numericSliderAnswer.map(opt => new NumericSliderAnswer(opt).toJS()),
        });
    }

}

export class StudentPdfFormActItem extends StudentActItem {
    pdfFormAnswer       ?: UrlString          = undefined    ;
    pdfDoc              ?: PdfDocument;  set_pdfDoc(v?: PdfDocument) { this.pdfDoc = v; }
    constructor(data:any) {
        const {pdfDoc, pdfFormAnswer, ...pData} = data ?? {}; 
        super(pData);

        makeObservable(this, {
            pdfFormAnswer      : observable, set_pdfFormAnswer  : action.bound,
            saveAnswer         : action.bound,
            pdfDoc             : observable.ref, set_pdfDoc      : action.bound,
        });

        if (data != null) {
            Object.assign(this, { pdfFormAnswer });
        }
    }
    set_pdfFormAnswer       (v?: UrlString         ) { this.pdfFormAnswer       = v  ; }
    async saveAnswer(){
        try {
            if(this.pdfDoc){
                const rawPdfData = await this.pdfDoc.saveDocument();
                const pdfDoc = await PDFDocument.load(rawPdfData);
                pdfDoc.registerFontkit(fontkit);
                const url = '/standard_fonts/Helvetica.ttf';
                const fontBytes = await fetch(url).then(res => res.arrayBuffer());
                const fontHelvetica = await pdfDoc.embedFont(fontBytes);
                const form = pdfDoc.getForm();
                const rawUpdateFieldAppearances = form.updateFieldAppearances.bind(form);
                form.updateFieldAppearances = function () {
                    return rawUpdateFieldAppearances(fontHelvetica);
                 };
                form.updateFieldAppearances(fontHelvetica)


                const savedData = await pdfDoc.save();
                if(savedData){
                    const [uErr, {link}] = await uploadGeneralFile(blob2File(new Blob([savedData], { type: 'application/pdf' }), `${nanoid().substring(0, 8)}.pdf`));
                    if(!uErr){
                        this.set_pdfFormAnswer(link);
                    }
                    return uErr;
                }
            }
        } catch (e: unknown) {
            console.error(e);
        }
        return;
    }
    override toJS() {
        return ({...super.toJS(), pdfFormAnswer: this.pdfFormAnswer});
    }
}

export class StudentActDoc extends StudentActItemContainer implements IStudentActDoc {
    id                    : DocId            = "";
    activityId            : DbIdentity       = DefaultId;
    studentId             : DbIdentity       = DefaultId;
    title                 : string           = "";
    banner                : UrlString        = "";
    summary               : HtmlString       = "";
    comments              : DocId[]          = [];
    dateCreated           : NumberDate       = 0;
    focusIndex            : number           = 0;
    lockedIndex           : number           = 0;
    revision              : number           = 0;
    hasNewActDocUpdate   ?: boolean          = undefined;
    alreadyUpdatedDoc    ?: boolean          = undefined;
    startTime            ?: NumberDate       = 0;
    serverTimeNow        ?: NumberDate       = 0;
    isSubmitted          ?: boolean          = undefined;
    contentWidth    ?: number           = undefined;
    contentHeight        ?: number           = undefined;
    isTinyMCE            ?: boolean = undefined;

    /** @description to check this doc has been completely created. */
    isInProgress          : boolean          = false;
    // for track now
    groupId               : string           = "";
    isFirstOpened         : boolean          = false;

    set_focusIndex(v: number) { this.focusIndex = v }
    set_contentWidth(v: number) { this.contentWidth = v }
    set_contentHeight(v: number) { this.contentHeight = v }

    get teacherItems() { return this.items.filter(x =>  x.fromTeacher)?.map(x => new StudentActItem(x))! }
    get studentItems() { return this.items.filter(x => !x.fromTeacher)?.map(x => new StudentActItem(x))! }

    constructor(data:any) {
        super(data);
        makeObservable(this, {
            title          : observable,
            banner         : observable,
            summary        : observable,
            comments       : observable.shallow,
            focusIndex     : observable,
            lockedIndex    : observable,
            revision       : observable,
            isSubmitted    : observable,
            set_focusIndex : action.bound,
            isTinyMCE      : observable,
            teacherItems   : computed,
            studentItems   : computed,
            totalPoints    : computed,
            cleanAnswers   : action.bound,
            set_contentWidth: action.bound,
            set_contentHeight    : action.bound,
        });

        if (data != null) {
            const {items, ...pData} = data;
            if (Array.isArray(items)) this.items = items.map(item => {
                if(item.type === EActItemType.PdfForm){
                    return new StudentPdfFormActItem(item);
                }
                return new StudentActItem(item);
            })
            Object.assign(this, pData);
        }
    }

    override toJS(): StudentActDoc {
        return ({
            id        : this.id,
            activityId: this.activityId,
            studentId : this.studentId,
            title     : this.title,
            banner    : this.banner,
            summary   : this.summary,
            focusIndex: this.focusIndex,
            lockedIndex: this.lockedIndex,
            items     : this.items.map(item => item.toJS()),
            revision  : this.revision
            // comments  : this.comments,
        });
    }

    isQuestionDoc() {
        if (this.items.find(x => QuestionTypes.has(x.type)))
            return true;
        return false;
    }

    cleanAnswers() {
        this.items = this.items.map(x => {
            if (!x.fromTeacher && x.type == EActItemType.Text) x.content = "";
            if(x.type === EActItemType.PdfForm){
                const item = new StudentPdfFormActItem(new ActItem(x).clone());
                item.pdfFormAnswer = undefined;
                return item;
            }
            return new StudentActItem(new ActItem(x).clone()) as IStudentActItem;
        });
    }

    get totalPoints() {
        const pointScores = compact(this.items.filter(s => !s.isExtraCredit).map(s => s.pointScore));
        return sumBy(pointScores, s => s)
    }

    async save(ps?: {contentWidth?: number, contentHeight?: number}) {
        if (this.activityId < 1) return [createError(new Error(`Invalid activityId`), 400), this] as const;

        if (IsEnableCacheStudentDoc) {
            const cacheName = BuildCacheName_StudentDoc(this.studentId, this.activityId);
            const [err, x] = await aFetchWithCache<IStudentActDoc>(cacheName, "PUT", `/student/${this.studentId}/activity/${this.activityId}/doc${(!!ps?.contentHeight && !!ps?.contentWidth) ? `?${new URLSearchParams(ps).toString()}` : ""}`, this.toJS(), undefined)
            return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
        }

        const [err, x] = await aFetch<IStudentActDoc>("PUT", `/student/${this.studentId}/activity/${this.activityId}/doc${(!!ps?.contentHeight && !!ps?.contentWidth) ? `?${new URLSearchParams(ps).toString()}` : ""}`, this.toJS(), undefined)
        return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
    }

    async saveDocAsFaculty({ facultyId, ps } : {facultyId: DbIdentity, ps?: {contentWidth?: number, contentHeight?: number}}) {
        if (this.activityId < 1) return [createError(new Error(`Invalid activityId`), 400), this] as const;

        const [err, x] = await aFetch<IStudentActDoc>("PUT", `/faculty/${facultyId}/activity/${this.activityId}/onBehalfOfStudent/${this.studentId}/doc${(!!ps?.contentHeight && !!ps?.contentWidth) ? `?${new URLSearchParams(ps).toString()}` : ""}`, this.toJS(), undefined)
        return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
    }

    // save log only
    async saveLog() {
        if (this.activityId < 1) return [createError(new Error(`Invalid activityId`), 400)] as const;;

        const [err, _] = await aFetch("PUT", `/student/${this.studentId}/activity/${this.activityId}/saveActDocLog`, this.toJS(), undefined)
        return [err] as const;
    }

    static async fetchForFaculty({facultyId, activityId, studentId, signal}:{facultyId: DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<IStudentActDoc>("GET", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/doc`, undefined, { signal });
        return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
    }

    // save log only
    async saveLogOnBehalfOfStudent({ facultyId }: { facultyId: DbIdentity }) {
        if (this.activityId < 1) return [createError(new Error(`Invalid activityId`), 400)] as const;;

        const [err, _] = await aFetch("PUT", `/faculty/${facultyId}/onBehalfOfStudent/${this.studentId}/activity/${this.activityId}/saveActDocLog`, this.toJS(), undefined)
        return [err] as const;
    }

    static async pullAsFacultyOnBehalfOfStudent({ facultyId, activityId, studentId}: {facultyId: DbIdentity, activityId:DbIdentity, studentId: DbIdentity}) {
        const [err, x] = await aFetch<{}>("POST", `/faculty/${facultyId}/activity/${activityId}/onBehalfOfStudent/${studentId}/doc/pull`);
        return [err, (err ? undefined : new StudentActDoc(x))!] as const;
    }

    static async fetchForFacultyOnBehalfOfStudent({facultyId, activityId, studentId, signal}:{facultyId: DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<IStudentActDoc>("GET", `/faculty/${facultyId}/activity/${activityId}/onBehalfOfStudent/${studentId}/doc`, undefined, { signal });
        return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
    }

    static async fetchRevisionsForFaculty({facultyId, activityId, studentId, signal}:{facultyId: DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<IStudentActDoc[]>("GET", `/faculty/${facultyId}/activity/${activityId}/student/${studentId}/doc/revisions`, undefined, { signal });
        return [err, ((err || !xs) ? [] : xs.map(i => new StudentActDoc(i)))!] as const;
    }

    static async fetchGroupForFaculty({facultyId, activityId, groupId, signal}:{facultyId: DbIdentity, activityId:DbIdentity, groupId:string, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<IStudentActDoc[]>("GET", `/faculty/${facultyId}/activity/${activityId}/group/${groupId}/doc`, undefined, {signal});
        return [err, ((err||!xs) ? [] : xs.map(item => new StudentActDoc(item)))!] as const;
    }

    static async fetchStudentActDocReportForFaculty({facultyId, activityId, studentIds, signal}:{facultyId: DbIdentity, activityId:DbIdentity, studentIds?: DbIdentity[], signal?: AbortSignal}) {
        const [err, xs] = await aFetch<StudentActDocReport[]>("GET", `/faculty/${facultyId}/activity/${activityId}/studentPerformance`, !studentIds ? undefined : {studentIds: studentIds}, { signal });
        return [err, (err ? [] : xs)!] as const;
    }

    static async fetchStudentActItemReportForFaculty({facultyId, activityId, questionId, signal}:{facultyId: DbIdentity, activityId:DbIdentity, questionId: string, signal?: AbortSignal}) {
        const [err, data] = await aFetch<StudentActDocReport>("GET", `/faculty/${facultyId}/activity/${activityId}/question/${questionId}/studentPerformance`, undefined, { signal });
        return [err, (err ? undefined : data)!] as const;
    }

    static async fetchRevisionsGroupForFaculty({facultyId, activityId, groupId, signal}:{facultyId: DbIdentity, activityId:DbIdentity, groupId:string, signal?: AbortSignal}) {
        const [err, xs] = await aFetch<IStudentActDoc[]>("GET", `/faculty/${facultyId}/activity/${activityId}/group/${groupId}/doc/revisions`, undefined, { signal });
        return [err, (err ? [] : xs.map(item => new StudentActDoc(item)))!] as const;
    }

    static async fetchAllSubmissionsAsFaculty({facultyId, activityId, studentIds, signal}:{facultyId: DbIdentity, activityId:DbIdentity, studentIds?: number[], signal?: AbortSignal}) {
        const [err, xs] = await aFetch<IStudentActDoc[]>("GET", `/faculty/${facultyId}/activity/${activityId}/all-submissions`, { studentIds: studentIds }, { signal });
        return [err, (err ? [] : xs.map(item => new StudentActDoc(item)))!] as const;
    }

    static async fetchForStudent({ studentId, activityId, password, ignoreStudentCache, signal }: {studentId:DbIdentity, activityId:DbIdentity, password?: string, ignoreStudentCache ?: boolean, signal?: AbortSignal}) {
        if (IsEnableCacheStudentDoc && !ignoreStudentCache) {
            const cacheName = BuildCacheName_StudentDoc(studentId, activityId);
            const [err, x] = await aFetchWithCache<IStudentActDoc>(cacheName, "GET", `/student/${studentId}/activity/${activityId}/doc`, {password}, { signal });
            return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
        }

        const [err, x] = await aFetch<IStudentActDoc>("GET", `/student/${studentId}/activity/${activityId}/doc`, {password}, { signal });
        return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
    }

    static async fetchRevisionsForStudent(studentId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<IStudentActDoc[]>("GET", `/student/${studentId}/activity/${activityId}/doc/revisions`, undefined, { signal });
        return [err, (err ? [] : data ? data.map(x => new StudentActDoc(x)) : [])!] as const;
    }

    static async fetchAsPreview({ facultyId, activityId, signal }: {facultyId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<IStudentActDoc>("GET", `/faculty/${facultyId}/previewStudent/activity/${activityId}/doc`, undefined, { signal });
        return [err, (err ? undefined : new StudentActDoc(x))!] as const;
    }

    static async fetchForParent({parentId, activityId, studentId, signal}:{parentId: DbIdentity, activityId:DbIdentity, studentId:DbIdentity, signal?: AbortSignal}) {
        const [err, x] = await aFetch<IStudentActDoc>("GET", `/parent/${parentId}/student/${studentId}/activity/${activityId}/doc`, undefined, { signal });
        return [err, ((err || !x) ? undefined : new StudentActDoc(x))!] as const;
    }

    static async fetchGroupForParent({parentId, studentId, activityId, groupId, signal}:{parentId: DbIdentity, studentId: DbIdentity, activityId:DbIdentity, groupId:string, signal?: AbortSignal}) {
        const [err, x] = await aFetch<IStudentActDoc[]>("GET", `/parent/${parentId}/student/${studentId}/activity/${activityId}/group/${groupId}/docList`, undefined, { signal });
        return [err, (err ? undefined : x.map(item => new StudentActDoc(item)))!] as const;
    }

    static async fetchStudentActDocListInGroupForStudent({studentId, activityId, groupId, signal}:{studentId: DbIdentity, activityId:DbIdentity, groupId:string, signal?: AbortSignal}) {
        const [err, x] = await aFetch<IStudentActDoc[]>("GET", `/student/${studentId}/activity/${activityId}/group/${groupId}/docList`, undefined, { signal });
        return [err, (err ? undefined : x.map(item => new StudentActDoc(item)))!] as const;
    }

    static async fetchRevisionsOfStudentActDocInGroupForStudent({studentId, activityId, groupId, signal}:{studentId: DbIdentity, activityId:DbIdentity, groupId:string, signal?: AbortSignal}) {
        const [err, x] = await aFetch<IStudentActDoc[]>("GET", `/student/${studentId}/activity/${activityId}/group/${groupId}/doc/revisions`, undefined, { signal });
        return [err, (err ? undefined : x.map(item => new StudentActDoc(item)))!] as const;
    }

    static async pullAsStudent({activityId, studentId}:{activityId:DbIdentity, studentId:DbIdentity}) {
        const [err, x] = await aFetch<IStudentActDoc>("POST", `/student/${studentId}/activity/${activityId}/doc/pull`);
        return [err, (err ? undefined : new StudentActDoc(x))!] as const;
    }

    static sorter = {
        dateCreated     : (a: StudentActDoc, b: StudentActDoc) => ((a.dateCreated || 0) - (b.dateCreated || 0)),
        revision        : (a: StudentActDoc, b: StudentActDoc) => ((a.revision || 0) - (b.revision || 0)),
    }
}

const provideId = (actItemId: string) => `actItem_${actItemId}`;
const findActItem = (actItemId: string) => document.getElementById(provideId(actItemId));

function triggerBlur (element?: HTMLElement | null) {
    element?.focus();
    element?.blur();
}

export const triggerActItemBlur = (actItemId: string) => triggerBlur(findActItem(actItemId)?.closest('div'));
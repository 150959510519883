import { action, makeObservable } from "mobx";
import { ItemContainer } from "../ItemContainer";
import { EActItemType } from "./EActItemType";
import { IErrorData } from "../../services/api/AppError";
import { StudentActItem } from "../StudentActDoc";

export class StudentActItemContainer extends ItemContainer<StudentActItem> {
    constructor(data ?:  ItemContainer<StudentActItem>) {
        super(data);
        makeObservable(this, {
            refine             : action.bound,
        }
    );

        if (data != null) {
            const { items } = data;
            if (Array.isArray(items)) this.items = items == null ? [] : items.map(item => new StudentActItem(item));
        }
    }

    override toJS() {
        return ({
            items : this.items.map(item => item.toJS()),
        });
    }

    override removeItem(item: StudentActItem) {
        this.items.map((x) => {
            if (x.discussionItem == item)
                x.discussionItem =  undefined;
        });
        super.removeItem(item);
        if (this.items.length == 0) this.items.push(new StudentActItem({type: EActItemType.Text, content: ""}));
    }

    /** only for add default when items length < 1 */
    private _addDefaultTextItem() {
        if (this.items.length == 0) this.addItem(new StudentActItem({type: EActItemType.Text, content: ""}));
    }

    override get isEmptyList() {
        if (super.isEmptyList) return true;
        const validItems = this.items.filter(x => x.type == EActItemType.Text && !x.isEmptyContent);
        return validItems && validItems.length < 1;
    }
    /**
     * @description: remove/fix and validate ActItem[] data before submitting to server
     * @returns [error, error]
     */
    refine(): [IErrorData<{code:string}>|undefined, StudentActItem|undefined] {
        if (this == null) return [undefined, undefined];
        // don't need to check valid items in student act doc
        // const eitem = isValidActItems(this.items);
        // if (eitem) {
            this._addDefaultTextItem();
            // return [eitem.error, eitem.item];
        // }
        return [undefined, undefined];
    }
}
import type { UploadFile } from "antd/lib/upload/interface";

import { DefaultId, DbIdentity, UrlString } from "./types";

import { aFetch, apiHost, uploadFile2, uploadFileChunks, versionPrefix } from "../services/api/fetch";
import { BaseActivity } from "./BaseActivity";
import { ActDoc } from "./ActDoc";
import { ResourceModule } from "./ResourceModule";
import { action, computed, makeObservable, observable } from "mobx";
import { ActivityType } from "./Activity";
import { isGoogleDriveUrl } from "../components/GoogleApis/utils";
import { isBlobFile } from "../utils/file";
import { stripHtml } from "../utils/html";
import { InstructionActDoc } from "./ActDoc/InstructionActDoc";

export class ResourceActivity extends BaseActivity {
    resourceGroupId       : DbIdentity = DefaultId;
    attachmentUrl         : UrlString  = ''       ;
    attachmentContentType : string      = ''       ;
    isShowAsImage         ?: boolean   = false    ;
    ignoreCompleteMark                 = false    ;
    isTinyMCE             ?: boolean;

    static LtiAttachmentContentType = 'lti';

    /** @deprecated */
    parentFolderId  ?: DbIdentity;
    /** @deprecated */
    document        ?: ActDoc = undefined;
    thumbnail?: string | undefined;
    description?: string; set_description(v: string) { this.description = v; }

    constructor(data?:any) {
        super(data);
        makeObservable(this, {
            resourceGroupId       : observable ,
            attachmentUrl         : observable ,
            attachmentContentType : observable ,
            isShowAsImage         : observable ,
            ignoreCompleteMark    : observable ,
            description           : observable ,
            set_isShowAsImage     : action.bound,
            haveContent           : computed   ,
            attachmentType        : computed   ,
            isTinyMCE             : observable ,
            set_description       : action.bound,
        });
        if (data != null) {
            const {document, ...pData} = data;
            Object.assign(this, pData);
            if (document) this.document = new ActDoc(document);
        }
    }

    set_isShowAsImage(v ?: boolean) {this.isShowAsImage = v;}

    get haveContent() {
        // Resource Discussion have not content for now, and some other Resource not support content for now
        return this.type == ActivityType.Activity
                || this.type == ActivityType.Assessment
                || this.type == ActivityType.Assignment
    }

    get attachmentType(){
        if (isGoogleDriveUrl(this.attachmentUrl ?? "")) return "googleUrl";
        if (this.attachmentUrl && isBlobFile(this.attachmentUrl)) return "file";
        return "url";
    }

    override get params() { return ({activityId:String(this.activityId), resourceGroupId:String(this.resourceGroupId)}) }

    toJS() {
        return ({
            activityId           : this.activityId                 ,
            parentFolderId       : this.parentFolderId             ,
            type                 : this.type                       ,
            title                : this.title                      ,
            dateAssigned         : this.dateAssigned               ,
            dateDue              : this.dateDue                    ,
            dateCutoff           : this.dateCutoff                 ,
            categoryId           : this.categoryId                 ,
            color                : this.color                      ,
            isGraded             : this.isGraded                   ,
            isCredit             : this.isCredit                   ,
            maxScore             : this.maxScore                   ,
            weight               : this.weight                     ,
            sortIndex            : this.sortIndex                  ,
            isGroup              : this.isGroup                    ,
            isDeleted            : this.isDeleted                  ,
            groups               : this.groups                     ,
            rubricScoreGuideId   : this.rubricScoreGuideId         ,
            pointScoreGuideId    : this.pointScoreGuideId          ,
            timeLimit            : this.timeLimit                  ,
            isPublishGrade       : this.isPublishGrade             ,
            qualityIds           : this.selectedTags.map(q => q.id),
            submissionType       : this.submissionType             ,
            isFormative          : this.isFormative                ,
            isShowAsImage        : this.isShowAsImage              ,
            attachmentUrl        : this.attachmentUrl              ,
            attachmentContentType: this.attachmentContentType      ,
            ignoreCompleteMark   : this.ignoreCompleteMark         ,
            isTinyMCE            : this.isTinyMCE                  ,
            thumbnail            : this.thumbnail                  ,
            description          : this.description                ,
        });
    }

    async save(createData: {}) {
        if (this.activityId < 1) {
            const [err, data] = await aFetch<{resourceActivities:{}[], resourceModule:{}[]}>("POST", `/resourceGroup/${this.resourceGroupId}/activity`, { ...this.toJS(), ...createData });
            const resourceActivities = err ? [] : data.resourceActivities.map(a => new ResourceActivity(a));
            const resourceModules = err ? [] : data.resourceModule.map(a => new ResourceModule(a));
            return [err, { resourceActivities, resourceModules }] as const;
        }

        const [err, data] = await aFetch<{}>("PUT", `/resourceGroup/${this.resourceGroupId}/activity/${this.activityId}`, this.toJS());
        const resourceActivities = err ? [] : [new ResourceActivity(data)];
        const resourceModules = [] as ResourceModule[];
        return [err, { resourceActivities, resourceModules }] as const;
    }

    static async updateActivities(resourceGroupId:DbIdentity, xs:ResourceActivity[]) {
        const [err, ys] = await aFetch<{}[]>("PUT", `/resourceGroup/${resourceGroupId}/activity`, xs.map(x => x.toJS()));
        return [err, (err ? [] : ys.map(y => new ResourceActivity(y)))] as const;
    }

    static async delete(resourceGroupId:DbIdentity, activityId:DbIdentity) {
        const [err, data] = await aFetch<{}>("DELETE", `/resourceGroup/${resourceGroupId}/activity/${activityId}`);
        return [err, (err ? undefined : new ResourceActivity(data))!] as const;
    }

    static async unDeleted(resourceGroupId:DbIdentity, activityId:DbIdentity[]) {
        const [err, data] = await aFetch<{}[]>("PUT", `/resourceGroup/${resourceGroupId}/activity/undeleted`, activityId);
        return [err, (err ? [] : data.map(a => new ResourceActivity(a)))] as const;
    }

    static async fetchActivityAsFaculty({resourceGroupId, activityId, signal}:{resourceGroupId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET" , `/resourceGroup/${resourceGroupId}/activity/${activityId}`, undefined, { signal });
        return [err, (err ? undefined : new ResourceActivity(data))!] as const;
    }

    static async fetchActivityDocAsFaculty({resourceGroupId, activityId, signal}:{resourceGroupId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET" , `/resourceGroup/${resourceGroupId}/activity/${activityId}/doc`, undefined, { signal });
        return [err, (err ? undefined : new ActDoc(data))!] as const;
    }

    static async fetchActivityInstructionDocAsFaculty({resourceGroupId, activityId, signal}:{resourceGroupId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET" , `/resourceGroup/${resourceGroupId}/activity/${activityId}/instructionDoc`, undefined, { signal });
        return [err, (err ? undefined : new InstructionActDoc(data))!] as const;
    }

    static async fetchDeletedActivityAsFaculty({resourceGroupId, activityId, signal}:{resourceGroupId:DbIdentity, activityId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET" , `/resourceGroup/${resourceGroupId}/activityDeleted/${activityId}`, undefined, { signal });
        return [err, (err ? undefined : new ResourceActivity(data))!] as const;
    }

    static async fetchDeletedActivitiesAsFaculty(resourceGroupId:DbIdentity) {
        const [err, data] = await aFetch<{}[]>("GET", `/resourceGroup/${resourceGroupId}/DeletedActivities`);
        return [err, err ? [] : data.map(a => new ResourceActivity(a))] as const;
    }

    static async fetchActivitiesOfFaculty({ resourceGroupId, signal }: { resourceGroupId:DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("GET" , `/resourceGroup/${resourceGroupId}/activity`, undefined, { signal });
        return [err, err ? [] : data.map(a => new ResourceActivity(a))] as const;
    }

    /**
     * Create import tracker if fileSize less than 100MB, otherwise return trackerId * -1
     * @param schoolId
     * @param resourceGroupId
     * @param fileSize
     * @returns
     */
    static async startImport({schoolId, resourceGroupId, fileSize, toQuestionbankOnly}: {schoolId:DbIdentity, resourceGroupId:DbIdentity, fileSize:number|undefined, toQuestionbankOnly ?: boolean}) {
        const [err, id] = await aFetch<DbIdentity>("POST" , `/resourceGroup/${resourceGroupId}/school/${schoolId}/activity/start-import?size=${fileSize}&toQuestionbankOnly=${toQuestionbankOnly}`);
        const trackerId = Math.abs(id);
        const isBigFile = (id < 0);
        return [err, trackerId, isBigFile] as const;
    }

    static async import({importFile, schoolId, resourceGroupId, couseType, trackerId, resourceActivity, toQuestionbankOnly, onUploadProgress}: {importFile: UploadFile|undefined, schoolId:DbIdentity,
                resourceGroupId:DbIdentity, couseType:string, trackerId:DbIdentity,
                resourceActivity : ResourceActivity | undefined,
                toQuestionbankOnly : boolean | undefined,
                onUploadProgress: (uploadedBytes: number, totalBytes: number) => void})
    {
        if (!!importFile) {
            const [err] = await uploadFile2<{}, any>("POST", `${apiHost}${versionPrefix}resourceGroup/${resourceGroupId}/school/${schoolId}/activity/import?type=${couseType}&trackerId=${trackerId}&toQuestionbankOnly=${toQuestionbankOnly}`,
                        importFile as any as File, resourceActivity?.toJS(),
                        { onUploadProgress: (pe) => {
                            const { loaded, total } = pe;
                            onUploadProgress(loaded, total);
                        }});
            if (err) return err;
        }
        return null;
    }

    static async uploadGdriveFileForExternalImport(gdriveFileId: string, schoolId:DbIdentity, resourceGroupId:DbIdentity, courseType:string, trackerId:DbIdentity, fileName: string, toQuestionbankOnly ?: boolean) {
        const params = `gdriveFileId=${gdriveFileId}&courseType=${courseType}&trackerId=${trackerId}&fileName=${encodeURI(fileName)}&toQuestionbankOnly=${toQuestionbankOnly}`
        const [err, data] = await aFetch<{}>("POST" , `/resourceGroup/${resourceGroupId}/school/${schoolId}/activity/upload-gdrive-file?${params}`);
        return [err, data] as const;
    }

    static async uploadFileForExternalImport(importFile: UploadFile|undefined, schoolId:DbIdentity, resourceGroupId:DbIdentity, couseType:string, trackerId:DbIdentity, onUploadProgress: (uploadedBytes: number, totalBytes: number) => void) {
        if (!!importFile) {
            const [err] = await uploadFileChunks<{}, any>("POST",
            `${apiHost}${versionPrefix}uploadFile/uploadChunk`,
            `${apiHost}${versionPrefix}resourceGroup/${resourceGroupId}/school/${schoolId}/activity/uploadFileForExternalImport?type=${couseType}&trackerId=${trackerId}`, importFile as any as File,
            onUploadProgress);
            if (err) return err;
        }
        return null;
    }

    static async fetchImportProgress(resourceGroupId:DbIdentity, trackerId:DbIdentity) {
        const [err, data] = await aFetch<{}>("GET" , `/resourceGroup/${resourceGroupId}/activity/import-progress/${trackerId}`)
        return [err, data] as const;
    }

    static async cancelImportProgress(resourceGroupId:DbIdentity, trackerId:DbIdentity) {
        const [err, data] = await aFetch<{}>("POST" , `/resourceGroup/${resourceGroupId}/activity/import-cancel/${trackerId}`)
        return [err, data] as const;
    }

    static sorter = {
        ...BaseActivity.sorter,
    };
}

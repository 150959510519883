import { action, computed, makeObservable, observable, toJS } from "mobx";
import { aFetch } from "../services/api/fetch";
import { CaseloadActivity } from "./CaseloadActivity";
import { CaseloadCounselor } from "./CaseloadCounselor";
import { parseCaseloadViewModel } from "./CaseloadModule";
import { Faculty } from "./Faculty";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { PageDoc } from "./PageDoc";
import { StudentExt } from "./Student";
import { DbIdentity, DefaultId, DocId, HexColorString, IStudentUserExt, IUserShortInfo } from "./types";
import { User, UserExt } from "./User";
import { BlendOptions } from "./BlendOptions";

export enum CaseloadResourceType{
    Unknown           = 0,
    Activity          = 1,
    Discussion        = 4,
    Attachment        = 5,
    Conference        = 6,
    AttachmentImage   = 10,
}

export const defaultCaseloadTabList = [
    { id: "caseloadStudents"      , isFacultyTabActive: true, isStudentTabActive: false },
    { id: "caseloadHomepage"      , isFacultyTabActive: true, isStudentTabActive: true  },
    { id: "caseloadAnnouncements" , isFacultyTabActive: true, isStudentTabActive: true  },
    { id: "caseloadModules"       , isFacultyTabActive: true, isStudentTabActive: true  },
    { id: "caseloadDiscussions"   , isFacultyTabActive: true, isStudentTabActive: true  },
    { id: "caseloadConferences"   , isFacultyTabActive: true, isStudentTabActive: true  },
];

export class Caseload {
    caseloadId    : DbIdentity          = DefaultId;
    title         : string              = ''       ;
    description   : string              = ''       ;
    createdBy     : DbIdentity          = DefaultId;
    counselors    : CaseloadCounselor[] = []       ;
    homePageDocId : DocId              = ""       ;
    caseloadCardImage?: string         = undefined; set_caseloadCardImage(v?: string) { this.caseloadCardImage = v ;}
    caseloadCardBlendOptions: BlendOptions  = new BlendOptions(); set_caseloadCardBlendOptions(v: BlendOptions) { this.caseloadCardBlendOptions = v }
    color         : HexColorString     = "#fff2cc"; set_color(v : string) { this.color = v ;}
    tabConfigs: CaseloadTabConfig[] = []; set_tabConfigs(v: CaseloadTabConfig[]) { this.tabConfigs = v }

    constructor(data?: any) {
        if (data != null) {
            const { caseloadCardBlendOptions, ...pData} = data;
            Object.assign(this, pData);
            this.caseloadCardBlendOptions = new BlendOptions(caseloadCardBlendOptions);
            if(data.tabConfigs && data.tabConfigs.length > 0){
                this.tabConfigs = data.tabConfigs.map((t: any) => new CaseloadTabConfig(t))
                .filter(x=>x.id !== "caseloadSettings"); // try to remove caseloadSettings in bad data
            }
            else{
                this.tabConfigs = defaultCaseloadTabList.map(({id, isFacultyTabActive, isStudentTabActive})=> new CaseloadTabConfig({id, isFacultyTabActive, isStudentTabActive}));
            }
        } else {
            this.caseloadCardBlendOptions = new BlendOptions();
        }

        makeObservable(this, {
            title: observable,
            description: observable,
            createdBy: observable,
            set_title: action.bound,
            set_description: action.bound,
            homePageDocId: observable,
            reactKey: computed,
            params: computed,
            mainCounselorId: computed,
            caseloadCardImage: observable,
            set_caseloadCardImage: action.bound,
            color    : observable,
            set_color: action.bound,
            caseloadCardBlendOptions: observable,
            set_caseloadCardBlendOptions: action.bound,
            tabConfigs: observable.shallow,
            set_tabConfigs: action.bound
        });
    }

    get reactKey() { return JSON.stringify({ caseloadId:(this.caseloadId) }) }
    get params() { return ({ caseloadId:String(this.caseloadId) }) }
    get mainCounselorId() { return this.counselors?.filter(faculty => faculty.isOwner)[0]?.facultyId ?? DefaultId; }

    set_title(v: string) { this.title = v };
    set_description(v: string) { this.description = v };
    toJS() { return toJS(this); }
    clone() {
        return new Caseload(this.toJS());
    }

    checkOwner(userId: DbIdentity) { return this.counselors?.some(x => x.isOwner && x.facultyId === userId)};

    static isCreateNew(caseloadId: DbIdentity){
        return caseloadId < 0;
    }

    static async save(caseload: Caseload, facultyId: DbIdentity) {
        if (caseload.caseloadId < 1) {
            const [err, data] = await aFetch<{}>("POST", `/faculty/${facultyId}/caseload`, caseload.toJS());
            return [err, (err ? undefined : new Caseload(data))!] as const;
        }

        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/caseload/${caseload.caseloadId}`, caseload.toJS());
        return [err, (err ? undefined : new Caseload(data))!] as const;
    }
    static async deleteCaseload(caseloadId: DbIdentity, facultyId: DbIdentity){
        const [err, _] = await aFetch<Caseload>("DELETE", `faculty/${facultyId}/caseload/${caseloadId}`, undefined);
        return [err, undefined] as const;
    }
    static async fetchCaseloadById(caseloadId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<Caseload>("GET", `faculty/${facultyId}/caseload/${caseloadId}`, undefined, { signal });
        return [err, (err ? undefined : new Caseload(data))!] as const;
    }
    static async fetchCaseloadHomePage({ caseloadId, facultyId, signal }: { caseloadId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal }){
        const [err, x] = await aFetch<{page: {}, pDoc: {}}>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/getHomPageDoc`, undefined, {signal})
        const page = ((err ?? !x?.page) ? undefined : new CaseloadActivity(x.page))!;
        const pageDoc = ((err ?? !x?.pDoc) ? undefined : new PageDoc(x.pDoc))!;
        return [err, {page, pageDoc}] as const;
    }

    static async fetchCaseloadPage({ facultyId, caseloadId, pageId, signal }: { facultyId: DbIdentity, caseloadId: DbIdentity, pageId: DbIdentity, signal?: AbortSignal }){
        const [err, x] = await aFetch<{}>("GET", `/faculty/${facultyId}/caseload/${caseloadId}/page/${pageId}`, undefined, {signal})
        return [err, (err ? undefined : new PageDoc(x))!] as const;
    }

    static async fetchCaseloadPageAsStudent({ studentId, caseloadId, pageId, signal }: { studentId: DbIdentity, caseloadId: DbIdentity, pageId: DbIdentity, signal?: AbortSignal }){
        const [err, x] = await aFetch<{}>("GET", `/student/${studentId}/caseload/${caseloadId}/page/${pageId}`, undefined, {signal})
        return [err, (err ? undefined : new PageDoc(x))!] as const;
    }

    static async createPage({caseloadId, doc, facultyId, extraData} :{caseloadId: DbIdentity, doc: PageDoc, facultyId: DbIdentity, extraData?: any}) {
        const [err, x] = await aFetch<{activities: {}[], modules: {}[], pageDoc: {}}>("POST", `/faculty/${facultyId}/caseload/${caseloadId}/createPage`, {...doc.toJS(), ...extraData});
        const data = (err ? undefined : parseCaseloadViewModel(x))!;
        const pDoc = (err ? undefined : new PageDoc(x.pageDoc))!;
        return [err, {activities: data.activities, modules: data.modules, pDoc}] as const;
    }

    static async saveCaseloadPageDoc({caseloadId, doc, facultyId, pageId} :{caseloadId: DbIdentity, doc: PageDoc, facultyId: DbIdentity, pageId: DbIdentity}) {
        const [err, x] = await aFetch<{activities: {}[], pageDoc: {}}>("PUT", `/faculty/${facultyId}/caseload/${caseloadId}/page/${pageId}/updateCaseloadPage`, doc.toJS());
        const page = (err ? undefined : x.activities.map(p => new CaseloadActivity(p)))!;
        const pageDoc = (err ? undefined : new PageDoc(x.pageDoc))!;
        return [err, {page, pageDoc}] as const;
    }

    static async setHomepage({caseloadId, facultyId, pageId, pageDocId} :{caseloadId: DbIdentity, facultyId: DbIdentity, pageId: DbIdentity, pageDocId: DocId}) {
        const [err] = await aFetch<{}>("PUT", `/faculty/${facultyId}/caseload/${caseloadId}/setHomepage`, {pageId, pageDocId});
        return [err] as const;
    }

    static async fetchCaseloadList(facultyId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<{caseloads: {}[], counselors: {}[]}>("GET", `faculty/${facultyId}/caseloadList`, undefined, { signal });
        const caseloadList = (err || !data.caseloads) ? [] : data.caseloads.map(x => new Caseload(x));
        const counselorList = (err || !data.counselors) ? [] : data.counselors.map(x => new User(x));
        return [err, caseloadList, counselorList] as const;
    }

    static async fetchCaseloadFaculties({facultyId, caseloadId}:{facultyId:DbIdentity, caseloadId:DbIdentity}) {
        const [err, data] = await aFetch<GeneralDto>("GET", `faculty/${facultyId}/caseload/${caseloadId}/faculties`);
        return [err, (err ? undefined : parseGeneralViewModel(data))!] as const;
    }

    static async fetchCaseloadConferenceFaculties({facultyId, caseloadId, conferenceId}:{facultyId:DbIdentity, caseloadId:DbIdentity, conferenceId: DbIdentity}) {
        const [err, xs] = await aFetch<{}[]>("GET", `faculty/${facultyId}/caseload/${caseloadId}/conference/${conferenceId}/faculties`);
        return [err, (err ? undefined : xs.map(x => new Faculty(x)) ?? [])!] as const;
    }

    static async fetchCaseloadListAsStudent(studentId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<{caseloads: ICaseloadShortInfo[], counselors: {}[]}>("GET", `student/${studentId}/caseloads`, undefined, { signal });
        const counselors = (err || !data.counselors) ? [] : data.counselors.map(x => new User(x));
        return [err, data.caseloads, counselors] as const;
    }

    static async fetchCaseloadByIdAsStudent(caseloadId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal) {
        const [err, data] = await aFetch<Caseload>("GET", `student/${studentId}/caseload/${caseloadId}`, undefined, { signal });
        return [err, (err ? undefined : new Caseload(data))!] as const;
    }

    static async fetchCaseloadHomePageAsStudent({ caseloadId, studentId, signal }: { caseloadId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal }){
        const [err, x] = await aFetch<{}>("GET", `/student/${studentId}/caseload/${caseloadId}/getHomePageDoc`, undefined, {signal})
        return [err, (err ? undefined : new PageDoc(x))!] as const;
    }
    static sorter = {
        title: <T extends Caseload>(a:T, b:T) => (a.title.localeCompare(b.title))
    }
    static async fetchStudentCaseloadStatAsFaculty({ currentFacultyId, studentId, pageIndex, pageSize, sortColumnKey, sortOrder, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, pageIndex:number, pageSize:number, searchText?:string, sortColumnKey: string|undefined, sortOrder?: string|undefined, signal?: AbortSignal }){
        const [err, {results, total}] = await aFetch<{results: ICaseloadStat[], total: number}>("GET", `/faculty/${currentFacultyId}/student/${studentId}/caseloads`, {
            pageIndex           : pageIndex,
            pageSize            : pageSize,
            sortColumnKey  : sortColumnKey || "title",
            sortOrder      : sortOrder     || "ascend",
        }, { signal });
        return [err, (err ? [] : results), total ?? 0 ] as const;
    }
    //facultyAdmin
    static async fetchStudentCaseloadStatAsFacultyAdmin({ currentFacultyId, studentId, pageIndex, pageSize, sortColumnKey, sortOrder, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, pageIndex:number, pageSize:number, searchText?:string, sortColumnKey: string|undefined, sortOrder?: string|undefined, signal?: AbortSignal }){
        const [err, {results, total}] = await aFetch<{results: ICaseloadStat[], total: number}>("GET", `/faculty/${currentFacultyId}/admin/student/${studentId}/caseloads`, {
            pageIndex           : pageIndex,
            pageSize            : pageSize,
            sortColumnKey  : sortColumnKey || "title",
            sortOrder      : sortOrder     || "ascend",
        }, { signal });
        return [err, (err ? [] : results), total ?? 0 ] as const;
    }
    static async searchCaseloadStatAsFacultyAdmin({ currentFacultyId, studentId, pageIndex, pageSize, searchText, sortColumnKey, sortOrder, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, pageIndex:number, pageSize:number, searchText?:string, sortColumnKey: string|undefined, sortOrder?: string|undefined, signal?: AbortSignal }){
        const [err, {results, total}] = await aFetch<{results: ICaseloadStat[], total: number}>("GET", `/faculty/${currentFacultyId}/admin/student/${studentId}/searchCaseloads`, {
            pageIndex           : pageIndex,
            pageSize            : pageSize,
            searchText,
            sortColumnKey  : sortColumnKey || "title",
            sortOrder      : sortOrder     || "ascend",
        }, { signal });
        return [err, (err ? [] : results), total ?? 0 ] as const;
    }

    static async addStudentToCaseloadsAsFacultyAdmin({currentFacultyId, studentId, caseloadIds}: {currentFacultyId: DbIdentity, studentId: DbIdentity, caseloadIds: DbIdentity[]}){
        const [err, _] = await aFetch("PUT", `/faculty/${currentFacultyId}/admin/student/${studentId}/addCaseloads`, caseloadIds);
        return err;
    }
    static async removeStudentFromCaseloadAsFacultyAdmin({ currentFacultyId, studentId, caseloadId, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, caseloadId: DbIdentity, signal?: AbortSignal }){
        const [err] = await aFetch("DELETE", `/faculty/${currentFacultyId}/admin/student/${studentId}/caseload/${caseloadId}/remove`, undefined, { signal });
        return err;
    }
    static async fetchFacultyCaseloadStatAsFacultyAdmin({ currentFacultyId, facultyId, signal }: { currentFacultyId: DbIdentity, facultyId: DbIdentity, signal?: AbortSignal }){
        return await aFetch<ICaseloadStat[]>("GET", `/faculty/${currentFacultyId}/admin/faculty/${facultyId}/caseloads`, undefined, { signal });
    }
    static async fetchFacultyCaseloadStudentsAsFacultyAdmin({ currentFacultyId, facultyId, caseloadId, pageIndex, pageSize, sortColumnKey, sortOrder, signal }: { currentFacultyId: DbIdentity, facultyId: DbIdentity, caseloadId: DbIdentity, pageIndex:number, pageSize:number, sortColumnKey: string|undefined, sortOrder?: string|undefined, signal?: AbortSignal }){
        const [err, {results, total}] = await aFetch<{results: IStudentUserExt[], total: number}>("GET", `/faculty/${currentFacultyId}/admin/faculty/${facultyId}/caseload/${caseloadId}/students`, {
            pageIndex           : pageIndex,
            pageSize            : pageSize,
            sortColumnKey  : sortColumnKey || "fullName",
            sortOrder      : sortOrder     || "ascend",
        }, { signal });
        return [err, (err ? [] : results.map(r => ({userInfo: new UserExt(r.userInfo), studentInfo: new StudentExt(r.studentInfo)} as IStudentUserExt))), total ?? 0 ] as const;
    }

    static async addStudentToCaseloadAsFacultyAdmin({currentFacultyId, caseloadId, studentIds}: {currentFacultyId: DbIdentity, caseloadId: DbIdentity, studentIds: DbIdentity[]}){
        const [err, _] = await aFetch("PUT", `/faculty/${currentFacultyId}/admin/caseload/${caseloadId}/addStudents`, studentIds);
        return err;
    }

    static async setCaseloadCard({ facultyId, caseloadId, color, caseloadCardBlendOptions, caseloadCardImage } : { facultyId: DbIdentity, caseloadId: DbIdentity, color: string, caseloadCardBlendOptions?: BlendOptions, caseloadCardImage?: string}){
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${facultyId}/caseload/${caseloadId}/setCaseloadCard`, {
            color,
            cardImage: caseloadCardImage,
            cardBlendOptions: caseloadCardBlendOptions?.toJS()
        });
        return [err, (err ? undefined : new Caseload(data))!] as const;
    }

    //end facultyAdmin
}

export interface ICaseloadShortInfo {
    caseloadId: number,
    title: string,
    caseloadCardImage?: string,
    color: string,
    caseloadCardBlendOptions?: BlendOptions,
    createdBy: number
}
export interface ICaseloadStat{
    caseload          : ICaseloadShortInfo,
    owner             : IUserShortInfo,
    studentCount      : number
}

export interface ICounselor{
    avatar: string;
    fullName: string
}
export class CaseloadTabConfig {
    state               : CaseloadTabActiveState = CaseloadTabActiveState.Off;
    isFacultyTabActive  : boolean = false;
    isStudentTabActive  : boolean = false;
    id                  : string              = "";
    set_state(v:CaseloadTabActiveState) { this.state = v }

    constructor(data?:{}) {
        makeObservable(this, {
            state                   : observable, set_state : action.bound,
            calculateState          : computed,
            isFacultyTabActive      : observable,
            isStudentTabActive      : observable,
            onChangeFacultyBtn      : action.bound,
            onChangeStudentBtn      : action.bound,
        });

        if (data != null) Object.assign(this, data);
    }

    onChangeFacultyBtn(v: boolean) {
        this.isFacultyTabActive = v;
        this.set_state(this.calculateState);
    }

    onChangeStudentBtn(v: boolean) {
        this.isStudentTabActive = v;
        this.set_state(this.calculateState);
    }

    get calculateState() {
        return parseInt(`${(+this.isStudentTabActive).toString()}${(+this.isFacultyTabActive).toString()}`, 2);
    }

    toJS() {
        return toJS(this);
    }
}

export enum CaseloadTabActiveState {
    Off                 = 0,
    Faculty             = 1,
    Student             = 2,
    FacultyAndStudent   = CaseloadTabActiveState.Faculty | CaseloadTabActiveState.Student,
    On                  = CaseloadTabActiveState.Faculty | CaseloadTabActiveState.Student
}

///#region Caseload Student Route Names
/** [key: main route name]: [value: sub route names in array] */
export const allStudentCaseloadRouteNames = {
    caseloadHomepage        : ['caseloadHomepage'],
    caseloadModules         : ['caseloadModules', 'caseloadPage'],
    caseloadAnnouncements   : ['caseloadAnnouncements'],
    caseloadDiscussions     : ['caseloadDiscussions', 'caseloadDiscussionDetail'],
    caseloadConferences     : ['caseloadConferences', 'caseloadConferenceStart', 'caseloadConferenceJoin'],
} as const;

export const defaultStudentTabs = Object.keys(allStudentCaseloadRouteNames);

///#endregion
import { observable, action, computed, toJS, makeObservable } from "mobx";

import {DbIdentity, DefaultId, NumberDate} from "./types";

import { aFetch } from "../services/api/fetch";
import { ResourceDiscussion } from "./ResourceDiscussion";
import { ResourceActivity } from "./ResourceActivity";
import { GeneralDto, parseGeneralViewModel } from "./GeneralViewModel";
import { ResourceFolder } from "./ResourceFolder";
import { ResourceModule } from "./ResourceModule";

export enum ResourceType {
    Module     = 0,
    Page       = 1,
    Assignment = 2,
    Assessment = 3,
    Discussion = 4,
    QuestionBank = 5,
    // Upload      = 6,
    Attachment = 7,
    CommentBank = 8,
}

interface IImportParams {
    classId   : DbIdentity,
    moduleIds : string[],
}
interface IDeleteParams {
    pages        ?: DbIdentity[],
    activities   ?: DbIdentity[],
    discussions  ?: DbIdentity[],
    modules      ?: string[],
}
interface IExportParams extends IDeleteParams{
    targetClasses : DbIdentity[],
}

export class ResourceGroup {
    resourceGroupId  : DbIdentity = DefaultId;
    name             : string = "";
    dateCreated     ?: NumberDate = undefined;
    dateUpdated     ?: NumberDate = undefined;
    isDeleted        : boolean    = false;

    isOwner          : boolean    = false;
    isSharedOwner    : boolean    = false;
    isSharedEditor   : boolean    = false;
    isSharedViewer   : boolean    = false;
    isAllowedEdit    : boolean    = false;

    includeInSelection : boolean  = true;

    constructor(data?:any) {
        makeObservable(this, {
            name               : observable,
            dateCreated        : observable,
            dateUpdated        : observable,
            isDeleted          : observable,
            isOwner            : observable,
            isSharedOwner      : observable,
            isSharedEditor     : observable,
            isSharedViewer     : observable,
            isAllowedEdit      : observable,
            includeInSelection : observable,
            set_name           : action     ,
            params             : computed
        });

        if (data != null) {
            Object.assign(this, data);
        }
    }

    toJS() {
        return toJS(this);
    }
    clone() { return new ResourceGroup(this.toJS()) }

    set_name = (v : string           ) => { this.name            = v };

    get params() { return ({ resourceGroupId : String(this.resourceGroupId) }) }

    async save() {
        const data = this.toJS();
        if (this.resourceGroupId < 1) {
            const [err, d] = await aFetch<{}>("POST", `/resourceGroup`, data);
            return [err, (err ? undefined : new ResourceGroup(d))!] as const;
        }

        const [err, d] = await aFetch<{}>("PUT", `/resourceGroup/${this.resourceGroupId}`, data);
        return [err, (err ? undefined : new ResourceGroup(d))!] as const;
    }

    static async fetchResourceGroupById({ resourceGroupId, signal }: { resourceGroupId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `/resourceGroup/${resourceGroupId}`, undefined, { signal });
        return [err, (err ? undefined : new ResourceGroup(data))!] as const;
    }

    static async fetchResourceGroupOfUser(ps: { signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}[]>("GET", `/resourceGroup`, undefined, ps);
        return [err, (err ? undefined : data.map(rs => new ResourceGroup(rs)))!] as const;
    }

    static async deleteResource({ resourceGroupId }: { resourceGroupId: DbIdentity }) {
        const [err, d] = await aFetch<ResourceGroup>("DELETE", `/resourceGroup/${resourceGroupId}`);
        return [err, (err ? undefined : new ResourceGroup(d))!] as const;
    }

    static async fetchDeletedResourceGroupOfUser() {
        const [err, data] = await aFetch<{}[]>("GET", `/resourceGroup/deleted`);
        return [err, (err ? [] : data.map(rs => new ResourceGroup(rs)))] as const;
    }

    static async undeleteResource({resourceGroupIds}:{resourceGroupIds: DbIdentity[]}) {
        const [err, data] = await aFetch<{}[]>("POST", `/resourceGroup/undelete`, resourceGroupIds);
        return [err, (err ? [] : data.map(rs => new ResourceGroup(rs)))] as const;
    }

    static async import(params: {resourceGroupId: DbIdentity, folderId?: DbIdentity, body: IImportParams }) {
        const [err, d] = await aFetch<{modules: any[],folders: any[], discussions: any[], activities: any[]}>("POST", `/resourceGroup/${params.resourceGroupId}/import`, params.body);
        const data = (err ? undefined : {
            modules: d.modules.map(m => new ResourceModule(m)),
            folders: d.folders.map(f => new ResourceFolder(f)),
            discussions: d.discussions.map(d => new ResourceDiscussion(d)),
            activities: d.activities.map(a => new ResourceActivity(a)),
        });

        return [err, data] as const;
    }

    static export(params: {resourceGroupId: DbIdentity, body: IExportParams}) {
        return aFetch<{ jobId?:string }>("POST", `/resourceGroup/${params.resourceGroupId}/export`, params.body);
    }

    static delete(params: {resourceGroupId: DbIdentity, body: IDeleteParams}) {
        return aFetch<{ jobId?:string }>("POST", `/resourceGroup/${params.resourceGroupId}/delete`, params.body);
    }

    static async importToModule(facultyId: DbIdentity,classId: DbIdentity, fromResourceGroupId: DbIdentity, toModuleId: string, resourceModuleIds: string[]) {
        const [err, data] = await aFetch<GeneralDto>("POST", `/faculty/${facultyId}/class/${classId}/resourceGroup/${fromResourceGroupId}/module/${toModuleId}/importFromResource`, resourceModuleIds);
        return [err, !!data ? parseGeneralViewModel(data) : undefined] as const;
    }

    static async importToClass(facultyId: DbIdentity,toclassId: DbIdentity, fromResourceGroupId: DbIdentity, resourceModuleIds: string[]) {
        const [err, data] = await aFetch<GeneralDto>("POST", `/faculty/${facultyId}/class/${toclassId}/resourceGroup/${fromResourceGroupId}/importFromResource`, resourceModuleIds);
        return [err, !!data ? parseGeneralViewModel(data) : undefined] as const;
    }

    static sorter = {
        name        : (a:ResourceGroup, b:ResourceGroup) => (a.name!.localeCompare(b.name!)),
        dateCreated : (a:ResourceGroup, b:ResourceGroup) => (a.dateCreated || -1) - (b.dateCreated || -1),
        dateUpdated : (a:ResourceGroup, b:ResourceGroup) => (a.dateUpdated || -1) - (b.dateUpdated || -1),
    }
}

export const JwtTokenKey = "JwtToken";
export const RememberMeKey = "RememberMe";
export const LocalCacheKey = "LocalCache";
export const AutosaveDuration = 5*60*1000;
export const CommitteeAssessSchoolIdKey = "CommitteeAssess_SelectedSchoolId";
export const CommunityHubSchoolIdKey = "CommunityHub_SelectedSchoolId";
export const SignInRedirectKey = "SignInRedirectKey";

export const MediaBreakpoint = {
    // https://getbootstrap.com/docs/4.0/layout/overview/#responsive-breakpoints
    xs: 0,
    sm: 576,
    md: 769, // set this +1px to works on iPad vertical: <= 768px
    lg: 992,
    xl: 1200,

    // https://tailwindcss.com/docs/responsive-design/
    // sm: 640,
    // md: 768,
    // lg: 1024,
    // xl: 1280,
};

export const siteName = "eKadence";
export const supportEmail = "support@ekadence.com";
export const SetSeenDuration = 0.5*1000;
// https://gist.github.com/dperini/729294
export const RegExpPatternUrl = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z0-9\u00a1-\uffff][a-z0-9\u00a1-\uffff_-]{0,62})?[a-z0-9\u00a1-\uffff]\.)+(?:[a-z\u00a1-\uffff]{2,}\.?))(?::\d{2,5})?(?:[/?#]\S*)?$/;
export const FaviconDomainUrl = "https://s2.googleusercontent.com/s2/favicons?domain=";
export const RegExpPatternEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const ActivityTreeDndType = "ActivityTree";
export const QuestionListDndType = "QuestionList";

/** only use to show */
export const UploadSizeLimitInMB = 750;
export const UploadSizeLimit = UploadSizeLimitInMB * 1024 * 1024;
export const UploadSizeToBeChunked = 50 * 1024 * 1024;

export const IconSizeLimit = 1024 * 1024;

export const defaultScoreInput = {
    minNagetiveValue : -32768,
    minValue : 0,
    maxValue : 10000,
    defaultStep: 0.01
}

export const defaultTextAreaAutoSize = {
    minRows: 4,
    // maxRows: 8, //unlimited
};

export const CacheDuration = 5*60*1000;

export const PublicConferencePath   = `${window.location.origin}/public/conference`;
export const PublicEPortfolioPath   = `${window.location.origin}/public/eportfolio`;
export const PublicDiscussionPath   = `${window.location.origin}/shared/discussion`;
export const PublicClassPath        = `${window.location.origin}/public/class`     ;
export const PublicSurveyStreamPath = `${window.location.origin}/public/surveys/`;
export const PublicSurveyResultsPath = `${window.location.origin}/public/surveys/results/`;

export const Media_Image_ThumbnailPostfix = "_thumbnail";

export const IdleDuration = 1000 * 60 * 15;
export const PollingConferenceInterval = 0;

export const CollapseAnimationDuration = 300;
export const ModuleItemHeight = "80px";

export const MaxTimesToRetakeExam = 100;

// TODO: just temporary hide, remove this after eportfolio is completed
export const isEnableEportfolio = true;
export const VideoCallWsUrl = process.env.REACT_APP_VIDEOCALL_WS_URL;
/** keep this for old data */
export const suneditorCustom = {
    se_use_googleViewer: "se_use_googleViewer",
    fill_in_blank: "__se_fillInBlank"
}

export const editorCustomData = {
    se_use_googleViewer: "googleViewer",
    fillInBlank: "editor_fillInBlank"
}
export const ChatBadgeOverflowCount = 99;

export const IsEnableServiceWorker = false;
export const IsEnableCacheComment = true;
export const IsEnableCacheStudentDoc = true;

export const ServiceWorkerApiCacheName = "eKadenceApi";
export const StudentDocCachePrefix = 'StudentDoc_';
export const StudentCommentCachePrefix = 'StudentComment_';
export const BuildCacheName_StudentDoc = (studentId: number, activityId: number) => `${StudentDocCachePrefix}${studentId}_${activityId}`;
export const BuildCacheName_Comment    = (studentId: number, threadId: string)   => `${StudentCommentCachePrefix}${studentId}_${threadId}`;

export const IsUseNewModule: boolean = true;
export const IsEnabledCaseloadModule: boolean = true;
export const IsEnableHighlightTextQuestion: boolean = true;
export const IsEnableResourceSharing: boolean = true;
export const IsUseMultipleMatching: boolean = true;
export const BuildThrivelyLinkUrl = (email: string) => `https://thrively.com/ext/ekadence/profile/${email}`;
export const IsEnableInvitedOtherClasses: boolean = true;
export const IsUseClassInvitation: boolean = true;
export const IsUseStudentProgram: boolean = true;
export const IsUseGoogleSignInInline: boolean = true;
export const IsEnableAddNewEndeavour: boolean = true;

export const Gradebook_Sync_ActivityScore_Name = 'Gradebook_Sync_ActivityScore_Name';
export const Gradebook_Sync_SbgActivityScores_Name = 'Gradebook_Sync_SbgActivityScores_Name';
export const Gradebook_Sync_ActivityScores_Name = 'Gradebook_Sync_ActivityScores_Name';
export const Gradebook_Sync_5C_Storage_Name = 'Gradebook_Sync_5C_Storage_Name';
export const Gradebook_Sync_Comment_Storage_Name = 'Gradebook_Sync_Comment_Storage_Name';
export const EPortfolio_Sync_PrimaryId_Name = 'EPortfolio_Sync_PrimaryId_Name';
export const isEnableAutomaticClassEPortfolios = true;
export const isEnableConvertPageTo: boolean = true;
export const isEnableClassLayout = true;
export const isEnableMakeTeacherOnRecord = true;
export const isEnableNewCoteacherUI = true;
export const isEnableAnnouncementPollSurvey = true;
export const isEnableClassCalendar = true;
export const isEnableV2UserManagementInFacultyAdmin = true;
export const isEnableStudentClassCardInFacultyPortal = true;
export const isEnableBadging = true;
export const allowTestCrosslistedClass = '5,10426,31564';
export const isEnableShoulderTaps = true;
export const enableEportfolioBackgroundSetting = true;
export const isEnableNumericSliderQuestion = true;
export const isEnableImportContentFromGoogleFormsButton = true;
export const selfPacedLearningMaxDaysAssigned   = 255; //byte
export const isEnableBannerInEportfolio = true;
export const isEnableToChangeSharedDrives = true;

export const EditorPickerModalZIndex = 1205; // > 1202 of modal zindex
export const ContactSupportModalZIndex = 1050;
export const isEnableMessageCenter = true;
export const isEnableAIGenerateQuestion = true;
export const isEnableAIImportQuestion = true;
export const isEnableGradeBookFlyOver = true;
export const isEnableInterclass = true;
export const isEnableTinyMCEAI = true;
export const isEnableLockModule = true;
export const isEnableDiscussionModeration = true;
export const isEnableDiscussionRegularName = true;
export const isEnableMultipleGradebooks = true;
export const careerCompassUrl = 'https://site.pragyasystems.com/enrollment';
export const isEnableVideoActivity = true;
export const isEnableAdditionalScore = true;
export const isEnableInstructorEvaluated = true;
export const google_enable_redirect_uri_validation = false;

export const intervalToRefreshUnReactGroupAnnouncementCounter = 3 * 60000; // in minutes
export const SKOLAPortalSettings = {
    student : { isFrameIn : true, src : "https://learn.skola.tech"  , },
    faculty : { isFrameIn : true , src : "https://educate.skola.tech", }
};

export const LearnerGradeLevels = [1,2,3,4,5,6,7,8,9,10,11,12];
/**NOTE
 * This is to support
 * -2: Pre-School
 * -1: Pre-Kindergarten
 *  0: Kindergarten
 *  1: 1st
 * ...
 * 16: 16th
 */
export const FullGradeLevels: number[] = [-2, -1, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10 , 11 , 12, 13, 14, 15, 16];
export const isEnableParentSelectionInMessageCenterSearchModal = true;
export const surveyStreamPollingTimeout = 800; //To disable polling, set = 0
export const surveyStreamAnalysisPollingTimeout = 2000; //To disable polling, set = 0
export const surveyStatisticPollingTimeout = 1000; //To disable polling, set = 0
export const uploadSurveyResponsePollingTimeout = 700;

export const SurveyStreamRoles = [
    {id: 1, label: "Faculty", color: "#ff7f27"},
    {id: 2, label: "Parent", color: "#4299e1"},
    {id: 3, label: "Student", color: "#22b14c"},
    {id: 4, label: "Other", color: "#a349a4"}
];
export const DefaultIEPCommand = `$"""
The following is IEP report of one student:

BEGIN IEP REPORT
{context}
END IEP REPORT

Based on the report, please carefully extract all the following information:
[Program accommodations], [Program modifications], [Other Supports for School Personnel or for Student, or on Behalf of Student].
"""`
export const DefaultCommandActivityImport = `$"""
            return the multiple choice questions below in xml format like
            <questions>
                <question>
                    <text></text>
                    <choices>
                        <choice isCorrect="1"></choice>
                    </choices>
                    <correctAnswer><choice isCorrect="1"></choice></correctAnswer>
                </question>
            </questions>
            """`
export const DefaultMasterQuestionGenerationCommand = `For my {ClassName} class, I am building an assignment for my {GradeLevel} students called {ActivityTitle}. I want to generate some question about {Topic}.
Here is the content I am teaching my students:
{LessonPlan}
{Instructions}
{WorkQuestions}`;
export const DefaultLimitToContentOnPageCommand = `Use only the information above to generate questions.`;
export const DefaultGoogleAIAdditionalToggles = `{"temperature": 0.6, "top_k": 40, "top_p": 0.95, "candidate_count": 1, "max_output_tokens": 1024, "stop_sequences": [], "safety_settings": [{"category":"HARM_CATEGORY_DEROGATORY","threshold":1},{"category":"HARM_CATEGORY_TOXICITY","threshold":1},{"category":"HARM_CATEGORY_VIOLENCE","threshold":1},{"category":"HARM_CATEGORY_SEXUAL","threshold":1},{"category":"HARM_CATEGORY_MEDICAL","threshold":1},{"category":"HARM_CATEGORY_DANGEROUS","threshold":1}]}`;
export const DefaultMatchingQuestionsCommand = `Give me {NumMatchingQuestions} matching questions. Give it to me in this format (note: each question should have at least 4 pair node) <questions><question index="indexOfQuestion"><text></text><pairs><pair><key></key><value></value></pair></pairs></question></questions> Response in XML format only`;
export const DefaultMultipleChoiceQuestionsCommand = `Give me {NumMultipleChoiceQuestions} multiple choice questions with answer key and explanations for the answer inline. Give it to me in this format (note: each question should have at least 4 choice node) <questions><question index="indexOfQuestion"><text /><choices><choice isCorrect=\"true or false\" /></choices><correctAnswer /><explanation /></question></questions> Response in XML format only`;
export const DefaultEssayQuestionsCommand = `Give me {NumEssayQuestions} essay questions. Give it to me in this format <questions><question index="indexOfQuestion"/></questions> Response in XML format only`;
export const DefaultFillInTheBlankQuestionsCommand = `Give me {NumFillInTheBlankQuestions} fill-in-the-blank questions. Fill-in position should be represented as [___], where a set of three underscores within square brackets indicate a blank space where something should be filled in. Give it to me in this format  <questions><question-with-blank index="indexOfQuestion"><text/><answer/></question-with-blank></questions> Response in XML format only`;
export const MagicLinkExpirationTime = 10;
export const WebSocketHost = (process.env.NODE_ENV === "development") ? process.env.REACT_APP_TEST_WEBSOCKET_HOST : undefined
export const DeepLinkingHost = 'ekadence';
export const DeepLinkingScheme = 'mobile';
export const isLocalhost = window.location.hostname === "localhost";
export const applyAbsentForGrading = false;
export const PowerSearchMinimumKeywordLength = 2;// limit minimum characters required for perform power search
export const regexDetectMobileBrowser = /Mobi|Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i;
/** The temporary limit that "Expand All" action in module should not be called to save performance */
export const collapsedModuleLimit = 500;

export const featureFlags = {
    externalUpload: true,
    externalVideoUpload: true,
    studentEcard: true,
    activityEditor: {
        awards: (process.env.REACT_APP_CREDIT_AND_AWARDS === 'true')
    },
    sisSyncAdmin: true,
}
import { DbIdentity, EUserRole } from "./models/types";
import { Conference } from "./models/Conference";

import { StrictMode, type ComponentType } from "react";
import { render } from "react-dom";
import { configure } from "mobx";

import reportWebVitals from './reportWebVitals';
import "./i18n";

import { getJwtToken, clearJwtToken } from "./LoginPortal/stores/loginUtil";
import { aFetch, setAuthtoken } from "./services/api/fetch";

import { KeyValueStore } from "./utils/KeyValueStore";
import { historyReplace } from "./utils/historyBack";

import "./css/index.css";
import "./css/index-light.less";
import { ApplicationRoleValue } from "./models/ApplicationRoleValue";
import { ActivityType } from "./models/ActivityType";
import { trimEnd } from "lodash-es";

import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { IsEnableServiceWorker } from "./config";
import { generateUrl, matchUrl } from "mobx-state-router";
import { StringMap } from "i18next";
import { ConfigProvider, App as AntApp } from "antd";
import { themeConfig } from "./components/Ant/themeConfig";

if (process.env.NODE_ENV == "development") configure({
    enforceActions:"observed",
}); else configure({ enforceActions: "never" });

const rootEle = document.getElementById("root");

async function goToLogin() {
    const {App} = await import("./LoginPortal/App");
    render(<ConfigProvider theme={themeConfig}>
            <AntApp>
                <App />
            </AntApp>
        </ConfigProvider>
    , rootEle);
}

const reservedSeparatedPortalPaths = ["/login/faculty", "/login/student", "/login/parent", "/login/admin"];
const reservedLoginPortalPaths = ["/login", "forgot-password", "/reset-password", "/set-password", "/register", "/unable-confirm-account", '/conferences/end', '/email-confirmed', '/welcome-magic-link', ...reservedSeparatedPortalPaths];
async function bootstrap() {
    if (reservedLoginPortalPaths.includes(window.location.pathname)
        || window.location.pathname.startsWith("/public"))
        return goToLogin();

    const jwtToken = getJwtToken();
    if (!jwtToken) return goToLogin();

    setAuthtoken(jwtToken);
    const [err, data] = await aFetch<{userId: DbIdentity, roles: string[]}>("GET", `/Account/me/roles`);
    if (err) {
        setAuthtoken("");
        clearJwtToken();
        return goToLogin();
    }

    if (location.pathname == '/zoom/oauth') {
        const url = new URL(location.href);
        const code = url.searchParams.get('code');
        if (code) {
            const err = await Conference.updateZoomAuthorizationCode(code);
            if (!err[0]) {
                const state = url.searchParams.get('state');
                if (state) location.replace(state);
                else location.replace('/');
            } else alert('Error: can not update zoom authorization code');
        }
    } else if (/^\/group\/\d+\/conferences\/\d+\/conferencelogs\/\d+/.test(location.pathname)) {
        // For group pattern, mostly from email. Convert it to proper portal.
        const portalPrefix = (data.roles.includes('Faculty')) ? '/faculty-portal'
            : (data.roles.includes('Student')) ? '/student-portal'
            : '';
        if (portalPrefix) {
            historyReplace(portalPrefix + location.pathname);
        }
    }

    //auto redirect to right portal in case student click the activity url that faculty send to them
    if (window.location.pathname.includes("faculty-portal/activities/")) {
        if(!data.roles.includes('Faculty') && data.roles.includes('Student')) {
            const studentPortalUrl = location.pathname
                .replace('faculty-portal', 'student-portal')
                .replace('/instruction','/doc')
                .replace('/editor','/doc')
                .replace('/settings','/doc')
                .replace('/view','/doc');
            historyReplace(studentPortalUrl);
        }
    }

    // auto redirect to right portal in case faculty click the eportfolio url that student send to them
    if (/^\/student-portal\/ePortfolios\//i.test(window.location.pathname) && /^\/student-portal\/ePortfolios\/(?!preview|new|deleted)/i.test(window.location.pathname)) {
        const lastRole = await getLastRole(data);
        if(data.roles.includes(EUserRole.Faculty) && lastRole == EUserRole.Faculty) {
            const routePatterns  = new Set([
                "/ePortfolios/comment/:ePortfolioId"                   ,
                "/ePortfolios/comment/:ePortfolioId/:pageEPortfolioId" ,
                "/ePortfolios/:ePortfolioId"                           ,
                "/ePortfolios/:ePortfolioId/settings"                  ,
                "/ePortfolios/:ePortfolioId/:pageEPortfolioId"         ,
                "/ePortfolios/:ePortfolioId/:pageEPortfolioId/settings",
            ]);
            let _rUrl : string | undefined = window.location.pathname.replace("/student-portal", '');
            let matchValues : StringMap | undefined = undefined;
            for (let pattern of routePatterns) {
                matchValues = matchUrl(_rUrl, pattern);
                if (matchValues) break;
            }
            if (matchValues) {
                const { ePortfolioId, pageEPortfolioId } = matchValues;
                let redirectUrl : string | undefined = undefined;
                if      (ePortfolioId && pageEPortfolioId) { _rUrl = generateUrl("/ePortfolios/preview/:ePortfolioId/:pageEPortfolioId", { ePortfolioId, pageEPortfolioId }); }
                else if (ePortfolioId                    ) { _rUrl = generateUrl("/ePortfolios/preview/:ePortfolioId", { ePortfolioId }); }
                else                                       { _rUrl = undefined; }

                if (_rUrl) {
                    _rUrl = `/faculty-portal${_rUrl}`;
                    redirectUrl = _rUrl;
                }

                if (redirectUrl) historyReplace(redirectUrl);
            }
        }
    }

    //auto redirect to right portal in case student click the discussion url that faculty send to them
    if(/^\/faculty-portal\/classes\/\d+\/discussion\/\d+/.test(window.location.pathname)){
        if(!data.roles.includes('Faculty') && data.roles.includes('Student')){
            const url = new URL(location.href);
            url.pathname = url.pathname.replace('faculty-portal', 'student-portal').replace('/editor','').replace('/settings','');
            url.searchParams.set('checkLinkedSection', 'true')
            historyReplace(url);
        }
    }

    // TES-6852
    if (/^\/activities\/\d+/.test(location.pathname)) {
        let role = ApplicationRoleValue.Unknown;
        let activityType = ActivityType.Unknown;
        const u = new URL(location.href); if (u.pathname.endsWith("/")) u.pathname = trimEnd(u.pathname, '/');

        if (data.roles.includes('Faculty') && data.roles.includes('Student')) {
            const activityId = u.pathname.split('/')[2];
            const [err, r] = await aFetch<{type: ActivityType, role:ApplicationRoleValue}>("GET", `/Account/activity/${activityId}/role`);
            if (!err) { role = r.role; activityType = r.type }
        }
        else if (data.roles.includes('Faculty')) { role = ApplicationRoleValue.Faculty; }
        else if (data.roles.includes('Student')) { role = ApplicationRoleValue.Student; }

        if (role == ApplicationRoleValue.Faculty) {
            const suff = u.pathname.split('/').length > 3 ? '' : (
                activityType == ActivityType.Activity ? '/view' :
                (activityType == ActivityType.Assessment || activityType == ActivityType.Assignment) ? '/instruction' :
                '/view');
            u.pathname = '/faculty-portal'+u.pathname+suff; historyReplace(u);
        } else if (role == ApplicationRoleValue.Student)  {
            const suff = u.pathname.split('/').length < 4 ? '/doc' : '';
            u.pathname = '/student-portal'+u.pathname+suff; historyReplace(u);
        }
    }

    let mApp = (
        (window.location.pathname.startsWith('/faculty-portal'   ) && data.roles.includes('Faculty'   )) ? getApp('Faculty'       ) : (
        (window.location.pathname.startsWith('/preview-student/' ) && data.roles.includes('Faculty'   )) ? getApp('PreviewStudent') : (
        (window.location.pathname.startsWith('/student-portal'   ) && data.roles.includes('Student'   )) ? getApp('Student'       ) : (
        (window.location.pathname.startsWith('/shared'           ) && data.roles.includes('Faculty'   )) ? getApp('Faculty'       ) : (
        (window.location.pathname.startsWith('/shared'           ) && data.roles.includes('Student'   )) ? getApp('Student'       ) : (
        (window.location.pathname.startsWith('/parent-portal'    ) && data.roles.includes('Parent'    )) ? getApp('Parent'        ) : (
        (window.location.pathname.startsWith('/admin-portal'     ) && data.roles.includes('Admin'     )) ? getApp('Admin'         ) : (
        (window.location.pathname.startsWith('/translator-portal') && data.roles.includes('Translator')) ? getApp('Translator'    ) : (
        undefined
        )))))))));
    if (mApp == null) {
        const role = await getLastRole(data);
        if (role) mApp = getApp(role);
    }
    if (mApp == null) mApp = getFirstApp(data.roles);

    if (!mApp) {
        clearJwtToken();
        alert(`No roles assigned for this account.`);
        goToLogin();
        return;
    }

    const { App } = await mApp;
    render(<StrictMode>
        <ConfigProvider theme={themeConfig}>
            <AntApp>
                <App />
            </AntApp>
        </ConfigProvider>
    </StrictMode>
        , rootEle);
}

bootstrap();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

if (IsEnableServiceWorker) serviceWorkerRegistration.register();
else serviceWorkerRegistration.unregister();

async function getLastRole({ userId, roles }: {userId: DbIdentity, roles: string[]}) {
    const sKeyValue = new KeyValueStore();
    sKeyValue.userId = userId;
    const initErr = await sKeyValue.pInit;
    if (initErr) {
        console.error(initErr);
        return '';
    }
    const role = await sKeyValue.getLastPortal();
    if (role && roles.includes(role)) return role;
    return '';
}

function getFirstApp(roles:string[]) {
    for (const r of roles) {
        const mApp = getApp(r);
        if (mApp) return mApp;
    }
    return undefined;
}

function getApp(role: string): Promise<{App:ComponentType<{}>}> | undefined {
    switch(role) {
        case "Faculty"          : return import("./FacultyPortal/App");
        case 'PreviewStudent'   : return import("./PreviewStudentPortal/App");
        case "SchoolAdmin"      :
        case "Admin"            : return import("./AdminPortal/App");
        case "Student"          : return import("./StudentPortal/App");
        case "Parent"           : return import("./ParentPortal/App");
        case "Translator"       : return import("./TranslatorPortal/App");
        default                 : return undefined;
    }
}



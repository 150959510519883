import axios from "axios";
import { uniq } from "lodash-es";
import moment from "moment";
import type { IAllowFileTypeInfo } from "../models/types";
import { audioExts, contentType2Extension, imageExts, videoExts } from "./getMediaInfo";
import { UploadFile } from "antd/lib/upload/interface";
import { nanoid } from "nanoid";

export function blob2DataUri(blob: Blob) {
    return new Promise<string>(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result as string));
        reader.readAsDataURL(blob);
    });
}
export function urlContentToDataUri(url: string) {
    return fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise(callback => {
            let reader = new FileReader();
            reader.onload = function () { callback(this.result) };
            reader.readAsDataURL(blob);
        }));
}

export function dataURItoBlob(dataUri: string) {
    var byteString = atob(dataUri.split(',')[1]);
    var mimeString = dataUri.split(',')[0].split(':')[1].split(';')[0];
    var arrayBuffer = new ArrayBuffer(byteString.length);
    var _ia = new Uint8Array(arrayBuffer);
    for (var i = 0; i < byteString.length; i++) {
        _ia[i] = byteString.charCodeAt(i);
    }
    var dataView = new DataView(arrayBuffer);
    var blob = new Blob([dataView], { type: mimeString });
    return blob;
}

export async function blob2ArrayBuffer(blob: Blob): Promise<[Error|undefined, ArrayBuffer]> {
    return blob.arrayBuffer().then(a => [undefined, a], e => [e, undefined!]);

    return new Promise<[Error|undefined, ArrayBuffer]>((resolve) => {{}
        const reader = new FileReader();
        const controller = new AbortController();
        const { signal } = controller;

        reader.addEventListener('loadend', () => { resolve([undefined, reader.result as ArrayBuffer]); controller.abort() }, {once:true, signal});
        reader.addEventListener('error', (e) => { resolve([e as any, undefined!]); controller.abort(); }, {once:true, signal});
        reader.readAsArrayBuffer(blob);
    });
}

export function arrayBufferToBlob(buffer: ArrayBuffer, type: string) {
    return new Blob([buffer], {type: type});
}

export function readAsTextFile(file: Blob) {
    return file.text();
    return new Promise<string>(resolve => {
        const reader = new FileReader();
        reader.addEventListener('load', () => resolve(reader.result as string));
        reader.readAsText(file);
    });
}

export function blob2File(blob:Blob, fileName?:string|((ext:string) => string), options?: FilePropertyBag | undefined) {
    const now = Date.now();
    const ext = contentType2Extension(blob.type);
    fileName = (typeof fileName == "function") ? fileName(ext) : (fileName || (!ext ? `${now}` : `${now}.${ext}`));
    return new File([blob], fileName, { lastModified: options?.lastModified ?? now, type: options?.type ?? blob.type });
}

export async function fileSaver({ data, fileName }: { data:string|Blob, fileName: string }) {
    const mFileSaver = import("file-saver");
    const { default: FileSaver } = (await mFileSaver);
    FileSaver(data, fileName);
}
export async function export2Csv(xs:object[], fileName:string) {
    const { csvFormat } = await import("d3-dsv");

    const csv = csvFormat(xs);
    await downloadAsCsv(csv, fileName);
}

export async function downloadAsCsv(value: string, fileName: string) {
    const mFileSaver = import("file-saver");
    const { default: FileSaver } = (await mFileSaver);
    FileSaver(new Blob([value], { type: "text/csv;charset=utf-8" }), fileName);
}

export async function export2CsvUsingFormatRows(rows: string[][], fileName:string) {
    const mFileSaver = import("file-saver");
    const { csvFormatRows } = await import("d3-dsv");
    const { default: FileSaver } = (await mFileSaver);

    const csv = csvFormatRows(rows);
    FileSaver(new Blob([csv], {type: "text/csv;charset=utf-8"}), fileName);
}
export async function exportExcel(rows: string[][], fileName:string) {
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    const sheet = workbook.addWorksheet('Schedule');

    for (const row of rows) {
        sheet.addRow(row);
    }
    await export2Excel(workbook, fileName);
}
export async function export2Excel(workbook: any, fileName: string){
    const mFileSaver = import("file-saver");
        const { default: FileSaver } = (await mFileSaver);
        const blobType: string = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        workbook.xlsx.writeBuffer().then((data:any) => {
            const blob = new Blob([data], { type: blobType });
            FileSaver.saveAs(blob, fileName);
        });
}
export function export2Ics(text:string, fileName:string) {
    return fileSaver({ data:new Blob([text], {type: "text/calendar;charset=utf-8"}), fileName });
}

export async function loadCsv(blob: Blob) {
    const p = blob.text();
    const { csvParse } = await import("d3-dsv");
    const content = await p;
    return csvParse(content);
}

function uint8ArrayUtf8ByteString(array: Uint8Array, start: number, end: number){
    return String.fromCharCode(...array.slice(start, end));
};

function isHeic(buffer: Uint8Array){
    const brandMajor = uint8ArrayUtf8ByteString(buffer, 8, 12).replace('\0', ' ').trim();

    switch (brandMajor) {
        case 'mif1':
            return true; // {ext: 'heic', mime: 'image/heif'};
        case 'msf1':
            return true; // {ext: 'heic', mime: 'image/heif-sequence'};
        case 'heic':
        case 'heix':
            return true; // {ext: 'heic', mime: 'image/heic'};
        case 'hevc':
        case 'hevx':
            return true; // {ext: 'heic', mime: 'image/heic-sequence'};
    }

    return false;
};

export async function processSpecialFile(file: any){
    const fileExt = getFileExtension(file.name ?? "").toLowerCase();
    if (!!file && (["image/heif", "image/heif-sequence", "image/heic", "image/heic-sequence"].includes(file.type) || fileExt == "heic")) {
        let inputBuffer = null;
        if(file.url){
            const inputBlob = await fetch(file.url).then(r => r.blob());
            inputBuffer = await inputBlob.arrayBuffer();
        }else if(file instanceof File){
            inputBuffer = await (file as File).arrayBuffer();
        }
        if(inputBuffer){
            const bufferArr = new Uint8Array(inputBuffer);
            if (isHeic(bufferArr)) {
                const HeicConvert = await import('heic-convert');
                const outputBuffer = await HeicConvert.default({
                    buffer: bufferArr, // the HEIC file buffer
                    format: 'JPEG',      // output format
                    quality: 1           // the jpeg compression quality, between 0 and 1
                });
                const uid = file.uid;
                file = new File([outputBuffer], file.name.replace(/.heic$/, ".jpg"), { type: 'image/jpeg' });
                file.url = file.thumbUrl = URL.createObjectURL(file);
                file.uid = uid; //keep original uid in order to use later for multiple files upload
            }
        }
    }
    return file;
}
export function getFileExtension(fileName: string){
    return (fileName.split(".").pop() ?? "").toLowerCase();
}

export function getListExtension(allowFileTypes ?: IAllowFileTypeInfo[]) {
    return allowFileTypes?.map(x => "." + x.ext) ?? [];
}

export function getFilenameFromURL(url?: string, includeExt: boolean = false) {
    if (url == null) return "";
    return includeExt ? url.substring(url.lastIndexOf("/") + 1) : url.substring(url.lastIndexOf("/") + 1, url.lastIndexOf("."));
}

export function getFileNameWithoutExtension(fileName: string){
    return (fileName.split(".").shift() ?? "").toLowerCase();
}
export function checkSupportFileMimeType(checkingMimeType: string, allowFileTypes ?: IAllowFileTypeInfo[]){
    return !!allowFileTypes?.some(x => x.mimeType == checkingMimeType.toLowerCase());
}

export function checkSupportFileExtension(checkingExtension: string, allowFileTypes ?: IAllowFileTypeInfo[]) {
    return !!allowFileTypes?.some(x => x.ext === checkingExtension.toLowerCase().replace(".", ""));
}

export function checkSupportedFileTypes<T extends { name : string, type ?: string }>(v: T, allowFileTypes ?: IAllowFileTypeInfo[]): boolean {
    if (!allowFileTypes) return true;
    return v.type !== undefined && v.type !== ""
        ?  checkSupportFileMimeType(v.type.toLowerCase(), allowFileTypes)
        :  checkSupportFileExtension(getFileExtension(v.name).toLowerCase(), allowFileTypes);
}

export function toMimeTypeListString(fileTypeList?: IAllowFileTypeInfo[], separator?: string){
    return uniq((fileTypeList ?? []).map(x => x.mimeType)).join(separator ?? ",");
}
export function toFileExtensionListString(fileTypeList?: IAllowFileTypeInfo[], separator?: string) {
    return uniq((fileTypeList ?? []).map(x => `.${x.ext}`)).sort((a,b) => a.localeCompare(b)).join(separator ?? ", ");
}
export function toFileTypeListString(fileTypeList?: IAllowFileTypeInfo[], separator?: string) {
    //return the combination of file extensions and mime types of files allowed to upload to system
    //we using the combination to make sure it works in all PC.
    //there is a situation when browsers on window os in a particular PC did not recognize and accept "text/csv", it only understand ".csv" (that mimetypes did not registered in Registry)
    //that cause the bug of upload button did not work properly sometimes
    //we believe that there are others case like the one happened and decide to using the combination of file extensions and mimetypes
    return uniq((fileTypeList ?? []).map(x => [`.${x.ext}`, x.mimeType]).flat()).join(separator ?? ",");
}

export async function isBlobAnimatedGif(blob: Blob) {
    if (blob.type != 'image/gif') return false;
    const [err, arrayBuffer] = await blob2ArrayBuffer(blob);
    if (err) return false;
    var arr = new Uint8Array(arrayBuffer);

    var frames = 0;

    // make sure it's a gif (GIF8)
    if (arr[0] !== 0x47 || arr[1] !== 0x49 ||
        arr[2] !== 0x46 || arr[3] !== 0x38)
    {
        return false;
    }

    for (var i=0; i < arr.length - 9; i++) {
        if (arr[i] === 0x00 && arr[i+1] === 0x21 &&
            arr[i+2] === 0xF9 && arr[i+3] === 0x04 &&
            arr[i+8] === 0x00 &&
            (arr[i+9] === 0x2C || arr[i+9] === 0x21))
        {
            frames++;
            if(frames > 1)
                return true;
        }
    }

    return false;
}

export async function resizeImageFile(file:File, maxWidth:number, maxHeight:number): Promise<[Error|undefined, File]> {
    // const [err, dataUri] = await blob2DataUri(file);
    // if (err) { debugger; return [err, undefined]; }

    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    img.src = URL.createObjectURL(file); // dataUri
    await new Promise(resolve => img.onload = resolve);

    let ctx = canvas.getContext("2d"); if (ctx == null) { debugger; return [new Error('canvas.getContext("2d") return null'), undefined!]; }
    ctx.drawImage(img, 0, 0);

    let width = img.width;
    let height = img.height;

    if (width < maxWidth && height < maxHeight) return [undefined, file];

    if (height < width) {
        height *= maxWidth / width;
        width = maxWidth;
    } else {
        width *= maxHeight / height;
        height = maxHeight;
    }
    canvas.width = width;
    canvas.height = height;

    ctx = canvas.getContext("2d"); if (ctx == null) { debugger; return [new Error('canvas.getContext("2d") return null'), undefined!]; }
    ctx.drawImage(img, 0, 0, width, height);

    const resizedBlob = await new Promise<Blob|null>(resolve => canvas.toBlob(resolve, file.type, 1));
    if (resizedBlob == null) { debugger; return [new Error('canvas.toBlob() return null'), undefined!]; }
    const resizedFile = new File([resizedBlob], file.name, { type: file.type });
    return [undefined, resizedFile];
}

export async function getImageElement(file:File): Promise<HTMLImageElement | undefined> {

    const img = document.createElement('img');
    const canvas = document.createElement('canvas');
    img.src = URL.createObjectURL(file); // dataUri
    await new Promise(resolve => img.onload = resolve);

    let ctx = canvas.getContext("2d"); if (ctx == null) { return undefined; }
    ctx.drawImage(img, 0, 0);

    return img;
}

export function getRecordingFileName(ext: string) { return `recording_${moment().format("YYYY-MM-DD[T]HH-mm-ss.SSSZZ")}.${ext}` }

/** only use when cannot get District.allowDocExtensions  */
const docExts = [
    /\.docx$/i,
    /\.pptx$/i,
    /\.xls$/i,
    /\.xlsx$/i,
    /\.ppt$/i,
    /\.doc$/i,
    /\.pdf$/i,
    /\.odp$/i,
    /\.odt$/i,
    /\.ods$/i,
    /\.ai$/i,
    /\.eps$/i,
    /\.psd$/i
]

/** only use when cannot get District.allowDocExtensions  */
const docMimetypes = [
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    'application/vnd.ms-powerpoint',
    'application/msword',
    'application/pdf',
    'application/vnd.google-apps.document',
    'application/vnd.google-apps.presentation',
    'application/vnd.google-apps.spreadsheet',
    'application/vnd.google-apps.form',
    'application/vnd.google-apps.drawing',
    'application/vnd.oasis.opendocument.presentation',
    'application/vnd.oasis.opendocument.text',
    'application/vnd.oasis.opendocument.spreadsheet',
    'application/octet-stream',
    'application/x-photoshop',
    'image/vnd.adobe.photoshop',
    'image/x-photoshop',
    'application/octet-stream',
    'application/postscript',
    'application/octet-stream',
]

export enum AllowFileGroupType {
    Unknown,
    Image,
    Audio,
    Video,
    Document,
    Package
}

export function canDisplayImageUrl(url: string){
    if(/\.heic/i.test(url)) return false;
    return true;
}
const playableVideoExtension = ["webm", "mp4", "mov", "mkv", "m4v"]
export function canPlayVideoUrl(url: string){
    return playableVideoExtension.some(x => url.toLocaleLowerCase().endsWith(`.${x}`));
}

export function contentType2GroupType(contentType:string, link: string): AllowFileGroupType {
    if (/image\/\w+/i.test(contentType) || imageExts.some(regex => regex.test(link))) return AllowFileGroupType.Image;
    if (/audio\/\w+/i.test(contentType) || audioExts.some(regex => regex.test(link))) return AllowFileGroupType.Audio;
    if (/video\/\w+/i.test(contentType) || videoExts.some(regex => regex.test(link))) return AllowFileGroupType.Video;
    if (docMimetypes.some(x => x === contentType) || docExts.some(regex => regex.test(link))) return AllowFileGroupType.Document;
    return AllowFileGroupType.Unknown;
}
export function isBlobFile(url: string){
    //is to detect whether the url come from ekadence file system
    return new RegExp(`^${window.location.origin}(:[0-9]+)?/(blob|resources)`, "i").test(url);
}
export function extractFileNameFromUrl(url: string){
    return url.split(/[?#]/)[0]?.split("/").pop();
}
export function formatBytes(bytes: number, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
}

export async function fromFileToUploadFile(file: File | Blob) : Promise<UploadFile<any>> {
    const url = await URL.createObjectURL(file);
    const uploadFile = file as any as UploadFile<any>;
    uploadFile.uid = nanoid();
    uploadFile.url = url;
    uploadFile.thumbUrl = url;
    return uploadFile;
}
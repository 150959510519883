import { ActItem, ActOption, EActItemType, FillInBlankItemType, NumericSliderOption, isValidPollItem } from "../../models/ActDoc";
import { Activity, ActivityType } from "../../models/Activity";
import { CreateGoogleAssignmentRequest, GoogleDrive, GoogleFile } from "../../models/GoogleDrive";
import { CreateMicrosoftAssignmentRequest, OneDrive } from "./../../models/Microsoft/OneDrive";
import { isValidIdentity } from "../../models/types";
import { ErrorCode, ErrorItemType, IErrorData, createError, createInvalidError } from "../../services/api/AppError";
import { uploadDocument, uploadMedia } from "../../services/api/upload";
import { blob2File, dataURItoBlob, isBlobAnimatedGif, resizeImageFile } from "../file";
import { extractContent, stripHtml } from "../html";
import { isUrl } from "../url";
import { notNull } from "../list";
import { StudentActItem, StudentPdfFormActItem } from "../../models/StudentActDoc";
import { contentType2Extension } from "../getMediaInfo";
import { nanoid } from "nanoid";

export function isValidActItems<T extends ActItem>(items: T[]) {
    for (let item of items) {
        const eActItem = isValidActItem(item);
        if (eActItem) return eActItem;
    }
    return undefined;
}

export function isValidActItem<T extends ActItem>(item: T): ErrorItemType<T> | undefined {
    let error: IErrorData<any> | undefined;
    switch (item.type) {
        case EActItemType.Heading         :
        case EActItemType.Text            :
        case EActItemType.Media           :
        case EActItemType.Embed           :
        case EActItemType.TextQuiz        :
        case EActItemType.Document        :
        case EActItemType.PdfForm         :
        case EActItemType.GoogleAssignment:
        case EActItemType.MarkUpImage     :
            break;
       case EActItemType.EmbedFrame      :
            if(isUrl(item.link) == false) {
                error = createInvalidError({errorObject: "app.presentation.addBtn.EmbedFrame.mustBeAValidUrl"});
            }
            break;
        case EActItemType.Discussion:
            if(isValidIdentity(item.discussionId) == false && item.discussion && (stripHtml(item.discussion.title) ?? "") == "") {
                error = createInvalidError({errorObject: "app.actDoc.editor.error.item.discussion.newTopic.required"});
            }
            break;
        case EActItemType.ChoiceQuiz:
            if (item.options) item.options = item.options.filter((opt) => opt.IsValid());
            if (!item.options || item.options.length < 1) {
                item.options?.push(new ActOption());
                error = createInvalidError({errorObject :"app.actDoc.editor.multiple.required"});
            }
            else if (item.isDuplicateChoice) {
                error = createInvalidError({errorObject :"app.actDoc.editor.item.options.duplicate"});
            }
            else if (item.options.filter(x => x.isCorrect).length == 0) {
                error = createInvalidError({errorObject :"app.actDoc.editor.item.options.noCorrectAnswer"});
            }
            item.isMultipleAnswer = (item.options && item.options.filter(x => x.isCorrect).length > 1);
            break;
        case EActItemType.MatchQuiz:
            item.matchQuizzItems = item.matchQuizzItems.filter(i => i.hasLeftValue || i.hasRightValue);
            if (!item.matchQuizzItems || item.matchQuizzItems.length == 0)
                error = createInvalidError({errorObject :"app.actDoc.editor.matching.required"});

            break;
        case EActItemType.MatchQuizN:
            item.matchQuizzRowItems = item.matchQuizzRowItems.filter(i => i.leftOptions.some(x => x.hasValue) || i.rightOptions.some(x => x.hasValue));
            if (!item.matchQuizzRowItems || item.matchQuizzRowItems.length == 0)
                error = createInvalidError({errorObject :"app.actDoc.editor.matching.required"});

            item.matchQuizzNoMappingAnswers = item.matchQuizzNoMappingAnswers.filter(i => i.hasValue && !item.matchQuizzRowItems.flatMap(x => x.rightOptions).some(y => y.id == i.id));

            break;
        case EActItemType.PollQuiz:
            if (item.pollItems) item.pollItems = item.pollItems.filter((pollItem) => pollItem.getLabel != "" || pollItem.imageFile != null || pollItem.image != "");
            if (!isValidPollItem(item))
            {
                error = createInvalidError({errorObject :"app.actDoc.editor.polling.required"});
            }

            break;
        case EActItemType.HighlightText:
            const errorCode: string | undefined = (() => {
                if (extractContent(item.highlightTextContent) == "")
                    return "app.actDoc.editor.highlightText.questionContentRequired"
                if (item.highlightTextItems.length == 0)
                    return "app.actDoc.editor.highlightText.possibleResponseRequired"
                if (item.correctHighlightItems.length == 0)
                    return "app.actDoc.editor.highlightText.correctResponseRequired"
                return undefined;
            })();
            if (!!errorCode) error = createInvalidError({ errorCode: ErrorCode.HighlightText, errorObject : { code: errorCode }});
            break;
        case EActItemType.FillInBlank:
            const blankItems = item.fillInBlankItems;
            const errorNumeric = blankItems?.some(x => x.type == FillInBlankItemType.Numeric && x.numericMin == null && x.numericMax == null) ?? false;
            if(errorNumeric){
            error = createInvalidError({errorObject :"api.activity.error.facultyActivityDoNotSave"});
            }
            if (blankItems) for (let blank of blankItems) {
                if ((blank.type == FillInBlankItemType.TypeIn   && blank.options.filter(x => x.getLabel != ""               ).length == 0)
                    || (blank.type == FillInBlankItemType.DropDown && blank.options.filter(x => x.getLabel != "" && x.isCorrect).length == 0)
                ) error = createInvalidError({ errorObject : "app.actDoc.editor.item.options.noCorrectAnswer" });
            }
            break;
        case EActItemType.OrderList:
            if (item.options) item.options = item.options.filter((opt) => opt.IsValid());
            if (!item.options || item.options.length < 1) {
                item.options?.push(new ActOption());
                error = createError(new Error("app.actDoc.editor.orderList.required"), 500);
            }
            break;
        case EActItemType.NumericSlider:
            if (item.numericSliderOptions) item.numericSliderOptions = item.numericSliderOptions.filter((opt) => opt.isValidLabel());
            if (!item.numericSliderOptions || item.numericSliderOptions.length < 1) {
                item.numericSliderOptions?.push(new NumericSliderOption({correctValue: item.numericMin}));
                error = createError(new Error("app.actDoc.editor.numericSlider.required"), 500);
            }
            if (item.numericSliderOptions.some(x => !x.isValid(item))) {
                error = createInvalidError({errorObject : { code: "app.actDoc.editor.numericSlider.invalidOptions" }});
            }
            break;
        case EActItemType.NumericQuestion :
            if(item.numericMin == null && item.numericMax == null){
                error = createInvalidError({errorObject :"api.activity.error.facultyActivityDoNotSave"});
            }
            break;
        default:
            break;
    }
    if (error) return ({ item, error });
    return undefined;
}

const MAX_IMAGE_WIDTH = 1024;
const MAX_IMAGE_HEIGHT = 1024;

export function uploadFileInActItems(items: ActItem[]) {
    return Promise.all(
        items.map(async item => {
            if (item.imageFile == null) return;
            let file = item.imageFile.originFileObj || (item.imageFile as any as File);
            const isAnimatedGif = await isBlobAnimatedGif(file);
            if(!isAnimatedGif){
                const [rErr, resizedFile] = await resizeImageFile(file, MAX_IMAGE_WIDTH, MAX_IMAGE_HEIGHT);
                if (!rErr) file = resizedFile;
            }
            const [err, url] = await uploadMedia(file);
            if (!err) {
                item.set_imageFile(undefined);
                item.set_image(url);
            }
            return err;
        }).concat(items.map(async item => {
            if (item.mediaFile == null) return;
            const f = item.mediaFile.originFileObj || (item.mediaFile as any as File);
            const [err, url] = await (item.pMediaFile ? item.pMediaFile : uploadMedia(f));
            if (!err) {
                item.set_mediaFile(undefined);
                item.set_link(url);
            }
            return err;
        })).concat(items.map(async item => {
            if (item.documentFile == null) return;
            const f = item.documentFile.originFileObj || (item.documentFile as any as File);
            const [err, res] = await uploadDocument(f);
            if (!err) {
                item.set_documentFile(undefined);
                item.set_link(res.docUrl);
                item.set_pdfLink(res.pdfUrl);
            }
            return err;
        }))
        .concat(items.flatMap(item => {
            //Upload images in Multiple Choice questions
            if (item.options == null) return [];
            return (item.options.map(async option => {
                if (option.imageFile == null) return;
                const f = option.imageFile.originFileObj || (option.imageFile as any as File);
                const [err, url] = await uploadMedia(f);
                if (!err) {
                    option.set_imageFile(undefined);
                    option.set_image(url);
                }
                return err;
            }));
        })).concat(items.flatMap(item => {
            //Upload images in Polling questions
            if (item.pollItems == null) return [];
            return (item.pollItems.map(async poll => {
                if (poll.imageFile == null) return;
                const f = poll.imageFile.originFileObj || (poll.imageFile as any as File);
                const [err, url] = await uploadMedia(f);
                if (!err) {
                    poll.set_imageFile(undefined);
                    poll.set_image(url);
                }
                return err;
            }));
        })).concat(items.flatMap(item => {
            //Upload images in Matching questions
            if (item.matchQuizzItems == null || item.matchQuizzItems.length < 1) return [];
            return (item.matchQuizzItems.map(async matchQuizz => {
                if (matchQuizz.leftImageFile != null) {
                    const f = matchQuizz.leftImageFile.originFileObj || (matchQuizz.leftImageFile as any as File);
                    const [err, url] = await uploadMedia(f);
                    if (err) return err;
                    matchQuizz.set_leftImageFile(undefined);
                    matchQuizz.set_leftImage(url);
                }
                if (matchQuizz.rightImageFile != null) {
                    const f = matchQuizz.rightImageFile.originFileObj || (matchQuizz.rightImageFile as any as File);
                    const [err, url] = await uploadMedia(f);
                    if (err) return err;
                    matchQuizz.set_rightImageFile(undefined);
                    matchQuizz.set_rightImage(url);
                }
                return;
            }));
        })).concat(items.flatMap(item => {
            //Upload images in Matching questions
            if (item.matchQuizzRowItems == null || item.matchQuizzRowItems.length < 1) return [];
            return (item.matchQuizzRowItems.flatMap(rowItem => {
                return rowItem.leftOptions.concat(rowItem.rightOptions).map(async (optionItem) => {
                    if (optionItem.imageFile != null) {
                        const f = optionItem.imageFile.originFileObj || (optionItem.imageFile as any as File);
                        const [err, url] = await uploadMedia(f);
                        if (err) return err;
                        optionItem.set_imageFile(undefined);
                        optionItem.set_image(url);
                    }
                    return;
                });
            }));
        })).concat(items.flatMap(item => {
            //Upload images in matchQuizzNoMappingAnswers
            if (item.matchQuizzNoMappingAnswers == null || item.matchQuizzNoMappingAnswers.length < 1) return [];
            return (item.matchQuizzNoMappingAnswers.map(async (optionItem) => {
                    if (optionItem.imageFile != null) {
                        const f = optionItem.imageFile.originFileObj || (optionItem.imageFile as any as File);
                        const [err, url] = await uploadMedia(f);
                        if (err) return err;
                        optionItem.set_imageFile(undefined);
                        optionItem.set_image(url);
                    }
                    return;
            }));
        }))
    );
}

export function uploadFileInStudentActItems(items: StudentActItem[]) {
    return Promise.all(items.map(async item => {
            if (item.type === EActItemType.PdfForm) {
                return await (item as StudentPdfFormActItem).saveAnswer();
            }
            return;
        }).concat(items.map(async item => {
            // just add the same logic as in MarkUpImage in StudentPortal\stores\EditStudentActDocStore.ts
            if (item.type === EActItemType.MarkUpImage && /data:image\/[a-zA-Z0-9-+.]+;base64,/i.test(item.markUpImageAnswer ?? "")) {
                const info = (item.markUpImageAnswer ?? "").substring(0, 50);
                const m = /^data:(?<mimeType>image\/[a-zA-Z0-9-+.]+);base64,/i.exec(info);
                if (!!m) {
                    let ext = contentType2Extension(m.groups?.["mimeType"] ?? "");
                    if (ext === "") ext = "jpg";
                    const file = blob2File(dataURItoBlob(item.markUpImageAnswer!), `${nanoid(8)}.${ext}`);
                    const [err, url] = await uploadMedia(file);
                    if (!err) {
                        item.set_markUpImageAnswer(url);
                    }
                    return err;
                }
            }
            return;
        }))
    );
}

export async function processGoogleAssignmentBeforeSave(items: ActItem[], activity: Pick<Activity,  "activityId"| "classId" | "title">, embedOwnerAccount?: string) {
    const gDocErrs: IErrorData<any>[] = [];
    for(let item of items) {
        if (item.type !== EActItemType.GoogleAssignment) continue;
        let file : CreateGoogleAssignmentRequest | undefined = undefined;
        if (item.googleFile != null) {
            const f = item.googleFile;
            file = new CreateGoogleAssignmentRequest({
                googleFileId    : f.id,
                name            : f.name,
                mimeType        : f.mimeType,
                classId         : activity.classId,
                activityId      : activity.activityId,
                isEmbedLink     : f.isEmbedLink,
            });
        }
        if (!file) continue;
        let err: IErrorData<any> | undefined = undefined;
        let url : string = "";
        let selectedGoogleFile: GoogleFile | undefined = undefined;
        [err, selectedGoogleFile] = await GoogleDrive.createFile(file);
        if (!err) {
            url = selectedGoogleFile.embedUrl;
        }
        if (!err) {
            item.set_googleFile(undefined);
            item.set_embedLink(url);
            if(selectedGoogleFile) item.set_embedActItem({ mimeType: selectedGoogleFile.mimeType, embedId: selectedGoogleFile.id, embedLastEdited: Date.now().valueOf(), embedOwnerAccount, isEmbedLink: file.isEmbedLink, embedName: selectedGoogleFile.name })
        }
        if(err) gDocErrs.push(err);
    }
    return gDocErrs;
}

interface IActivity{
    activityId: number,
    classId?: number,
    type: ActivityType
}

export async function processMSAssignmentBeforeSave(items: ActItem[], activity: IActivity, origItems?: ActItem[]) {
    if (activity.type != ActivityType.Assignment && activity.type != ActivityType.Assessment) return [];

    // Try to mark old files as archived
    const oneDriveItems = await processMarkArchivedMSFiles(items, activity, origItems);

    // Clone file
    const msDocErrs: IErrorData<any>[] = [];
    for(let item of oneDriveItems) {
        let file : CreateMicrosoftAssignmentRequest | undefined = undefined;
        if (item.microsoftFile != null) {
            const f = item.microsoftFile;
            file = new CreateMicrosoftAssignmentRequest({
                id         : f.id,
                type       : f.type,
                name       : f.displayName,
                classId    : activity.classId,
                activityId : activity.activityId,
            });
        }
        if (!file) continue;
        const [err, selectedMSFile] = await OneDrive.createFile(file);
        if (!err && !!selectedMSFile) {
            item.set_microsoftFile(selectedMSFile);
        }
        if(err) msDocErrs.push(err);
    }

    return msDocErrs;
}

export async function processMarkArchivedMSFiles(items: ActItem[], activity: IActivity, origItems?: ActItem[]){
    const oneDriveItems = items.filter(x => x.type == EActItemType.MicrosoftAssignment);

    if (!origItems || origItems?.some(x => x.type == EActItemType.MicrosoftAssignment && !!x.embedId)){
        // Try to mark old files as archived
        await OneDrive.markFileAsArchived({
            ...activity,
            items: oneDriveItems.map(x => x.embedId).filter(notNull)
        });
    }

    return oneDriveItems;
}
import { ThemeConfig } from "antd";
import { primaryColorThemes } from "./Themes/theme";

/**
 * refs: https://ant.design/theme-editor#
 * visit the link above to generate the theme config
 */
export const themeConfig: ThemeConfig = {
    // cssVar: { key: 'ant-compatible', prefix: 'ant' },
    token: {
        // replace all less variables by component token here
        // after replace must be remove all less variables file from ant

		// Seed Token
        colorPrimary: primaryColorThemes.light,
        fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
        zIndexPopupBase: 1002,

        // Map Token
        borderRadiusLG: 5,
        borderRadiusOuter: 5,
        borderRadiusSM: 3,
        borderRadiusXS: 2,

        controlHeightLG: 42,
        controlHeightSM: 32,
        controlHeightXS: 16,
        fontSizeHeading1: 38,
        fontSizeHeading2: 30,
        fontSizeHeading3: 24,
        fontSizeHeading4: 20,
        fontSizeHeading5: 16,
        fontSizeLG: 16,
        fontSizeSM: 12,
        fontSizeXL: 20,
        lineType: 'solid',
        lineWidth: 1,
        lineWidthBold: 2,
        lineHeightLG: 1.5,
        size: 16,
        sizeLG: 24,
        sizeMD: 20,
        sizeMS: 16,
        sizeSM: 12,
        sizeXXL: 48,
        sizeXL: 32,
        sizeXS: 8,
        sizeXXS: 4,

		// Alias Token
        margin: 16,
        marginXXL: 48,
        marginXL: 32,
        marginLG: 24,
        marginMD: 20,
        marginXS: 8,
        marginXXS: 4,
        padding: 16,
        paddingXL: 32,
        paddingLG: 24,
        paddingMD: 20,
        paddingXS: 8,
        paddingXXS: 4,
        screenLG: 992,

	},
    components: {
        // component style
        Anchor: {

        },
        Avatar: {

        },
        Badge: {
            indicatorHeight: 16,
        },
        Breadcrumb: {

        },
        Button: {
            controlHeight: 42,
            controlHeightLG: 42,
            controlHeightSM: 32,
            controlHeightXS: 16,
        },
        Calendar: {

        },
        Card: {
            actionsLiMargin: '12px 0px',
        },
        Carousel: {

        },
        Cascader: {

        },
        Checkbox: {
            controlInteractiveSize: 20,
        },
        Collapse: {

        },
        DatePicker: {
            cellHeight: 24, // default
            cellWidth: 36, // default
        },
        Descriptions: {

        },
        Divider: {

        },
        Drawer: {

        },
        Dropdown: {
            paddingBlock: 5,
            zIndexPopup: 1050,
        },
        Empty: {
            fontSize: 14,
        },
        Form: {

        },
        Image: {

        },
        Input: {
            inputFontSize: 14,
            inputFontSizeLG: 14,
            inputFontSizeSM: 14,
            paddingInline: 11,
            paddingInlineLG: 11,
            paddingInlineSM: 8,
            paddingBlock: 5,
            paddingBlockLG: 5,
            paddingBlockSM: 0,
        },
        InputNumber: {
        },
        Layout: {
            headerHeight: 80,
        },
        List: {
            avatarMarginRight: 12,
            itemPaddingSM: '4px 16px',
        },
        Menu: {
            itemPaddingInline: 11,
            itemHeight: 32,
            iconSize: 14,
            iconMarginInlineEnd: 10,
        },
        Tooltip: {
            colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
        },
        Tabs: {
            horizontalItemGutter: 24,
        },
        Table: {
            cellPaddingBlock: 8,
            cellPaddingBlockMD: 6,
            cellPaddingBlockSM: 4,
            cellPaddingInline: 8,
            cellPaddingInlineMD: 6,
            cellPaddingInlineSM: 4,
            selectionColumnWidth: 32,
        },
        Select: {
            optionFontSize: 14,
            multipleItemBorderColor: 'transparent',
            multipleItemBorderColorDisabled: 'transparent',
        },
        Tree: {
            indentSize: 24,
            titleHeight: 24,
        },
    }
}
import { theme as antdTheme } from "antd";
import { darkTheme, grayTheme, defaultTheme, fade } from "../../themes/cssVarTheme";
import { DarkThemeType, eKadenceThemes, LightThemeType, ThemeModeType, darkThemeTypes, lightThemeTypes, ComponentTokenType } from "./theme";

export { fade, defaultTheme };

function isDarkTheme(theme: ThemeModeType): theme is ThemeModeType {
    return theme === "dark" || theme === "gray";
}

export function getAlgorithm(theme: any) {
    return isDarkTheme(theme) ? antdTheme.darkAlgorithm : undefined;
}

// css variable from component token, for compatibility with antd v4
export function getCssVariableFromTheme(theme: ThemeModeType) {
    const token = eKadenceThemes[theme];
    let _defaultTheme : {};
    const isDark = isDarkTheme(theme);
    if (isDark) {
        if (theme === 'gray') _defaultTheme = grayTheme;
        else _defaultTheme = darkTheme;
    } else {
        _defaultTheme = defaultTheme;
    }
    return getCssVariableFromComponentToken(token, _defaultTheme);
}

/**
 * refs: https://ant.design/docs/react/migrate-less-variables
 * Note: @vunguyenek: if you want to use more css variable, you can add more here
 * more exists css variable in our system in `src/themes/cssVarTheme.ts`
 * */
function getCssVariableFromComponentToken(theme ?: ComponentTokenType, defaultTheme ?: {}) {
    let currentTheme = {};
    if (theme) {
        currentTheme = {
            ...theme.token && {
                // "--switch-color"            : theme.token.colorPrimary,
                "--primary-color"           : theme.token.colorPrimary,

                "--primary-color-f20"       : fade(theme.token.colorPrimary??"", 20),
                "--info-color"              : theme.token.colorInfo,
                "--processing-color"        : theme.token.colorPrimary,
                "--link-color"              : theme.token.colorLink,
                "--link-hover-color"        : theme.token.colorLinkHover,
                "--dropdown-selected-color" : theme.token.colorPrimary,
                "--dropdown-selected-bg"    : theme.token.controlItemBgActive,

                "--text-color"              : theme.token.colorText,
                "--heading-color"           : theme.token.colorTextHeading,

                "--disabled-input-bg"       : theme.token.colorBgContainerDisabled,
            },
            ...theme.components && {

                // Menu
                ...theme.components.Menu &&{
                    "--menu-highlight-color"   : theme.components.Menu.itemSelectedColor,
                    "--menu-light-theme-color-base": theme.components.Menu.itemColor,
                },

                // Input
                ...theme.components.Input && {
                    "--input-hover-border-color" : theme.components.Input.hoverBorderColor,
                    "--activeBorderColor": theme.components.Input.activeBorderColor,
                    "--activeShadow": theme.components.Input.activeShadow,
                },
                
                // Modal
                ...theme.components.Modal && {
                    "--colorBgElevated": theme.components.Modal.contentBg,
                    "--headerBg": theme.components.Modal.headerBg,
                },

                // Tabs
                ...theme.components.Tabs && {
                    "--itemActiveColor": theme.components.Tabs.itemActiveColor,
                    "--itemSelectedColor": theme.components.Tabs.itemSelectedColor,
                },

                // Tree
                ...theme.components.Tree && {
                    "--nodeSelectedBg": theme.components.Tree.nodeSelectedBg,
                },

                // Progress
                ...theme.components.Progress && {
                    "--circleTextColor": theme.components.Progress.circleTextColor,
                },

                // Select
                ...theme.components.Select && {
                    "--optionSelectedColor": theme.components.Select.optionSelectedColor,
                    "--activeOutlineColor": theme.components.Select.activeOutlineColor,
                },

                // DatePicker
                ...theme.components.DatePicker && {
                    "--activeBorderColor": theme.components.DatePicker.activeBorderColor,
                },
            }

        };
    }

    return {...defaultTheme, ...currentTheme};
}

export function getDarkThemes(): Record<DarkThemeType, {}> {
    const result: Record<DarkThemeType, {}> = {} as any;
    for (let theme of darkThemeTypes) {
        result[theme] = getCssVariableFromTheme(theme);
    }
    return result;
}
export function getLightThemes(): Record<LightThemeType, {}> {
    const result: Record<LightThemeType, {}> = {} as any;
    for (let theme of lightThemeTypes) {
        result[theme] = getCssVariableFromTheme(theme);
    }
    return result;
}

export const lightIdThemes = getLightThemes();
export const darkIdThemes = getDarkThemes();

export type { ThemeModeType };
export const allThemes: { [key in ThemeModeType]: { [key: string]: string } } = {...lightIdThemes, ...darkIdThemes};
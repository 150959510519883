import { Modal as ModalAndtd, Statistic  } from "antd";
import type { ModalFuncProps } from "antd/lib/modal";
import { Trans } from "react-i18next";
import i18n from "../../i18n";

import { sessionStorage } from "../../utils/localStorage";
import { ClientErrorLog } from "../../models/ClientErrorLog";

const { Countdown } = Statistic;

export const isShowedConnectionFailModal = "isShowedConnectionFailModal";

// TODO: Just keep this function for compatible version.
// Use App.useApp() instead
const modalStyles = {
    content: {
        backgroundColor: "var(--component-background)",
    },
    header: {
        backgroundColor: "var(--component-background)",
    }
};

const okButtonStyle = {
    backgroundColor: "var(--primary-color)",
    borderColor: "var(--primary-color)",
    color: "#FFF",
};
const cancelButtonStyle = {
    backgroundColor: "var(--component-background)",
    borderColor: "var(--border-color-base)",
    color: "var(--text-color)",
};

const defaultStyles = {
    styles: modalStyles,
    okButtonProps: { style: okButtonStyle },
    cancelButtonProps: { style: cancelButtonStyle },
};

const okDangerButtonStyle = {
    backgroundColor: "var(--component-background)",
};

const dangerStyles = {
    styles: modalStyles,
    okButtonProps: { style: okDangerButtonStyle },
    cancelButtonProps: { style: cancelButtonStyle },
};

/**
 * @deprecated: just keep this function for compatible version.
 * Use App.useApp() instead
 */
export class ModalAntd {
    /**
     * @deprecated
     * please use:
     * ```
     * src\components\Ant\hook\useModal.tsx
     * ```
     * */
    static error (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.error') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.error({...props, ...defaultStyles});
    }

    /**
     * @deprecated
     * please use:
     * ```
     * src\components\Ant\hook\useModal.tsx
     * ```
     * */
    static info (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.info') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.info({...props, ...defaultStyles});
    }

    /**
     * @deprecated
     * please use:
     * ```
     * src\components\Ant\hook\useModal.tsx
     * ```
     * */
    static success (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.success') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.success({...props, ...defaultStyles});
    }

    /**
     * @deprecated
     * please use:
     * ```
     * src\components\Ant\hook\useModal.tsx
     * ```
     * */
    static warning (props: ModalFuncProps){
        props.title = !props.title ? i18n.t('component.modal.warning') : props.title;
        props.okText = !props.okText ? i18n.t('form.ok') : props.okText;
        return ModalAndtd.warning({...props, ...defaultStyles});
    }

    /**
     * @deprecated
     * please use:
     * ```
     * src\components\Ant\hook\useModal.tsx
     * ```
     * */
    static deleteConfirm(props: ModalFuncProps){
        return this.confirm({
            title     : i18n.t('component.modal.confirm.delete'),
            okText    : i18n.t("form.yes"),
            okType    : "danger",
            cancelText: i18n.t("form.no"),
            zIndex    : 1250,
            ...props,
            ...dangerStyles,
        });
    }

    /**
     * @deprecated
     * please use:
     * ```
     * src\components\Ant\hook\useModal.tsx
     * ```
     * */
    static confirm(props: Omit<ModalFuncProps, "onOk"|"onCancel">){
        props.title      = !props.title      ? i18n.t('component.modal.confirm'): props.title;
        props.okText     = !props.okText     ? i18n.t('form.yes')               : props.okText;
        props.cancelText = !props.cancelText ? i18n.t('form.no')                : props.cancelText;
        props.zIndex = 1250;
        let _styles: {} = defaultStyles;
        if(props.okType === 'danger') _styles = dangerStyles;
        return new Promise<boolean>(resolve => {
            ModalAndtd.confirm({
                ...props,
                onOk() { resolve(true) },
                onCancel() { resolve(false) },
                ..._styles
            });
        });
    }

    /**
     * @deprecated
     * please use:
     * ```
     * src\components\Ant\hook\useModal.tsx
     * ```
     * */
    static async connectionFail(errLog: ClientErrorLog){
        if(!sessionStorage.getItem(isShowedConnectionFailModal)){
            sessionStorage.setItem(isShowedConnectionFailModal, "true");

            // send error detail to server to log
            const [err, clientLogId] = await ClientErrorLog.addLog(errLog);

            function onOk(){
                sessionStorage.removeItem(isShowedConnectionFailModal);
                location.reload();
            }

            function onCancel(){
                sessionStorage.removeItem(isShowedConnectionFailModal);
            }

            const deadline = Date.now() + 1000 * 15;//refresh page after 15 seconds
            const modal = ModalAndtd.confirm({
                title: i18n.t('component.modal.error'),
                content:<Trans i18nKey="component.modal.err.connectionLost.content"><Countdown value={deadline} onFinish={onOk} format="ss" valueStyle={{"font-size": '16px', "font-weight":"500"}} className="inline-block" />{clientLogId?`LogId=${clientLogId}. `:''}</Trans>,
                className : "multi-line-text", //apply line break \n to content
                okText: i18n.t('component.modal.err.retryNow'),
                cancelText: i18n.t('component.modal.err.close'),
                zIndex: 1060,
                onOk:onOk,
                onCancel: onCancel,
                autoFocusButton: 'cancel',
                ...defaultStyles
              });
            return modal;
        }
        return;
    }
}

/**
 * @deprecated
 * please use:
 * ```
 * src\components\Ant\hook\useModal.tsx
 * ```
 * */
export const Modal = ModalAntd;
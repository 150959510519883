import { Empty, theme, ThemeConfig } from "antd";
import { OverrideToken, AliasToken } from "antd/lib/theme/interface";

export const lightThemeTypes = ['light', 'red', 'blue', 'green', 'orange'] as const;
export const darkThemeTypes = ['dark', 'gray'] as const;
export const allThemeTypes = [...lightThemeTypes, ...darkThemeTypes] as const;
export type LightThemeType = typeof lightThemeTypes[number];
export type DarkThemeType = typeof darkThemeTypes[number];
export type ThemeModeType = LightThemeType | DarkThemeType;

export type ComponentTokenType = { token: Partial<AliasToken>, components ?: Partial<OverrideToken>};

/** primary color for all supported themes */
export const primaryColorThemes: Record<ThemeModeType, string> = {
    light  : '#3C4EBC' ,
    red    : '#cd4545' ,
    blue   : '#346be2' ,
    green  : '#008940' ,
    orange : '#be5205' ,
    dark   : '#121212' ,
    gray   : '#333333' ,
};

//#region Theme for each color
const lightTheme = {
    token: {
        colorPrimary: primaryColorThemes.light,
        colorLink: primaryColorThemes.light,
        colorLinkHover: primaryColorThemes.light,
        colorText: "#29364D",
        colorTextSecondary: '#5f6c7f',
        colorTextHeading: "#29364D",
        colorError: '#d93131',
        colorErrorText: '#d93131',

        colorBorder: "#E3E3E3",
        colorBorderSecondary: "#E3E3E3",

        colorBgContainer: "#FFFFFF",
        colorBgContainerDisabled: "#F5F5F5",
    },
    components: {
        Menu: {
            itemSelectedColor: primaryColorThemes.light,
            itemColor: "#647286",
        },
        Input: {
            hoverBorderColor: primaryColorThemes.light,
            activeBorderColor: primaryColorThemes.light,
            activeShadow: '0 0 0 2px rgba(60, 78, 188, 0.1)',
        },
        Modal: {
            contentBg: '#FFFFFF',
        },
        Tabs: {
            itemActiveColor: primaryColorThemes.light,
            itemSelectedColor: primaryColorThemes.light,
        },
        Tree: {
            nodeHoverBg: 'rgba(60, 78, 188, .1)',
            nodeHoverColor: '#29364D',
            nodeSelectedBg: 'rgba(60, 78, 188, .5)',
            nodeSelectedColor: '#29364D',
        },
        Progress: {
            circleTextColor: '#29364D',
        },
        Select: {
            optionSelectedColor: '#29364D',
            activeOutlineColor: 'transparent',
        },
        DatePicker: {
            activeBorderColor: primaryColorThemes.light,
        },
    }
};

const redTheme = {
    token: {
        colorPrimary: primaryColorThemes.red,
        colorLink: "#9c3333",
        colorLinkHover: "#9c3333",
        colorText: "#29364D",
        colorTextSecondary: '#5f6c7f',
        colorTextHeading: "#29364D",
        colorError: '#d93131',
        colorErrorText: '#d93131',

        colorBorder: "#E3E3E3",
        colorBorderSecondary: "#E3E3E3",

        colorBgContainer: "#FFFFFF",
        colorBgContainerDisabled: "#F5F5F5",
    },
    components: {
        Menu: {
            itemSelectedColor: primaryColorThemes.red,
            itemColor: "#647286",
        },
        Input: {
            hoverBorderColor: primaryColorThemes.red,
            activeBorderColor: primaryColorThemes.red,
            activeShadow: '0 0 0 2px rgba(205, 69, 69, 0.1)',
        },
        Modal: {
            contentBg: '#FFFFFF',
        },
        Tabs: {
            itemActiveColor: primaryColorThemes.red,
            itemSelectedColor: primaryColorThemes.red,
        },
        Tree: {
            nodeHoverBg: 'rgba(205, 69, 69, .1)',
            nodeHoverColor: '#29364D',
            nodeSelectedBg: 'rgba(205, 69, 69, .5)',
            nodeSelectedColor: '#29364D',
        },
        Progress: {
            circleTextColor: '#29364D',
        },
        Select: {
            optionSelectedColor: '#29364D',
            activeOutlineColor: 'transparent',
        },
        DatePicker: {
            activeBorderColor: primaryColorThemes.red,
        },
    }
};

const blueTheme = {
    token: {
        colorPrimary: primaryColorThemes.blue,
        colorLink: '#2855b6',
        colorLinkHover: '#2855b6',
        colorText: "#29364D",
        colorTextSecondary: '#5f6c7f',
        colorTextHeading: "#29364D",
        colorError: '#d93131',
        colorErrorText: '#d93131',

        colorBorder: "#E3E3E3",
        colorBorderSecondary: "#E3E3E3",

        colorBgContainer: "#FFFFFF",
        colorBgContainerDisabled: "#F5F5F5",
    },
    components: {
        Menu: {
            itemSelectedColor: primaryColorThemes.blue,
            itemColor: "#647286",
        },
        Input: {
            hoverBorderColor: primaryColorThemes.blue,
            activeBorderColor: primaryColorThemes.blue,
            activeShadow: '0 0 0 2px rgba(52, 107, 226, 0.1)',
        },
        Modal: {
            contentBg: '#FFFFFF',
        },
        Tabs: {
            itemActiveColor: primaryColorThemes.blue,
            itemSelectedColor: primaryColorThemes.blue,
        },
        Tree: {
            nodeHoverBg: 'rgba(52, 107, 226, .1)',
            nodeHoverColor: '#29364D',
            nodeSelectedBg: 'rgba(52, 107, 226, .5)',
            nodeSelectedColor: '#29364D',
        },
        Progress: {
            circleTextColor: '#29364D',
        },
        Select: {
            optionSelectedColor: '#29364D',
            activeOutlineColor: 'transparent',
        },
        DatePicker: {
            activeBorderColor: primaryColorThemes.blue,
        },
    }
};

const greenTheme = {
    token: {
        colorPrimary: primaryColorThemes.green,
        colorLink: '#006830',
        colorLinkHover: '#006830',
        colorText: "#29364D",
        colorTextSecondary: '#5f6c7f',
        colorTextHeading: "#29364D",
        colorError: '#d93131',
        colorErrorText: '#d93131',

        colorBorder: "#E3E3E3",
        colorBorderSecondary: "#E3E3E3",

        colorBgContainer: "#FFFFFF",
        colorBgContainerDisabled: "#F5F5F5",

        colorInfoText: '#008940',
        colorLinkActive: '#008940',
        colorPrimaryText: '#008940',
    },
    components: {
        Menu: {
            itemSelectedColor: primaryColorThemes.green,
            itemColor: "#647286",
        },
        Input: {
            hoverBorderColor: primaryColorThemes.green,
            activeBorderColor: primaryColorThemes.green,
            activeShadow: '0 0 0 2px rgba(0, 137, 64, 0.1)',
        },
        Modal: {
            contentBg: '#FFFFFF',
        },
        Tabs: {
            itemActiveColor: primaryColorThemes.green,
            itemSelectedColor: primaryColorThemes.green,
        },
        Tree: {
            nodeHoverBg: 'rgba(60, 137, 64, .1)',
            nodeHoverColor: '#29364D',
            nodeSelectedBg: 'rgba(60, 137, 64, .5)',
            nodeSelectedColor: '#29364D',
        },
        Progress: {
            circleTextColor: '#29364D',
        },
        Select: {
            optionSelectedColor: '#29364D',
            activeOutlineColor: 'transparent',
        },
        DatePicker: {
            activeBorderColor: primaryColorThemes.green,
        },
    }
};

const orangeTheme = {
    token: {
        colorPrimary: primaryColorThemes.orange,
        colorLink: '#994101',
        colorLinkHover: '#994101',
        colorText: "#29364D",
        colorTextSecondary: '#5f6c7f',
        colorTextHeading: "#29364D",
        colorError: '#d93131',
        colorErrorText: '#d93131',

        colorBorder: "#E3E3E3",
        colorBorderSecondary: "#E3E3E3",

        colorBgContainer: "#FFFFFF",
        colorBgContainerDisabled: "#F5F5F5",
    },
    components: {
        Menu: {
            itemSelectedColor: primaryColorThemes.orange,
            itemColor: "#647286",
        },
        Input: {
            hoverBorderColor: primaryColorThemes.orange,
            activeBorderColor: primaryColorThemes.orange,
            activeShadow: '0 0 0 2px rgba(190, 82, 5, 0.1)',
        },
        Modal: {
            contentBg: '#FFFFFF',
        },
        Tabs: {
            itemActiveColor: primaryColorThemes.orange,
            itemSelectedColor: primaryColorThemes.orange,
        },
        Tree: {
            nodeHoverBg: 'rgba(190, 82, 5, .1)',
            nodeHoverColor: '#29364D',
            nodeSelectedBg: 'rgba(190, 82, 5, .5)',
            nodeSelectedColor: '#29364D',
        },
        Progress: {
            circleTextColor: '#29364D',
        },
        Select: {
            optionSelectedColor: '#29364D',
            activeOutlineColor: 'transparent',
        },
        DatePicker: {
            activeBorderColor: primaryColorThemes.orange,
        },
    }
};

const darkTheme = {
    token: {
        colorPrimary: '#3C4DBC',
        colorLink: '#868dbd',
        colorLinkHover: '#868dbd',
        colorText: 'rgba(256, 256, 256, .87)',
        colorTextSecondary: 'rgba(256, 256, 256, .6)',
        colorTextHeading: 'rgba(256, 256, 256, .87)',
        colorError: '#e74749',
        colorErrorText: '#e74749',

        colorBorder: "#a5a5a5",
        colorBorderSecondary: "#2b2e31",
        colorBgContainerDisabled: 'rgba(256, 256, 256, .12)',
        colorBgContainer: "#000102",
        colorBgLayout: "#121212",
    },
    components: {
        Menu: {
            itemSelectedColor: '#868dbd',
            itemColor: 'rgba(256, 256, 256, .87)',
        },
        Input: {
            hoverBorderColor: '#3C4DBC',
            activeBorderColor: '#3C4DBC',
            activeShadow: '0 0 0 2px rgba(60, 77, 188, 0.1)',
        },
        Layout: {
            headerBg: primaryColorThemes.dark,
            bodyBg: primaryColorThemes.dark,
            footerBg: primaryColorThemes.dark,
        },
        Modal: {
            contentBg: "#000102",
            headerBg: "#000102",
        },
        Tabs: {
            itemActiveColor: '#868dbd',
            itemSelectedColor: '#868dbd',
        },
        Tree: {
            nodeHoverBg: 'rgba(60, 77, 188, .5)',
            nodeHoverColor: 'rgba(256, 256, 256, .87)',
            nodeSelectedBg: 'rgba(60, 77, 188, .9)',
            nodeSelectedColor: 'rgba(256, 256, 256, .87)',
        },
        Progress: {
            circleTextColor: 'rgba(256, 256, 256, .87)',
        },
        Select: {
            optionSelectedColor: 'rgba(256, 256, 256, .87)',
            activeOutlineColor: 'transparent',
        },
        DatePicker: {
            activeBorderColor: "#3C4DBC",
        },
        Empty: {
            colorTextDescription: '#747575',
        },
    }
};

const grayTheme = {
    token: {
        colorPrimary: '#5A69C6',
        colorLink: '#939bcf',
        colorLinkHover: '#939bcf',
        colorText: 'rgba(256, 256, 256, .87)',
        colorTextSecondary: 'rgba(256, 256, 256, .6)',
        colorTextHeading: 'rgba(256, 256, 256, .87)',
        colorError: '#ff6b6b',
        colorErrorText: '#ff6b6b',

        colorBorder: "#a5a5a5",
        colorBorderSecondary: "#626262",
        colorBgContainerDisabled: 'rgba(256, 256, 256, .12)',
        colorBgContainer: "#272727",
        colorBgLayout: "#333333",
    },
    components: {
        Menu: {
            itemSelectedColor: '#939bcf',
            itemColor: 'rgba(256, 256, 256, .87)',
        },
        Input: {
            hoverBorderColor: '#5A69C6',
            activeBorderColor: '#5A69C6',
            activeShadow: '0 0 0 2px rgba(90, 105, 198, 0.1)',
        },
        Layout: {
            headerBg: primaryColorThemes.gray,
            bodyBg: primaryColorThemes.gray,
            footerBg: primaryColorThemes.gray,
        },
        Modal: {
            contentBg: "#272727",
            headerBg: "#272727",
        },
        Tabs: {
            itemActiveColor: '#939bcf',
            itemSelectedColor: '#939bcf',
        },
        Tree: {
            nodeHoverBg: 'rgba(90, 105, 198, .5)',
            nodeHoverColor: 'rgba(256, 256, 256, .87)',
            nodeSelectedBg: 'rgba(90, 105, 198, .9)',
            nodeSelectedColor: 'rgba(256, 256, 256, .87)',
        },
        Progress: {
            circleTextColor: 'rgba(256, 256, 256, .87)',
        },
        Select: {
            optionSelectedColor: 'rgba(256, 256, 256, .87)',
            activeOutlineColor: 'transparent',
        },
        DatePicker: {
            activeBorderColor: "#5A69C6",
        },
        Empty: {
            colorTextDescription: '#919191',
        },
    }
};
//#endregion

/** refs: https://ant.design/theme-editor */
export const eKadenceThemes: Record<ThemeModeType, ComponentTokenType> = {
    light : lightTheme ,
    red   : redTheme   ,
    blue  : blueTheme  ,
    green : greenTheme ,
    orange: orangeTheme,
    dark  : darkTheme  ,
    gray  : grayTheme  ,
};

export const printThemes: ThemeConfig = {
    ...lightTheme,
    algorithm: theme.defaultAlgorithm,
};
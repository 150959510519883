import { useEffect, RefObject } from "react";
import { hex2hsl } from "../../utils/color";
import { allThemes, ThemeModeType, fade, defaultTheme } from "../Ant/Themes/themeCompatibleUtils";

/** re export for compatible with older version */
export type { ThemeModeType };

class CssVarTheme {
    constructor(private data:{[key:string]: string}) { }
    updateData(data:{[key:string]: string}) {
        this.data = Object.assign({}, this.data, data);
    }
    setProperty(element:ElementCSSInlineStyle) {
        for (const [variable, value] of Object.entries(this.data)) {
            if (typeof value == "string" && value.startsWith("#")) {
                const [i, h, s, l] = hex2hsl(value);
                if (i) {
                    element.style.setProperty(`${variable}__h_s`, `${h}, ${s}%`);
                    element.style.setProperty(`${variable}__l`, `${l}%`);
                    // element.style.setProperty(variable, `hsl(var(${variable}__h_s), var(${variable}__l))`);
                }
            }
            element.style.setProperty(variable, value);
        }
    }
}

export function useTheme(data: { [key:string]: string} , element?:RefObject<ElementCSSInlineStyle>) {
    useEffect(() => {
        const theme = new CssVarTheme(defaultTheme);
        theme.updateData(data);
        theme.setProperty(element?.current ?? document.documentElement);
    }, [data, element]);
}

export function useModuleColor(mainColor: string, secondaryColor: string, element:RefObject<ElementCSSInlineStyle>) {
    useEffect(() => {
        const ele = element?.current;
        if (ele == null) return;
        const theme = new CssVarTheme({
            "--module-main-color": "#FFFFFF",
            "--module-secondary-color": "#FFFFFF",
            "--activity-main-color": "#A7BEEC",
            "--activity-secondary-color": "#D0E0FF",
            "--module-main-color-f8": fade("--module-main-color", 8),
            "--module-main-color-f10": fade("--module-main-color", 10),
            "--activity-main-color-f10": fade("--activity-main-color", 10),
        });
        theme.updateData({
            "--module-main-color": mainColor,
            "--module-secondary-color": secondaryColor,
        });
        theme.setProperty(ele);
    }, [mainColor, secondaryColor, element]);
}

export function getThemeProp(themeId: ThemeModeType, propName: string){
    if(allThemes.hasOwnProperty(themeId)){
        let theme = allThemes[themeId];
        if(theme.hasOwnProperty(propName)) return theme[propName];
        // theme = defaultTheme;
        if(theme.hasOwnProperty(propName)) return theme[propName];
    }
    return "";
}

import { observable, action, toJS, computed, makeObservable, runInAction } from "mobx";

import {DbIdentity, EUserRole, DefaultId, NumberDate, ISearchActivityLogResult, IStudentUserExt, StringDate} from "./types";
import type {UserTokenData} from "./types";
import { aFetch } from "../services/api/fetch";
import { PermissionEnum } from "./Permission";
import { notNull, stringSorter, stringSorterDesc } from "../utils/list";
import { toDbIdentity } from "../utils/number";
import { Class } from "./Class";
import { ClassFaculty } from "./ClassFaculty";
import { School } from "./School";
import { ClassStudent } from "./ClassStudent";
import { omit, uniq } from "lodash-es";
import { firstLastName, lastFirstName } from "../utils/stringUtils";
import { StudentExt } from "./Student";
import { UserExtension } from "./UserExtension";
import { PowerSearchTargets } from "./PowerSearch";
import { ApplicationRoleValue } from "./ApplicationRoleValue";
import { Pagination } from "./Pagination";

//TODO: consider to replace by PrimaryRole
export enum UserTypeEnum {
    Faculty = 0,
    Student = 1
}
export enum SisPhotoStatusE { Empty = 0, Available, InUse }

export interface IAvatarStats {
    aeriesAvatar: string;
    avatar: string;
    hasProfile: boolean;
    sisStatus: SisPhotoStatusE;
}

export interface IUserPrefrence{
    language: string;
    timeZone: string;
}
export const enum UserDetailFieldEnum {
    SisUserIds      = "sisUserIds",
    Dob             = "dob",
    PrimaryRole     = "primaryRole",
    AdditionalRoles = "additionalRoles",
    PhoneNumber     = "phoneNumber",
    Email           = "email",
    AlternateEmail  = "alternateEmail",
    LockoutEnabled  = "lockoutEnabled",
    FirstName       = "firstName",
    MiddleName      = "middleName",
    LastName        = "lastName",
    DisplayName     = "displayName",
    StudentPortalRole   = "studentPortalRole",
    FacultyPortalRole   = "facultyPortalRole",
    ParentPortalRole   = "parentPortalRole",
    StudentNumber   = "studentNumber",
    Grade           = "grade",
    Schools         = "schools",
    JobPosition     = "JobPosition",
}
export enum PrimaryRoleEnum
{
    Unknown = 0,
    Faculty = 1,
    Student = 2,
    Parent  = 3
}

const userStringSorter = (selector: <T extends User1>(s: T) => string) => {
    return <T extends User>(a: T, b: T) => selector(a).localeCompare(selector(b))
}
const userStringSorterDesc = (selector: <T extends User1>(s: T) => string) => {
    return <T extends User>(a: T, b: T) => -(selector(a).localeCompare(selector(b)))
}

export interface IUser {
    userId         : DbIdentity;
    email          : string;
    email2         : string;
    firstName      : string;
    middleName     : string;
    lastName       : string;
    firstNameAlias : string;
    middleNameAlias: string;
    lastNameAlias  : string;
    salutation     : string;
    displayName    : string;
    aboutMe        : string;
    phoneNumber    : string;
    avatar         : string;
    messengerJid   : string;
    messengerToken : string;
    messengerDomain: string;
    fontSettings   : string;
    hasIEP        ?: boolean;
    hasFacultyRole?: boolean;
    aeriesAvatar   : string;

    userName       : string;
    fullName       : string;
    dob           ?: StringDate;
    primaryRole   ?: PrimaryRoleEnum;

    get flName     (): string;
    get lfName     (): string;
    get flNameAlias(): string;
    get fullDetailName() : string;
    get flMixName     () : string;
    get displayFullMixName() : string;
}

/** Profile avater have its own default path */
export const isDefaultAvatar = (avatar?: string) => !(avatar?.length) || /^\/avatar\/\d+$/.test(avatar);

export class User1 extends UserExtension implements IUser {
    userId         : DbIdentity = DefaultId;
    email          : string     = "";
    email2         : string     = "";
    firstName      : string     = "";
    lastName       : string     = "";
    middleName     : string     = "";
    firstNameAlias : string     = "";
    middleNameAlias: string     = "";
    lastNameAlias  : string     = "";
    salutation     : string     = "";
    displayName    : string     = "";
    aboutMe        : string     = "";
    phoneNumber    : string     = "";
    avatar         : string     = "";
    messengerJid   : string     = "";
    messengerToken : string     = "";
    messengerDomain: string     = "";
    fontSettings   : string     = "";
    chatId         : string     = "";
    hasIEP        ?: boolean    = undefined;
    hasFacultyRole?: boolean    = undefined;
    oneRosterId   ?: string     = undefined;
    aeriesAvatar   : string     = "";
    dob           ?: StringDate = undefined;
    primaryRole   ?: PrimaryRoleEnum = PrimaryRoleEnum.Unknown;
    ePortfolioId  ?: DbIdentity = DefaultId;

    constructor(data?:{}) {
        super(data);
        if (data != null) Object.assign(this, omit(data, ['fullName']));

        makeObservable(this, {
            userId          : observable,
            email           : observable, set_email       : action.bound,
            email2          : observable, set_email2      : action.bound,
            firstName       : observable, set_firstName   : action.bound,
            middleName      : observable, set_middleName  : action.bound,
            lastName        : observable, set_lastName    : action.bound,
            salutation      : observable, set_salutation  : action.bound,
            displayName     : observable, set_displayName : action.bound,
            aboutMe         : observable, set_aboutMe     : action.bound,
            phoneNumber     : observable, set_phoneNumber : action.bound,
            avatar          : observable, set_avatar      : action.bound,
            messengerJid    : observable,
            messengerToken  : observable,
            messengerDomain : observable,
            fontSettings    : observable, set_fontSettings: action.bound,
            chatId          : observable,
            oneRosterId     : observable, set_oneRosterId : action.bound,
            hasIEP          : observable, set_hasIEP      : action.bound,
            hasFacultyRole  : observable, set_hasFacultyRole: action.bound,
            userName        : computed,
            fullName        : computed,
            flName          : computed,
            lfName          : computed,
            flNameAlias     : computed,
            lfNameAlias     : computed,
            flMixName       : computed,
            fullDetailName  : computed,
            isDefaultAvatar : computed,
            isStudentUser   : computed,
            getLastNameAlias: computed,
            dob             : observable, set_dob: action.bound,
            primaryRole     : observable, set_primaryRole     : action.bound,
            ePortfolioId    : observable, set_ePortfolioId    : action.bound,
        });

        if (this.firstName       == null) this.firstName       = '';
        if (this.middleName      == null) this.middleName      = '';
        if (this.lastName        == null) this.lastName        = '';
        if (this.firstNameAlias  == null) this.firstNameAlias  = '';
        if (this.middleNameAlias == null) this.middleNameAlias = '';
        if (this.lastNameAlias   == null) this.lastNameAlias   = '';
        if (this.salutation      == null) this.salutation      = '';
        if (this.displayName     == null) this.displayName     = '';
    }

    get fullDetailName() { return this.lfName + (!(this.firstNameAlias || this.getLastNameAlias || this.displayName) ? "" : ` (${this.userName})`) };
    get displayFullMixName() { return this.displayName?.trim() || this.flMixName};
    get userName() { return this.displayName?.trim() || ((this.firstNameAlias || this.firstName) + " " + (this.getLastNameAlias || this.lastName))?.trim()}
    get fullName() { return this.displayName || (this.salutation ? `${this.salutation} ${this.lastName}` : firstLastName(this.firstName, '', this.lastName))  }
    get flName() { return firstLastName(this.firstName, '', this.lastName) }
    get lfName() { return userLfName(this) }
    get flNameAlias() { return firstLastName(this.firstNameAlias, '', this.getLastNameAlias) || this.flName }
    get lfNameAlias() { return lastFirstName(this.firstNameAlias, '', this.getLastNameAlias) || lastFirstName(this.firstName, '', this.lastName) }
    get flMixName() { return firstLastName(this.firstNameAlias || this.firstName, '', this.getLastNameAlias || this.lastName)};
    get isDefaultAvatar(){
        const regEx = new RegExp(`/avatar/${this.userId}`);
        return regEx.test(this.avatar);
    }

    get isStudentUser(){return this.primaryRole == null || ![PrimaryRoleEnum.Faculty, PrimaryRoleEnum.Parent].includes(this.primaryRole);}
    get isFacultyUser(){ return this.primaryRole === PrimaryRoleEnum.Faculty; }
    get getLastNameAlias() {return (this.firstNameAlias?.length > 0 && this.lastNameAlias?.length > 0) ? this.lastNameAlias : ''}

    set_email       (v: string) { this.email           = v };
    set_email2      (v: string) { this.email2          = v };
    set_firstName   (v: string) { this.firstName       = v };
    set_middleName  (v: string) { this.middleName      = v };
    set_lastName    (v: string) { this.lastName        = v };
    set_salutation  (v: string) { this.salutation      = v };
    set_displayName (v: string) { this.displayName     = v };
    set_aboutMe     (v: string) { this.aboutMe         = v };
    set_phoneNumber (v: string) { this.phoneNumber     = v };
    set_avatar      (v: string) { this.avatar          = v };
    set_aeriesAvatar(v: string) { this.aeriesAvatar    = v };
    set_fontSettings(v: string) { this.fontSettings    = v };
    set_hasIEP      (v?: boolean){ this.hasIEP         = v };
    set_hasFacultyRole (v?: boolean){ this.hasFacultyRole = v };
    set_oneRosterId (v: string) { this.oneRosterId     = v };
    set_dob(v?: StringDate) { this.dob = v; }
    set_primaryRole (v: PrimaryRoleEnum) { this.primaryRole  = v };
    set_ePortfolioId (v: DbIdentity) { this.ePortfolioId  = v };

    toJS() {
        return toJS(this);
    }

    clone() {
        return new User1(this.toJS())
    }

    static async impersonate(userId: DbIdentity, role: EUserRole.Faculty | EUserRole.Student | EUserRole.Parent) {
        const [err, data] = await aFetch<string>("GET", `/account/impersonate/${role}/${userId}`);

        return [err, data] as const;
    }

    static async impersonateInClassAsStudent(userId: DbIdentity) {
        const [err, data] = await aFetch<string>("GET", `/account/impersonateInClassAsStudent/${userId}`);

        return [err, data] as const;
    }

    static async unimpersonate() {
        // return {role, token}
        const [err, data] = await aFetch<{role: string, token: string}>("GET", `/account/unimpersonate`)
        return [err, data] as const;
    }

    static async updateNewPassword(id: number, password: string) {
        const [err, data] = await aFetch<{}[]>("PUT", "/admin/setpassword", { id, password });
        return [err, (err ? undefined : data)!] as const;
    }

    static async addFacultyRole(userId:number){
        const [err, data] = await aFetch<{}[]>("POST", "/admin/addFacultyRole", userId);
        return [err, (err ? undefined : data)!] as const;
    }
    /**
     *
     * @param currentFacultyId facultyId of **Current User**
     * @param id id of user who **Current user** set password for
     * @param password
     */
    static async updateNewPasswordAsFacultyAdmin({currentFacultyId, id, password}:{currentFacultyId: DbIdentity, id: number, password: string}) {
        const [err, data] = await aFetch<{}[]>("PUT", `/faculty/${currentFacultyId}/admin/faculty/${id}/setpassword`, password);
        return [err, (err ? undefined : data)!] as const;
    }
}
export type AvatarType = 'school' | 'profile' | 'sis';
export type AvatarSource = {from: AvatarType | 'remove'} | {url: string};

export class User extends User1 {
    language         : string                             = "";
    timeZone         : string                             = "";
    chatEnabled      : boolean                            = false;
    showTinyMCE      : boolean                            = false;
    avaiRoles        : EUserRole[]                        = [];
    districtId       : DbIdentity                         = DefaultId;

    googleAccount    : string                             = "";

    isImpersonated   : boolean                            = false;
    canEditResource  : boolean                            = false;

    permission       : PermissionEnum                     = PermissionEnum.None;
    rolePermissions  : {role: ApplicationRoleValue, permissions: PermissionEnum}[] = [];

    schoolPermission : Record<DbIdentity, PermissionEnum> = {};
    schoolRolePermissions : {schoolId: DbIdentity, rolePermissions: {role: ApplicationRoleValue, permissions: PermissionEnum}[]}[] = [];

    isSchoolAdmin    : boolean                            = false;
    chatInlineEnabled: boolean                            = false;
    isDistrictAdmin  : boolean                            = false;
    termsAgreedDate ?: number                             = undefined;
    lastLoginDate           ?: NumberDate        = undefined;

    linkedInUrl : string = ""; set_linkedInUrl(v: string) { this.linkedInUrl = v; }
    location    : string = ""; set_location   (v: string) { this.location    = v; }

    constructor(data?:{}) {
        if(data) data = omit(data,['fullName','flName','userName'])
        super(data);
        if (data != null) Object.assign(this, omit(data, ['fullName']));
        makeObservable(this, {
            language              : observable        , set_language : action.bound,
            timeZone              : observable        , set_timeZone : action.bound,
            chatEnabled           : observable        , set_chatEnabled : action.bound,
            avaiRoles             : observable.shallow,
            districtId            : observable        ,
            googleAccount         : observable        , set_googleAccount : action.bound,
            isImpersonated        : observable        ,
            canEditResource       : observable        ,
            permission            : observable        ,
            rolePermissions       : observable.shallow,
            schoolRolePermissions : observable.shallow,
            chatInlineEnabled: observable      , set_chatInlineEnabled: action.bound,
            isDistrictAdmin: observable        ,
            termsAgreedDate : observable       , set_termsAgreedDate : action.bound,
            linkedInUrl     : observable       , set_linkedInUrl     : action.bound,
            location        : observable       , set_location        : action.bound,
            lastFirstMiddleDisplayName : computed,
            lastLoginDate             : observable,
            showTinyMCE    : observable,
            updateProfile: action.bound
        });
    }

    get userInBusinessCard() { return ({ address: this.location, linkedin: this.linkedInUrl, email: this.email, phone: this.phoneNumber }); }

    get lastFirstMiddleDisplayName(){
        let firstMiddleName = [this.firstName, this.middleName].filter(Boolean).join(" ").trim();
        let lastFirstMiddleName = [this.lastName, firstMiddleName].filter(Boolean).join(", ").trim();
        let result = lastFirstMiddleName;
        if(!!this.displayName && this.displayName.length > 0){
            result = [lastFirstMiddleName, `(${this.displayName})`].filter(Boolean).join(" ").trim();
        }
        return result;
    }
    set_language       (v: string) { this.language        = v };
    set_timeZone       (v: string) { this.timeZone        = v };
    set_chatEnabled    (v: boolean){ this.chatEnabled     = v };
    set_googleAccount  (v: string) { this.googleAccount   = v };
    set_chatInlineEnabled(v:boolean) {this.chatInlineEnabled = v};
    set_termsAgreedDate(v:number) {this.termsAgreedDate = v};

    override clone() {
        return new User(this.toJS())
    }

    static async fetchAvatars({signal}:{ signal: AbortSignal }) {
        const [err, x] = await aFetch<IAvatarStats>("GET", `/account/profile/avatars`, undefined, {signal});
        return [err, !!err ? undefined : x] as const;
    }

    static async fetchUserToken({userId, signal}:{userId: DbIdentity, signal?: AbortSignal}) {
        return await aFetch<UserTokenData>("GET", `/account/userToken/${userId}`, undefined, {signal});
    }

    static async fetchAdminUserAvatarsAsFacultyAdmin({adminId, userId , signal}:{ adminId:DbIdentity, userId:DbIdentity, signal: AbortSignal }) {
        const [err, x] = await aFetch<IAvatarStats>("GET", `/faculty/${adminId}/admin/user/${userId}/avatars`, undefined, { signal });
        return [err, !!err ? undefined : x] as const;
    }

    static async updateAvatarAsFacultyAdmin({type, source, adminId, userId}:{adminId:DbIdentity, userId: DbIdentity, type: string, source: AvatarSource}) {
        var segment = ('from' in source) ? `/from/${source.from}` : "";
        var body =  ('url' in source) ? source.url: undefined;
        const [err, x] = await aFetch<IAvatarStats>("PUT"
            ,  `/faculty/${adminId}/admin/user/${userId}/avatar/${type}${segment}`, body);
        return [err, !!err ? undefined : x] as const;
    }

    static async updateAvatar({type,source}:{type: string, source: AvatarSource}) {
        var segment = ('from' in source) ? `/from/${source.from}` : "";
        var body =  ('url' in source) ? source.url: undefined;
        const [err, x] = await aFetch<IAvatarStats>("PUT"
            ,  `/account/profile/avatar/${type}${segment}`, body);
        return [err, !!err ? undefined : x] as const;
    }

    private async getUserChatCredentials() {
        const [err, x] = await aFetch<{ chatId: string, messengerJid: string, messengerToken: string }>("GET", `/Account/credentials`, undefined);
        return [err, !!err ? undefined : x] as const;
    }

    async updateProfile(termsAgreed: boolean = false) {
        const [ err ] = await aFetch("PUT", "/Account/UpdateProfile", {
            firstName   : this.firstName,
            middleName  : this.middleName,
            lastName    : this.lastName,
            salutation  : this.salutation,
            displayName : this.displayName,
            aboutMe     : this.aboutMe,
            email2      : this.email2 ? this.email2 : null,
            phoneNumber : this.phoneNumber,
            language    : this.language,
            timeZone    : this.timeZone,
            chatEnabled : this.chatEnabled,
            fontSettings: this.fontSettings,
            termsAgreed : termsAgreed,
            pageTitle   : window.document.title,
            dob         : this.dob,
        });
        
        if (!this.chatId) {
            var [uccErr, data] = await this.getUserChatCredentials();

            if (!uccErr && !!data) {
                this.chatId = data.chatId;
                this.messengerJid = data.messengerJid;
                this.messengerToken = data.messengerToken;
            }
        }

        return err;
    }

    async updateBusinessCardInfo() {
        return await aFetch<{}, {[key:string]:string[]}>("PUT", "/Account/updateBusinessCardInfo", {
            location     : this.location,
            linkedInUrl  : this.linkedInUrl,
        });
    }

    sendDefaultPreference(pref: IUserPrefrence) {
        return aFetch<IUserPrefrence>("POST", "/Account/SetPreference", pref);
    }

    static async changePassword(password:string, newPassword:string) {
        const [err] = await aFetch<{}, {code:string, description:string}[]>("PUT", "/Account/ChangePassword", {
            password   ,
            newPassword,
        });
        return err;
    }

    static async toggleShowChatInline(v: boolean) {
        const [err, data] = await aFetch<boolean>("PUT", "/Account/ToogleDisabledInlineChat", v);
        return [err, data] as const;
    }

    static sorter = {
        flNameAlias: <T extends User1>(a: T, b: T) => a.flNameAlias.localeCompare(b.flNameAlias),
        displayNameSorter: userStringSorter(s => s.lfNameAlias.trim()),
        useNameSorter: userStringSorter(s => s.userName.trim()),
        emailSorter: userStringSorter(s => s.email.trim()),
        firstName: userStringSorter(s => s.firstName?.trim()),
        lastName: userStringSorter(s => s.lastName?.trim()),
        firstNameDesc: userStringSorterDesc(s => s.firstName?.trim()),
        lastNameDesc: userStringSorterDesc(s => s.lastName?.trim()),
        fullName: userStringSorter(s => s.fullName?.trim()),
        displayFullMixName: userStringSorter(s => s.displayFullMixName?.trim()),
    };

    static async fetchOne({ userId, signal }: { userId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<IUser>("GET", `/user/${userId}`, undefined, { signal });
        return [err, new User(data)] as const;
    }

    static async fetchOneByEmail({ email, signal }: { email: string, signal?: AbortSignal }) {
        const [err, data] = await aFetch<IUser>("GET", `/user/email/${email}`, undefined, { signal });
        return [err, new User(data)] as const;
    }

    static async fetchUserByIds(userIds: DbIdentity[], signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/many/${uniq(userIds).join(",")}`, undefined, {signal});
        return [err, err ? [] : data.map(dt => new User(dt))] as const;
    }

    static async checkHasAnyStudentRole(userIds: DbIdentity[], signal?: AbortSignal) {
        const [err, data] = await aFetch<boolean>("POST", `/user/hasAnyStudentRole`,  userIds, {signal});
        return [err, data ?? false] as const;
    }

    static async fetchClasses() {
        const [err, data] = await aFetch<{ schools?: {}[], classes?: {}[], classFaculties: {}[] } | undefined>("GET", `/user/currentGradingTerm/classes`);
        if (err || !data) return [err, { schools: undefined, classes: undefined, studentsByClassId: undefined }] as const;
        const schools = Array.isArray(data.schools) ? data.schools.map(s => new School(s)) : [];
        const classFaculties = Array.isArray(data.classFaculties) ? data.classFaculties.map(cls => new ClassFaculty(cls)) : [];
        const classes = Array.isArray(data.classes) ? data.classes.map(cls => {
            const c = new Class(cls);
            const cf = classFaculties.find(x => x.classId == c.classId);
            if (cf) {
                c.sortIndex = cf.sortIndex;
            }
            return c;
        }) : [];
        return [undefined, { schools, classes }] as const;
    }

    static async fetchStudentsOfClass({classId, pagination, signal}: {classId: DbIdentity, pagination: Pagination, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{ total: number, studentsByClassId?: {} } | undefined>("GET", `/user/currentGradingTerm/studentsOfClass`, {
            classId,
            offset: pagination.offset,
            limit: pagination.limit,
            orderBy: pagination.orderBy,
        }, {signal});
        if (err || !data) return [err, { total: 0, studentsByClassId: undefined }] as const;
        let studentsByClassId;
        if (!!data.studentsByClassId) {
            studentsByClassId = new Map(Object.entries(data.studentsByClassId)
                .map(([k, v]) => (!!toDbIdentity(k) && Array.isArray(v) ? { classId: toDbIdentity(k)!, classStudents: v.map(u => new User(u)) } : null))
                .filter(notNull).map(item => [item.classId, item.classStudents]));
        }
        return [undefined, { total: data.total, studentsByClassId }] as const;
    }

    static async fetchStudents() {
        const [err, data] = await aFetch<{ schools?: {}[], classes?: {}[], studentsByClassId?: {}, classFaculties: {}[] } | undefined>("GET", `/user/currentGradingTerm/students`);
        if (err || !data) return [err, { schools: undefined, classes: undefined, studentsByClassId: undefined }] as const;
        let studentsByClassId;
        const schools = Array.isArray(data.schools) ? data.schools.map(s => new School(s)) : [];
        const classFaculties = Array.isArray(data.classFaculties) ? data.classFaculties.map(cls => new ClassFaculty(cls)) : [];
        const classes = Array.isArray(data.classes) ? data.classes.map(cls => {
            const c = new Class(cls);
            const cf = classFaculties.find(x => x.classId == c.classId);
            if (cf) {
                c.sortIndex = cf.sortIndex;
            }
            return c;
        }) : [];
        if (!!data.studentsByClassId) {
            studentsByClassId = new Map(Object.entries(data.studentsByClassId)
                .map(([k, v]) => (!!toDbIdentity(k) && Array.isArray(v) ? { classId: toDbIdentity(k)!, classStudents: v.map(u => new User(u)) } : null))
                .filter(notNull).map(item => [item.classId, item.classStudents]));
        }
        return [undefined, { schools, classes, studentsByClassId }] as const;
    }
    static async fetchTeachers() {
        const [err, data] = await aFetch<{ schools?: {}[], classes?: {}[], facultiesByClassId?: {}, classFaculties?: {}[] } | undefined>("GET", `/user/currentGradingTerm/teachers`);
        if (err || !data) return [err, { schools: undefined, classes: undefined, facultiesByClassId: undefined, classFaculties: undefined }] as const;
        let facultiesByClassId;
        const schools = Array.isArray(data.schools) ? data.schools.map(s => new School(s)) : [];
        const classes = Array.isArray(data.classes) ? data.classes.map(cls => new Class(cls)) : [];
        const classFaculties = Array.isArray(data.classFaculties) ? data.classFaculties.map(cls => new ClassFaculty(cls)) : [];
        if (!!data.facultiesByClassId) {
            facultiesByClassId = new Map(Object.entries(data.facultiesByClassId)
                .map(([k, v]) => (!!toDbIdentity(k) && Array.isArray(v) ? { classId: toDbIdentity(k)!, classFaculties: v.map(u => new User(u)) } : null))
                .filter(notNull).map(item => [item.classId, item.classFaculties]));
        }
        return [undefined, { schools, classes, facultiesByClassId, classFaculties }] as const;
    }
    static async fetchTeachersAsParent() {
        const [err, data] = await aFetch<{
            schools?: {}[],
            classes?: {}[],
            classStudents?: {},
            facultiesByClassId: {}[],
            classFaculties?: {}[]
            students?: {}[] } | undefined>("GET", `/user/currentGradingTerm/facultiesAsParent`);
        if (err || !data) return [err, { classes: undefined, classStudents: undefined, facultiesByClassId: undefined, students: undefined, schools: undefined, classFaculties: undefined }] as const;
        const classes = Array.isArray(data.classes) ? data.classes.map(s => new Class(s)) : [];
        const schools = Array.isArray(data.schools) ? data.schools.map(s => new School(s)) : [];
        const classStudents = Array.isArray(data.classStudents) ? data.classStudents.map(cls => new ClassStudent(cls)) : [];
        const students = Array.isArray(data.students) ? data.students.map(cls => new User(cls)) : [];
        const classFaculties = Array.isArray(data.classFaculties) ? data.classFaculties.map(cls => new ClassFaculty(cls)) : [];
        let facultiesByClassId;
        if (!!data.facultiesByClassId) {
            facultiesByClassId = new Map(Object.entries(data.facultiesByClassId)
                .map(([k, v]) => (!!toDbIdentity(k) && Array.isArray(v) ? { classId: toDbIdentity(k)!, classFaculties: v.map(u => new User(u)) } : null))
                .filter(notNull).map(item => [item.classId, item.classFaculties]));
        }
        return [err, { schools, classes, classStudents, facultiesByClassId, students, classFaculties }] as const;
    }
    static async searchContactUsersAsStudent(keyword: string, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/contacts/searchContactUsersAsStudent`, { keyword }, {signal});
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async searchContactUsersAsFaculty(keyword: string, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/contacts/searchContactUsersAsFaculty`, { keyword }, {signal});
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async searchContactFacultiesAsFaculty(keyword: string, schoolId?: number, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/contacts/searchContactFacultiesAsFaculty`, { keyword, schoolId }, {signal});
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async getFacultiesByEmailList(listData: string) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/currentGradingTerm/getFacultiesByEmailList`, { listData });
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async searchChatContactUsersAsStudent(keyword: string) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/currentGradingTerm/searchChatContactUsersAsStudent`, { keyword });
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async searchChatContactUsersAsFaculty(keyword: string) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/currentGradingTerm/searchChatContactUsersAsFaculty`, { keyword });
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async searchChatContactUsersAsParent(keyword: string) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/currentGradingTerm/searchChatContactUsersAsParent`, { keyword });
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }

    static async searchEcardContactUsersAsStudent(keyword: string, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/searchEcardContactUsersAsStudent`, { keyword }, {signal});
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async searchEcardContactUsersAsFaculty(keyword: string, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/searchEcardContactUsersAsFaculty`, { keyword }, {signal});
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }
    static async searchEcardContactUsersAsParent(keyword: string, signal?: AbortSignal) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/searchEcardContactUsersAsParent`, { keyword }, {signal});
        return [err, err ? [] : data.map(x => new User(x))] as const;
    }

    static async searchUsersAsFaculty(schoolId: DbIdentity
        , facultyId:DbIdentity
        , pageIndex:number
        , pageSize:number
        , excludedClassId:DbIdentity
        , filteredClassId: DbIdentity
        , searchText:string
        , sortColumnKey: string|undefined
        , sortOrder: boolean|"ascend"|"descend"|undefined
        , target ?: PowerSearchTargets
        ) {
        const [err, data] = await aFetch<{}[]>("GET", `faculty/${facultyId}/searchUsers`, {
            schoolId: schoolId == DefaultId ? undefined : schoolId,
            page           : pageIndex,
            size           : pageSize,
            excludedClassId,
            filteredClassId,
            searchText,
            sortColumnKey  : sortColumnKey ? sortColumnKey: "",
            sortOrder      : sortOrder != undefined ? sortOrder : "",
            target         : target
        });
        return [ err, data.map(x => new SearchUser(x))] as const;
    }

    static async fetchUsersTotalPaginationAsFaculty(schoolId: DbIdentity
        , facultyId:DbIdentity
        , excludedClassId:DbIdentity
        , filteredClassId: DbIdentity
        , searchText:string
        , target ?: PowerSearchTargets
        ) {
        const [err, dto] = await aFetch<number>("GET", `/faculty/${facultyId}/countSearchUsers`,{
            schoolId: schoolId == DefaultId ? undefined : schoolId,
            excludedClassId,
            filteredClassId,
            searchText,
            target : target
        });
        return [err, (err ? undefined : dto)!] as const;
    }

    //#region CaseloadStudents
    static async searchStudentsAsFaculty(schoolId: DbIdentity
        , facultyId:DbIdentity
        , pageIndex:number
        , pageSize:number
        , caseloadId:DbIdentity
        , filteredClassId: DbIdentity
        , searchText:string
        , sortColumnKey: string|undefined
        , sortOrder: boolean|"ascend"|"descend"|undefined
        , signal?: AbortSignal
        ) {
        const [err, data] = await aFetch<{students:SearchUser[], total: number}>("GET", `faculty/${facultyId}/caseload/${caseloadId}/searchStudents`, {
            schoolId: schoolId == DefaultId ? undefined : schoolId,
            page           : pageIndex,
            size           : pageSize,
            caseloadId,
            filteredClassId,
            searchText,
            sortColumnKey  : sortColumnKey ? sortColumnKey: "",
            sortOrder      : sortOrder != undefined ? sortOrder : "",
        }, {signal});
        return [err, (err ? undefined : {students: data.students.map(x => new SearchUser(x)) ?? [], total: data.total})!] as const;
    }

    //#endregion

    static async fetchParents({userId, signal}: {userId: DbIdentity, signal?: AbortSignal}) {
        const [err, data] = await aFetch<{}[]>("GET", `/user/${userId}/parents`, undefined, { signal });
        return [err, (err ? undefined : data.map(x => new User(x)))!] as const;
    }

    async processChangeParentEmail() {
        const [err, emailIsGood] = await aFetch<boolean>("GET", `/Account/sendRequestChangeEmail`, { email : this.email});
        return [err, err ? false : emailIsGood] as const;
    }

    async processChangeAlternateEmail() {
        const [err, email2IsGood] = await aFetch<boolean>("GET", `/Account/sendRequestChangeEmail2`, { email2 : this.email2 ? this.email2 : null  });
        return [err, err ? false : email2IsGood] as const;
    }
}

export class UserExt extends User {
    //UserExt contains some additional infomation that show in faculty admin
    availablePrimaryRoles    : PrimaryRoleEnum[] = [];
    additionalRoleIds        : number[]          = [];
    notifyUserOfEmailChange  : boolean           = false;
    notifyUserOfAlternateEmailChange   : boolean           = false;
    locations                : string[]          = [];
    schedulingClasses        : string[]          = [];
    sisUserIds               : string[]          = [];
    constructor(data?:any) {
        super(data);
        makeObservable(this, {
            availablePrimaryRoles     : observable.shallow,
            additionalRoleIds         : observable.shallow,
            notifyUserOfEmailChange   : observable,
            notifyUserOfAlternateEmailChange : observable,
            schedulingClasses         : observable.shallow,
            sisUserIds                : observable.shallow,
        });
        if (data != null) {
            const {availablePrimaryRoles, schedulingClasses, sisUserIds, additionalRoleIds, notifyUserOfEmailChange, notifyUserOfAlternateEmailChange, ...pData} = data;
            Object.assign(this, pData);
            if(Array.isArray(availablePrimaryRoles)) this.availablePrimaryRoles = availablePrimaryRoles.map(x => x as PrimaryRoleEnum);
            if(Array.isArray(additionalRoleIds)) this.additionalRoleIds = additionalRoleIds.map(x => Number(x));
            if(Array.isArray(schedulingClasses)) this.schedulingClasses = schedulingClasses.map(x => String(x));
            if(Array.isArray(sisUserIds)) this.sisUserIds = sisUserIds.map(x => String(x));
        }
    }
    override clone() {
        return new UserExt(this.toJS())
    }

    //#region  faculty admin
    static async fetchOneAsFacultyAdmin({ currentFacultyId, userId, signal }: { currentFacultyId: DbIdentity, userId: DbIdentity, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{}>("GET", `/faculty/${currentFacultyId}/admin/user/${userId}`, undefined, { signal });
        return [err, new UserExt(data)] as const;
    }

    static async turnOnOffPortalAsFacultyAdmin({currentFacultyId, portal, userId, shouldDisable}:{currentFacultyId: DbIdentity, portal: EUserRole.Faculty | EUserRole.Student | EUserRole.Parent, userId: DbIdentity, shouldDisable: boolean}) {
        let action = "turnOnOffStudentPortal";
        switch(portal){
            case EUserRole.Faculty:
                action = "turnOnOffFacultyPortal";
                break;
            case EUserRole.Parent:
                action = "turnOnOffParentPortal";
                break;
        }
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${currentFacultyId}/admin/user/${userId}/${action}`, shouldDisable);
        return [err, err ? undefined : new UserExt(data)] as const;
    }
    static async updateInfoAsFacultyAdmin({currentFacultyId, userId, field, info }:{currentFacultyId: DbIdentity, userId: DbIdentity, field: UserDetailFieldEnum, info?: User}) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${currentFacultyId}/admin/user/${userId}/updateUserInfo/${field}`, info?.toJS());
        return [err, err ? undefined : new UserExt(data)] as const;
    }
    static async changeUserPasswordAsFacultyAdmin({currentFacultyId, id, password}:{currentFacultyId: DbIdentity, id: number, password: string}) {
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${currentFacultyId}/admin/user/${id}/setpassword`, password);
        return [err, (err ? undefined : data)!] as const;
    }
    static async fetchParentUsersOfStudentAsFacultyAdmin({ currentFacultyId, studentId, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, signal?: AbortSignal }){
        const [err, data] = await aFetch<{}[]>("GET", `/faculty/${currentFacultyId}/admin/student/${studentId}/parents`, undefined, { signal });
        return [err, (err ? [] : data.map(x => new UserExt(x))) ] as const;
    }
    static async fetchStudentUsersOfParentAsFacultyAdmin({ currentFacultyId, parentId, signal }: { currentFacultyId: DbIdentity, parentId: DbIdentity, signal?: AbortSignal }){
        const [err, data] = await aFetch<{userInfo: {}, studentInfo: {}}[]>("GET", `/faculty/${currentFacultyId}/admin/parent/${parentId}/students`, undefined, { signal });
        return [err, (err ? [] : data.map(x => ({userInfo: new UserExt(x.userInfo), studentInfo: new StudentExt(x.studentInfo)} as IStudentUserExt))) ] as const;
    }
    static async removeParentFromStudentAsFacultyAdmin({ currentFacultyId, studentId, parentId, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, parentId: DbIdentity, signal?: AbortSignal }){
        const [err] = await aFetch("DELETE", `/faculty/${currentFacultyId}/admin/student/${studentId}/parents/${parentId}/remove`, undefined, { signal });
        return err;
    }
    static async fetchParentUsersAsFacultyAdmin({ currentFacultyId, studentId, pageIndex, pageSize, searchText, sortColumnKey, sortOrder, signal }: { currentFacultyId: DbIdentity, studentId: DbIdentity, pageIndex:number, pageSize:number, searchText:string, sortColumnKey: string|undefined, sortOrder?: string|undefined, signal?: AbortSignal }){
        const [err, {results, total}] = await aFetch<{results: {}[], total: number}>("GET", `/faculty/${currentFacultyId}/admin/student/${studentId}/searchParentUsers`, {
            pageIndex           : pageIndex,
            pageSize            : pageSize,
            searchText,
            sortColumnKey  : sortColumnKey || "fullName",
            sortOrder      : sortOrder     || "ascend",
        }, { signal });
        return [err, (err ? [] : results.map(x => new SearchUser(x))), total ?? 0 ] as const;
    }
    static async addNewParentToStudentAsFacultyAdmin({currentFacultyId, studentId, parent}: {currentFacultyId: DbIdentity, studentId: DbIdentity, parent: User}){
        const [err, data] = await aFetch<{}>("PUT", `/faculty/${currentFacultyId}/admin/student/${studentId}/addNewParent`, parent.toJS());
        return [err, (err ? undefined : new User(data))] as const;
    }
    static async addParentToStudentAsFacultyAdmin({currentFacultyId, studentId, parentIds}: {currentFacultyId: DbIdentity, studentId: DbIdentity, parentIds: DbIdentity[]}){
        const [err, _] = await aFetch("PUT", `/faculty/${currentFacultyId}/admin/student/${studentId}/addParents`, parentIds);
        return err;
    }
    static async searchActivity({ currentFacultyId, userId, ps, signal }: { currentFacultyId: DbIdentity, userId: DbIdentity, ps: {keyword: string, portal: EUserRole, fromDate?: number, toDate?: number, pageIndex: number, pageSize: number}, signal?: AbortSignal }) {
        const [err, data] = await aFetch<{ activities: ISearchActivityLogResult[], totalRecord: number }>("GET", `/faculty/${currentFacultyId}/admin/user/${userId}/searchActivity`, ps, { signal });
        return [err, err ? {activities: [], totalRecord: 0} : data] as const;
    }
    static async fetchStudentUsersAsFacultyAdmin({ currentFacultyId, parentId, classId, schoolId, pageIndex, pageSize, searchText, sortColumnKey, sortOrder, signal }: { currentFacultyId: DbIdentity, parentId: DbIdentity, classId: DbIdentity, schoolId: DbIdentity, pageIndex:number, pageSize:number, searchText:string, sortColumnKey: string|undefined, sortOrder?: string|undefined, signal?: AbortSignal }){
        const [err, {results, total}] = await aFetch<{results: {}[], total: number}>("GET", `/faculty/${currentFacultyId}/admin/parent/${parentId}/searchStudentUsers`, {
            pageIndex           : pageIndex,
            pageSize            : pageSize,
            classId,
            schoolId,
            searchText,
            sortColumnKey  : sortColumnKey || "fullName",
            sortOrder      : sortOrder     || "ascend",
        }, { signal });
        return [err, (err ? [] : results.map(x => new SearchUser(x))), total ?? 0 ] as const;
    }
    static async fetchStudentUsersForCaseloadAsFacultyAdmin({ currentFacultyId, caseloadId, classId, schoolId, pageIndex, pageSize, searchText, sortColumnKey, sortOrder, signal }: { currentFacultyId: DbIdentity, caseloadId: DbIdentity, classId: DbIdentity, schoolId: DbIdentity, pageIndex:number, pageSize:number, searchText:string, sortColumnKey: string|undefined, sortOrder?: string|undefined, signal?: AbortSignal }){
        const [err, {results, total}] = await aFetch<{results: {}[], total: number}>("GET", `/faculty/${currentFacultyId}/admin/caseload/${caseloadId}/searchStudentUsers`, {
            pageIndex           : pageIndex,
            pageSize            : pageSize,
            classId,
            schoolId,
            searchText,
            sortColumnKey  : sortColumnKey || "fullName",
            sortOrder      : sortOrder     || "ascend",
        }, { signal });
        return [err, (err ? [] : results.map(x => new SearchUser(x))), total ?? 0 ] as const;
    }
    static async addStudentToParentAsFacultyAdmin({currentFacultyId, parentId, studentIds}: {currentFacultyId: DbIdentity, parentId: DbIdentity, studentIds: DbIdentity[]}){
        const [err, _] = await aFetch("PUT", `/faculty/${currentFacultyId}/admin/parent/${parentId}/addStudents`, studentIds);
        return err;
    }
    //#endregion faculty admin

    //#region Admin
    static async getUserByAdmin({userId, signal}: {userId: DbIdentity, signal: AbortSignal}){
        const [err, data] = await aFetch<{}>("GET", `/admin/user/${userId}`, undefined, { signal });
        return [err, (err ? undefined : new User(data))!] as const;
    }
    //#endregion Admin
}

export class StudentUser extends User {
    studentNumber: string = "";
    vpc         ?: string = "";

    constructor(data?:{}) {
        super(data);

        makeObservable(this, {
            studentNumber: observable,
            vpc: observable,
            set_vpc: action.bound,
        });

        if (data != null) Object.assign(this, data);
    }

    set_vpc(s ?: string) {this.vpc = s};

    async fetchStudentVPC({ signal }: { signal?: AbortSignal }) {
        const [err, s] = await aFetch<string>("GET", `/user/${this.userId}/vpc`, undefined, { signal });
        runInAction(() => {
            this.set_vpc(s);
        });
        return err;
    }
}

export class SearchUser {
    userId          : DbIdentity = DefaultId;
    avatar         ?: string;
    firstName       : string = "";
    firstNameAlias  : string = "";
    middleName      : string = "";
    lastName        : string = "";
    lastNameAlias   : string = "";
    salutation     ?: string;
    displayName    ?: string;
    email          ?: string;
    email2         ?: string;
    studentNumber  ?: string;
    isStudent       : boolean = false;
    isFaculty       : boolean = false;
    isParent        : boolean = false;

    constructor(data?:{}) {
        if (data) Object.assign(this, data);

        if (this.firstName       == null) this.firstName       = '';
        if (this.middleName      == null) this.middleName      = '';
        if (this.lastName        == null) this.lastName        = '';
        if (this.firstNameAlias  == null) this.firstNameAlias  = '';
        if (this.lastNameAlias   == null) this.lastNameAlias   = '';
        if (this.salutation      == null) this.salutation      = '';
        if (this.displayName     == null) this.displayName     = '';
    }
    get getLastNameAlias() {return (this.firstNameAlias?.length > 0 && this.lastNameAlias?.length > 0) ? this.lastNameAlias : ''}
    get lfName() { return lastFirstName(this.firstName, this.middleName, this.lastName); }
    get fullName() { return this.displayName || (this.salutation ? `${this.salutation} ${this.lastName}` : `${this.firstName} ${this.lastName}`)  }
    get studentName() { return this.displayName || ((this.firstNameAlias || this.firstName) + " " + (this.getLastNameAlias || this.lastName))}
    get showNameInFaculty () {return ((this.firstNameAlias || this.getLastNameAlias || this.displayName) ? this.lfName + " (" + this.studentName + ")" : this.lfName);}

    static sorter = {
        firstName      : stringSorter<SearchUser>(s => s.firstName?.trim().replace((/\-/g), "")   ?? ""),
        middleName     : stringSorter<SearchUser>(s => s.middleName?.trim().replace((/\-/g), "")   ?? ""),
        lastName       : stringSorter<SearchUser>(s => s.lastName?.trim().replace((/\-/g), "")   ?? ""),
        firstNameDesc  : stringSorterDesc<SearchUser>(s => s.firstName?.trim().replace((/\-/g), "")   ?? ""),
        middleNameDesc : stringSorterDesc<SearchUser>(s => s.middleName?.trim().replace((/\-/g), "")   ?? ""),
        lastNameDesc   : stringSorterDesc<SearchUser>(s => s.lastName?.trim().replace((/\-/g), "")   ?? ""),
    }
}


export function userLfName (user: IUser) { return lastFirstName(user.firstName, '', user.lastName) };
import { action, computed, makeObservable, observable, toJS } from "mobx";
import { IErrorData } from "../services/api/AppError";

interface Item {
    id: string
}
export interface IItemContainer<T extends Item> {
    items : T[];
}

export abstract class ItemContainer<T extends Item> implements IItemContainer<T> {
    items : T[]  = [];

    constructor(data ?:  IItemContainer<T>) {
        makeObservable(this, {
            items              : observable.shallow,
            onDrag             : action.bound,

            removeItem         : action.bound,
            moveItemUp         : action.bound,
            moveItemDown       : action.bound,
            addItem            : action.bound,

            isEmptyList        : computed,
        });
    }

    toJS(): IItemContainer<T> {
        return ({
            items : this.items.map(item => toJS(item)),
        });
    }

    onDrag(startIndex: number, endIndex: number) {
        const xs = this.items;
        const [x] = this.items.splice(startIndex, 1);
        if (x) xs.splice(endIndex, 0, x);
    }

    removeItem(item: T) {
        this.items = this.items.filter(i => i.id != item.id);
    }

    moveItemUp(item: T) {
        const idx = this.items.findIndex(x => x.id == item.id);
        if(idx > 0){
            [this.items[idx], this.items[idx - 1]] = [this.items[idx - 1]!, this.items[idx]!];
        }
    }

    moveItemDown(item: T) {
        const idx = this.items.findIndex(x => x.id == item.id);
        if(idx < this.items.length - 1){
            [this.items[idx + 1], this.items[idx]] = [this.items[idx]!, this.items[idx + 1]!];
        }
    }

    addItem(item: T, afterItem ?: T, top ?: boolean) {
        const i = top ? 0
                        : (afterItem == null
                            ? this.items.length
                            : this.items.findIndex(x => x.id == afterItem.id) + 1 );
        this.items.splice(i, 0, item);
    }

    /**
     * @description: remove/fix and validate T[] data before submitting to server
     * @returns [error, error]
     */
    abstract refine(): [IErrorData<{code:string}>|undefined, T|undefined];

    get isEmptyList() { return this.items.length < 1; }
}
import { computed, observable, makeObservable } from "mobx";
import { UrlString, IMediaLink } from "../../models/types";
import {uploadFile, aFetch, uploadFile2, versionPrefix, apiHost, uploadFileChunksV2, uploadFiles} from "./fetch";
import { UploadFile } from "antd/lib/upload/interface";
import { UploadSizeToBeChunked } from "../../config";

export enum ImageTypeEnum {
    Jpeg = 0,
    Png = 1,
    Bmp = 2,
    Webp = 3,
}
type MediaAccessType = 'public'|'private';
type ImageSizeType = "none" | "small" | "middle" | "large" | "avatar" | "favIcon" | "icon" | 'mediumLarge';

export function uploadMedia(f:Blob, access ?: MediaAccessType) {
    let queries: string[] = [];
    if (!!access) queries.push(`access=${access}`);
    const query = queries.join("&");
    return uploadFile2<{data: string}>("POST", `${apiHost}${versionPrefix}Image/UploadMedia?${query}`, f , undefined ,{onUploadProgress: (progressEvent) => {
        const {loaded, total} = progressEvent;
        const percentage = Math.floor(loaded/total*100) + '%';
        const element = document.getElementById((f as File).name+'text');
        if(element){
            element.innerText = percentage;
        }
    }}).then(([err, res]) =>  [err, res?.data] as const);
}

export function uploadAvatar(f:Blob, publicUse?: boolean) {
    let queries: string[] = [];
    if (!!publicUse) queries.push(`publicUse=${publicUse}`);
    const query = queries.join("&");
    return uploadFile<string>("POST", `/Image/UploadAvatar?${query}`, f);
}

export function uploadSupportFiles(f: Blob) {
    return uploadFile<string>("POST", `/Image/UploadSupportFiles`, f);
}

export function uploadImage({ file, createThumb, access, size }: { file: Blob; createThumb?: boolean; access?: MediaAccessType; size?: ImageSizeType ; }) {
    let queries: string[] = [];

    if (!!createThumb) queries.push(`createThumb=${createThumb}`);
    if (!!access) queries.push(`access=${access}`);
    if (!!size) queries.push(`size=${size}`);

    const query = queries.join("&");
    return uploadFile<string>("POST", `/Image/UploadImage?${query}`, file);
}

export function uploadDocument(f:Blob, publicUse?: boolean) {
    let queries: string[] = [];
    if (!!publicUse) queries.push(`publicUse=${publicUse}`);
    const query = queries.join("&");
    return uploadFile<{docUrl:UrlString, pdfUrl:UrlString}>("POST", `/Image/UploadDocument?${query}`, f);
}

export function uploadGeneralFile(f:Blob, publicUse?: boolean) {
    let queries: string[] = [];
    if (!!publicUse) queries.push(`publicUse=${publicUse}`);
    const query = queries.join("&");
    return uploadFile<IMediaLink>("POST", `/Image/UploadFile?${query}`, f);
}

export function uploadGeneralFileWithProgressStatus(f:Blob, onUploadProgress:  (progress: UploadProgress) => void) {
    return uploadFile2<{data: IMediaLink}>("POST", `${apiHost}${versionPrefix}Image/UploadFile`, f, undefined, {onUploadProgress: (progressEvent) => {
        const {loaded, total, lengthComputable} = progressEvent;
        onUploadProgress(new UploadProgress({loaded, total, lengthComputable}));
    }});
}

export async function uploadFileAsChunksWithProgressStatus(f:Blob, onUploadProgress:  (progress: UploadProgress) => void) {
    if (f.size < UploadSizeToBeChunked) {
        /// File is too small to be chunked
        const [err, x] = await uploadGeneralFile(f)
        onUploadProgress(new UploadProgress({loaded: f.size, total: f.size, lengthComputable: true}));
        return [err, {data: x}] as  const;
    }

    return await uploadFileChunksV2<{data: IMediaLink}>("POST",
                `${apiHost}${versionPrefix}Image/UploadChunkFile`,
                f as any as File, (uploadedBytes: number, totalBytes: number) => {
                    onUploadProgress(new UploadProgress({loaded: uploadedBytes, total: totalBytes, lengthComputable: true}));
                } );
}

export function uploadMediumPhoto(f:Blob, publicUse?: boolean) {
    let queries: string[] = [];
    if (!!publicUse) queries.push(`publicUse=${publicUse}`);
    const query = queries.join("&");
    return uploadFile<string>("POST", `/Image/UploadMediumPhoto?${query}`, f);
}

export function uploadImageAndConvertGifToOther(f: Blob, convertTo: ImageTypeEnum, publicUse?: boolean) {
    let queries: string[] = [];
    queries.push(`convertTo=${convertTo}`);
    if (!!publicUse) queries.push(`publicUse=${publicUse}`);
    const query = queries.join("&");
    return uploadFile<string>("POST", `/Image/UploadAndConvertGifImage?${query}`, f);
}

export interface IPageMeta {
    title:string,
    contentType:string,
    url: string,
    description: string,
    imageUrl: string,
    siteName: string
}
const pageMetaCache = new Map<string, IPageMeta>();
export async function fetchPageMeta(url:string) {
    const cache = pageMetaCache.get(url);
    if (cache != null) return [undefined, cache] as const;

    const res = await aFetch<IPageMeta>("GET", "/Image/GetPageMeta", { url });
    const [err, data] = res;
    if (err == null) pageMetaCache.set(url, data);
    return res;
}

export class UploadProgress {
    loaded: number = 0;
    total: number = 0;
    lengthComputable: boolean = false;

    constructor(data ?: {}) {
        makeObservable(this, {
            loaded: observable,
            total: observable,
            lengthComputable: observable,
            progressInPercent: computed
        });

        if (data) Object.assign(this, data);
    }

    get progressInPercent() { return this.total === 0 ? 0 : Math.floor(this.loaded/this.total*100); }
}

export interface IUploadOptions{
    title?: string,
    fileExtensions?: string[],
    maxFiles?: number,
    maxSize?: number,
    allowCamera: boolean,
    allowGallery: boolean,
}

interface IUploadTarget{
    url: string
    token: string
}

interface UploaderInit {
    options: IUploadOptions,
    target: IUploadTarget,
    token: string
}

export interface IUploadedFile {
    uploadedDate: number,
    contentType: string,
    size: number,
    url: string
}

const uploader = () => {
    async function start(options: IUploadOptions) {
        return await aFetch<UploaderInit>("POST", "/external/upload", { ...options });
    }

    async function poll({id} : {id: string}) {
        return await aFetch<IUploadedFile[]>("GET", `/external/upload/${id}/result` );
    }

    async function remove({id} : {id: string}) {
        return await aFetch("DELETE", `/external/upload/${id}` );
    }

    async function meta({id, signal} : {id: string, signal: AbortSignal}) {
        return await aFetch<IUploadOptions>("GET", `/external/upload/${id}/meta`, undefined, { signal} );
    }

    async function upload({id, files, onProgress} : {id: string, files: File[], onProgress?: (progress: number) => void }) {
        return await uploadFiles("POST", `/external/upload/${id}`, files as any, undefined, undefined, onProgress);
    }

    async function redirect({urls} : {urls: string[]}) {
        return await aFetch<string[]>("PUT", `/external/upload/move`, urls);
    }



    return { start, poll, remove, meta, upload, redirect}
};

export const externalUpload = uploader();